import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

import { firebaseDevConfig, firebaseProdConfig } from "./firebase-config";

console.log("ENV: ", process.env.REACT_APP_ENV);

const app = initializeApp(
  process.env.REACT_APP_ENV === "development"
    ? firebaseDevConfig
    : firebaseProdConfig
); // initialize firebase
export const auth = getAuth(app); // initialize firebase auth
export const storage = getStorage(app); // initialize firebase storage
export const db = getFirestore(app); // initialize firebase firestore
export const functions = getFunctions(app); // initialize firebase functions

// to login with email and password
export const logInWithEmailAndPassword = async (email, password) => {
  // Check if the email contains "@asilimia.co.ke"
  // if (!email.endsWith("@asilimia.co.ke")) {
  //   return {
  //     errorMessage: "Only emails with @asilimia.co.ke domain are allowed.",
  //   };
  // }

  try {
    const user = await signInWithEmailAndPassword(auth, email, password);

    // return the logged in user
    return user;
  } catch (error) {
    console.log(error.message);
    return { errorMessage: error.message };
  }
};

// send password reset email
export const passwordReset = async (email) => {
  let isReset = false;
  try {
    await sendPasswordResetEmail(auth, email);
    console.log("Password reset email sent!!");
    isReset = true;
    return { isReset };
  } catch (error) {
    console.log("Error while sending password reset email: ", error);
    return { isReset, error };
  }
};

// authenticate users using email link
export const sendEmailLink = async (email) => {
  const actionCodeSettings = {
    // URL you want to redirect back to after email link confirmation.
    url:
      process.env.REACT_APP_ENV === "development"
        ? "http://localhost:3000/login"
        : "https://portal.asilimia.com/login",
    handleCodeInApp: true,
  };

  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings).then(() => {
      window.localStorage.setItem("emailForSignIn", email);
      console.log("Email Link sent to: ", email);
    });
  } catch (error) {
    console.log("Error while sending email link: ", error);
  }
};
