import React, { useState } from "react";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { db } from "../../firebase/firebase";

// styles
const divContainer = {
  display: "flex",
  justifyContent: "space-around",
  width: " 100%",
};

const div = {
  display: "flex",
  marginLeft: " 1rem",
};

export default function ViewAirtime({ airtimeId, airtimeData }) {
  const [open, setOpen] = useState(false);
  const [senderCode, setSenderCode] = useState(null);
  const [receipientNumber, setReceipientNumber] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
    getSendersMpesaCode(airtimeId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // fetch senders mpesa code
  const getSendersMpesaCode = async (transId) => {
    const docQuery = query(
      collection(db, "mpesa_paybill_stk_session"),
      where("docId", "==", transId)
    );

    const codeSnapshot = await getDocs(docQuery);
    codeSnapshot.forEach((doc) => {
      let { MpesaReceiptNumber, beneficiary } = doc.data();
      setSenderCode(MpesaReceiptNumber);
      setReceipientNumber(beneficiary);
    });
  };

  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <VisibilityIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="View"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        onBackdropClick="false"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle sx={{ fontWeight: "bold", textDecoration: "underline" }}>
          View Sent Airtime Transaction
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={divContainer}>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>Sender Name:</Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                {airtimeData.displayName}
              </p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>
                Sender Number:
              </Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                {airtimeData.phoneNumber}
              </p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>
                Recipient Number:
              </Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                {receipientNumber}
              </p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>Amount Sent:</Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                Ksh. {airtimeData.amount}
              </p>
            </div>
          </div>
          <div style={{ ...divContainer, marginTop: "2rem" }}>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>
                Date Requested:
              </Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                {moment(airtimeData.dateRequested.toDate()).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </p>
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>
                Sender Mpesa Code:
              </Typography>
              {senderCode ? (
                <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                  {senderCode}
                </p>
              ) : (
                <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                  Not Available
                </p>
              )}
            </div>
            <div style={div}>
              <Typography sx={{ fontWeight: "bold" }}>Status:</Typography>
              <p style={{ paddingLeft: " 1rem", margin: "0" }}>
                {airtimeData.status}
              </p>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
