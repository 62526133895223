import React, { useState, forwardRef } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { Link as RouterLink } from "react-router-dom";

import { TicketsOperations } from "../../../firebase/operations";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

export default function UpdateTemplate({ templateData, templateId }) {
  const [open, setOpen] = useState(false);
  const [openUpdateTemplate, setOpenUpdateTemplate] = useState(false);
  const [isUpdated, setIsUpdated] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleUpdateTemplateOpen = () => {
    setOpenUpdateTemplate(true);
  };

  const handleUpdateTemplateClose = () => {
    setOpenUpdateTemplate(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const UpdateTemplateSchema = Yup.object().shape({
    template: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      template: templateData?.template,
    },
    validationSchema: UpdateTemplateSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log("Message data: ", values);
      const response = await TicketsOperations.updateMessageTemplate(
        templateId,
        values
      );

      console.log("RESPONSE: ", response);

      if (response.isUpdated) {
        setIsUpdated(true);
        setOpenSnackbar(true);
        resetForm();
        handleUpdateTemplateClose();
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        setOpenSnackbar(true);
      }
    },
  });

  const { handleSubmit, getFieldProps } = formik;
  return (
    <div style={{ width: "20%" }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isUpdated ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isUpdated
            ? "Template Updated successfully!!!"
            : "Cannot Update Template!!!"}
        </Alert>
      </Snackbar>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleUpdateTemplateOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Update Template"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog
        PaperProps={{
          style: { width: "90%" },
        }}
        open={openUpdateTemplate}
        onClose={handleUpdateTemplateClose}
        onBackdropClick
      >
        <DialogTitle
          sx={{ fontFamily: "Rubik, sans-serif", fontWeight: "bold" }}
        >
          Update Template
        </DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="template"
                type="text"
                multiline
                rows={3}
                margin="normal"
                label="Template Message"
                {...getFieldProps("template")}
              />
            </DialogContent>
            <DialogActions>
              <Button
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  color: "red",
                  border: "2px solid red",
                  borderRadius: "10px",
                }}
                onClick={handleUpdateTemplateClose}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  backgroundColor: "#009966",
                  color: "white",

                  "&:hover": {
                    backgroundColor: "rgba(0,153,102,.8)",
                  },
                }}
                type="submit"
              >
                Update
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
