import React, { useState, forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { AirtimeOperations } from "../../firebase/operations";

export default function CheckAirtimeStatus({ airtimeId, airtimeData }) {
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleCheckStatus = async () => {
    const response = await AirtimeOperations.checkStatus(airtimeId);
    console.log("RESPONSE: ", response);
    if (response.isChecked) {
      handleClose();
      setIsChecked(true);
      setOpenSnackbar(true);
    } else {
      setErrorMsg(response.err);
      setOpenSnackbar(true);
    }
  };

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isChecked ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isChecked
            ? "Status checked successfully!!!"
            : `Cannot check airtime status: ${errorMsg}`}
        </Alert>
      </Snackbar>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <QuestionMarkIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Check Status"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <DialogTitle
          sx={{
            fontFamily: "Rubik, sans-serif",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          Check Airtime Status
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>
            Are you sure you want to check the status of{" "}
            <span style={{ fontWeight: "bold" }}>
              Ksh. {airtimeData.amount}
            </span>{" "}
            from{" "}
            <span style={{ fontWeight: "bold" }}>
              {airtimeData.displayName}
            </span>{" "}
            to{" "}
            <span style={{ fontWeight: "bold" }}>
              {airtimeData.beneficiary}
            </span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              fontFamily: "Rubik, sans-serif",
              fontWeight: "500",
              fontSize: ".88rem",
              color: "red",
              border: "2px solid red",
              borderRadius: "10px",
              marginRight: "2rem",
            }}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Rubik, sans-serif",
              fontWeight: "500",
              fontSize: ".88rem",
              backgroundColor: "#009966",

              "&:hover": {
                backgroundColor: "rgba(0,153,102,.8)",
              },
            }}
            onClick={handleCheckStatus}
          >
            Check
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
