import React, { useState, forwardRef, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { httpsCallable } from "firebase/functions";
import * as Yup from "yup";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import MuiAlert from "@mui/material/Alert";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";

import { db, functions, sendEmailLink } from "../../firebase/firebase";

// modal styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

// admin roles
const roles = [
  "ceo",
  "cfo",
  "cio",
  "coo",
  "cto",
  "cro",
  "customer-rep",
  "senior-customer-rep",
  "dev",
  "director",
  "head-hr",
  "hr",
  "manager",
  "senior-manager",
  "sales-rep",
  "senior-sales-rep",
];

const InputFeedback = ({ error }) =>
  error ? (
    <Typography
      color="error"
      sx={{
        fontSize: "0.8rem",
        fontFamily: "Rubik, sans-serif",
        marginLeft: "1rem",
        marginTop: "0.3rem",
      }}
    >
      {error}
    </Typography>
  ) : null;

export default function CreateAdmin() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openChecker, setOpenChecker] = useState(false);
  const [exists, setExists] = useState(false);
  const [isCreated, setIsCreated] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [adminRoles, setAdminRoles] = useState([]);
  const [sessionUser, setSessionUser] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [adminValues, setAdminValues] = useState(null);

  useEffect(() => {
    // current session user
    setSessionUser(JSON.parse(localStorage.getItem("user")));
  }, []);

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setAdminRoles(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAdminRoles([]);
  };

  const handleCloseChecker = () => {
    setExists(false);
    setOpenChecker(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // check if email exists
  const emailCheck = async (values) => {
    let emailCheck = false;
    const checkEmail = httpsCallable(functions, "onCountUsersCall");
    await checkEmail({ email: values.email }).then((response) => {
      if (response.data.count > 0) {
        setExists(true);
        setOpenChecker(true);
        emailCheck = true;
      }
    });
    return emailCheck;
  };

  // add admin
  const addAdmin = async (values, checker) => {
    let created = false;
    if (checker === false) {
      try {
        const adminDocRef = doc(collection(db, "admins"));
        await setDoc(adminDocRef, {
          ...values,
          docId: adminDocRef.id,
          dateCreated: serverTimestamp(),
          isSuspended: false,
          lastUpdated: serverTimestamp(),
          createdByEmail: sessionUser?.user?.email,
          createdBy: sessionUser?.user?.uid,
        });

        await sendEmailLink(values.email);
        created = true;
        return { created };
      } catch (error) {
        console.log("Error while creating admin: ", error);
        return { created, error };
      }
    }
  };

  const makeExistingUserAnAdmin = async (values) => {
    try {
      const adminDocRef = doc(collection(db, "admins"));
      await setDoc(adminDocRef, {
        ...values,
        docId: adminDocRef.id,
        dateCreated: serverTimestamp(),
        isSuspended: false,
        lastUpdated: serverTimestamp(),
        createdByEmail: sessionUser?.user?.email,
        createdBy: sessionUser?.user?.uid,
      });

      setOpenChecker(false);
      handleClose();
      setIsCreated(true);
      setOpenSnackbar(true);
    } catch (error) {
      console.log("Error while creating admin: ", error);
      setErrorMsg("Cannot create admin");
      setOpenChecker(false);
      setOpenSnackbar(true);
    }
  };

  const CreateAdminSchema = Yup.object().shape({
    name: Yup.string().required("Admin email is required"),
    email: Yup.string().email().required("Admin email is required"),
    roles: Yup.array()
      .of(Yup.string())
      .required()
      .min(1, "Admin must have at least one assigned role"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      accessRole: "",
      roles: adminRoles,
    },
    enableReinitialize: true,
    validationSchema: CreateAdminSchema,
    onSubmit: async (values, { resetForm }) => {
      values["roles"] = adminRoles;
      values["owner"] = "ASILIMIA";

      setAdminValues(values);
      const emailCheckResponse = await emailCheck(values);

      console.log("Email check response: ", emailCheckResponse);
      if (emailCheckResponse === false) {
        const response = await addAdmin(values, exists);

        if (response.created) {
          resetForm();
          handleClose();
          setIsCreated(true);
          setOpenSnackbar(true);
        } else {
          setErrorMsg(response.error);
          setOpenSnackbar(true);
        }
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div style={{ width: "20%" }}>
      {exists === true ? (
        <Modal
          open={openChecker}
          onClose={handleCloseChecker}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          elevation={4}
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              sx={{
                fontFamily: "Rubik, sans-serif",
                fontWeight: "500",
                fontSize: ".88rem",
              }}
            >
              This user email already exists — make him an admin?
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                onClick={handleCloseChecker}
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  color: "red",
                  border: "2px solid red",
                  borderRadius: "10px",
                  marginRight: "3rem",
                  padding: "4px 10px",
                }}
              >
                Close
              </Button>
              <Button
                onClick={() => makeExistingUserAnAdmin(adminValues)}
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  backgroundColor: "#009966",
                  color: "white",

                  "&:hover": {
                    backgroundColor: "rgba(0,153,102,.8)",
                  },
                }}
              >
                Make Admin
              </Button>
            </Box>
          </Box>
        </Modal>
      ) : (
        ""
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={isCreated ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isCreated
            ? "Admin Created successfully!!!"
            : "Cannot Create Admin: " + errorMsg}
        </Alert>
      </Snackbar>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          fontFamily: "Rubik, sans-serif",
          fontWeight: "500",
          fontSize: ".88rem",
          backgroundColor: "#009966",

          "&:hover": {
            backgroundColor: "rgba(0,153,102,.8)",
          },
        }}
        startIcon={<AddOutlinedIcon />}
      >
        Add Admin
      </Button>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle
          sx={{ fontFamily: "PRubik, sans-serif", fontWeight: "bold" }}
        >
          Add Admin
        </DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="name"
                type="text"
                margin="normal"
                label="Name"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
              <TextField
                fullWidth
                autoComplete="email"
                type="email"
                margin="normal"
                label="Email"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <FormControl sx={{ marginTop: "1rem", width: 550 }}>
                <InputLabel id="demo-multiple-chip-label">Roles</InputLabel>
                <Select
                  sx={{ width: "100%" }}
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  name="roles"
                  value={adminRoles}
                  onChange={handleSelectChange}
                  error={Boolean(touched.roles && errors.roles)}
                  helperText={touched.roles && errors.roles}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {roles.map((role) => (
                    <MenuItem
                      key={role}
                      value={role}
                      style={getStyles(role, adminRoles, theme)}
                    >
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {touched.roles && <InputFeedback error={errors.roles} />}
              <FormControl sx={{ mt: "1.5rem" }} variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  Access Role
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  {...getFieldProps("accessRole")}
                  name="accessRole"
                  label="Access Role"
                  error={Boolean(touched.accessRole && errors.accessRole)}
                  helperText={touched.accessRole && errors.accessRole}
                >
                  <MenuItem value="SuperAdmin">SuperAdmin</MenuItem>
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="CustomerSupport">CustomerSupport</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  color: "red",
                  border: "2px solid red",
                  borderRadius: "10px",
                  marginRight: "3rem",
                }}
                onClick={handleClose}
                type="reset"
              >
                Cancel
              </Button>
              <Button
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  backgroundColor: "#009966",
                  color: "white",

                  "&:hover": {
                    backgroundColor: "rgba(0,153,102,.8)",
                  },
                }}
                type="submit"
              >
                Create
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
