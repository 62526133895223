import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { Link } from "react-router-dom";

import { Badge, Box } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { db } from "../../firebase/firebase";

function NotificationPopper() {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const fetchSupportTicketsCounter = async () => {
      const docRef = doc(db, "counters", "tickets");
      const counterSnapshot = await getDoc(docRef);

      if (counterSnapshot.exists()) {
        setCounter(counterSnapshot.data().notViewed);
      }
    };

    fetchSupportTicketsCounter();
  }, []);

  return (
    <Box sx={{ mt: ".8rem" }}>
      <Badge color="success" badgeContent={counter}>
        <Link to="/home/support">
          <NotificationsIcon
            sx={{ color: "black", fontSize: "1.8rem", cursor: "pointer" }}
          />
        </Link>
      </Badge>
    </Box>
  );
}

export default NotificationPopper;
