import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  Typography,
  TextField,
  Box,
  Button,
  Stack,
  Tooltip,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import AirtimeMoreMenu from "./airtimeMoreMenu";

import { EnhancedTableToolbar } from "./airtimeToolbar";
import CircularStatic from "../shared/Loader/loader";

import { tableStyles } from "../styles/tableStyles";

import { db } from "../../firebase/firebase";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: "true",
});

const getMuiTheme = () => createTheme(tableStyles);

export default function PendingAirtime() {
  const [rows, setRows] = useState([]); // table rows
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lastKey, setLastKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const [requestDateFrom, setRequestDateFrom] = useState(null);
  const [requestDateTo, setRequestDateTo] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  const searchRef = useRef(null);

  const handleSearch = async (input) => {
    const senderNoMatches = [];
    const recipientNoMatches = [];
    let phoneNumMatches = [];
    const namesMatched = [];
    const codesMatched = [];

    let numCheck = false;

    const numRegex = /^\d+$/;

    const inputCheck = (string) => {
      if (string.match(numRegex)) {
        numCheck = true;
        return numCheck;
      }

      return numCheck;
    };

    inputCheck(input);

    console.log("CHECK: ", numCheck);

    // check for numbers
    if (numCheck) {
      // get sender number match query
      const searchSenderNoQuery = query(
        collection(db, "airtime"),
        where("phoneNumber", "==", input)
      );

      // get recipient number match query
      const searchRecipientNoQuery = query(
        collection(db, "airtime"),
        where("beneficiary", "==", input)
      );

      const senderNoSnapshot = await getDocs(searchSenderNoQuery);
      const recipientSnapshot = await getDocs(searchRecipientNoQuery);

      senderNoSnapshot.forEach((doc) =>
        senderNoMatches.push({ ...doc.data(), docId: doc.id })
      );

      recipientSnapshot.forEach((doc) =>
        recipientNoMatches.push({ ...doc.data(), docId: doc.id })
      );

      phoneNumMatches = senderNoMatches.concat(recipientNoMatches);

      setRows(phoneNumMatches);
    } else {
      const strCheck = /^[a-zA-Z0-9]+$/;

      if (strCheck.test(input) !== true) {
        //check for names
        const searchNameQuery = query(
          collection(db, "airtime"),
          where("displayName", "==", input)
        );

        const nameSnapshot = await getDocs(searchNameQuery);
        nameSnapshot.forEach((doc) =>
          namesMatched.push({ ...doc.data(), docId: doc.id })
        );

        setRows(namesMatched);
      } else {
        const searchMpesaCodeQuery = query(
          collection(db, "mpesa_paybill_stk_session"),
          where("MpesaReceiptNumber", "==", input)
        );

        const codeSnapshot = await getDocs(searchMpesaCodeQuery);
        codeSnapshot.forEach((doc) =>
          codesMatched.push({ ...doc.data(), docId: doc.id })
        );

        const airtimePromises = codesMatched.map(async (doc) => {
          const dataQuery = query(
            collection(db, "airtime"),
            where("CheckoutRequestID", "==", doc.CheckoutRequestID)
          );

          const dataSnapshot = await getDocs(dataQuery);
          return dataSnapshot.docs.map((doc) => {
            return doc.data();
          });
        });

        const airtimeResults = await Promise.all(airtimePromises);
        const airtimeMatch = airtimeResults.flat();

        setRows(airtimeMatch);
      }
    }
  };

  const filterList = {};

  // handle filter
  const handleFilterSubmit = (applyFilters) => {
    const filters = applyFilters();
    console.log("Filters: ", filters);
    filters.map((item) => {
      const key = columns[`${filters.indexOf(item)}`].name;
      let newFilter;
      if (key === "dateRequested") {
        newFilter = {
          requestDateFrom: moment(item[0]).format("DD-MM-YYYY"),
          requestDateTo: moment(item[1]).format("DD-MM-YYYY"),
        };
      } else {
        newFilter = { [key]: moment(item[0]).format("DD-MM-YYYY") };
      }
      Object.assign(filterList, newFilter);
    });
    console.log("FILTER LIST: ", filterList);
    setIsFiltered(true);
  };

  const columns = [
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => `KES ${rows[dataIndex].amount}`,
      },
    },
    {
      name: "displayName",
      label: "Sender Name",
      options: {
        filter: false,
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        filter: false,
      },
    },
    {
      name: "beneficiary",
      label: "Recipient Number",
      options: {
        filter: false,
      },
    },
    {
      name: "dateRequested",
      label: "Date Requested",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              console.log(
                `From: ${moment(v[0]).format("MM-DD-YYYY")},  To: ${moment(
                  v[1]
                ).format("MM-DD-YYYY")}`
              );
              return [
                `Requested From: ${moment(v[0]).format("MM-DD-YYYY")}`,
                `Requested To: ${moment(v[1]).format("MM-DD-YYYY")}`,
              ];
            }
            if (v[0]) {
              return `Requested From: ${moment(v[0]).format("MM-DD-YYYY")}`;
            }
            if (v[1]) {
              return `Requested To: ${moment(v[1]).format("MM-DD-YYYY")}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            console.log(
              "customFilterListOnDelete: ",
              filterList,
              filterPos,
              index
            );

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(dateRequested, filters) {
            const formattedDate =
              typeof dateRequested === "string"
                ? moment(dateRequested, "DD-MM-YYYY").format("MM-DD-YYYY")
                : moment(dateRequested?.toDate().toDateString()).format(
                    "MM-DD-YYYY"
                  );

            // console.log("Formatted Date: ", formattedDate);

            if (filters[0] && filters[1]) {
              let formattedTo = moment(filters[1]).format("MM-DD-YYYY");
              let formattedFrom = moment(filters[0]).format("MM-DD-YYYY");

              return (
                moment(formattedDate, "MM-DD-YYYY").isBetween(
                  formattedFrom,
                  formattedTo
                ) === false
              );
            }
            if (filters[1]) {
              let formattedFrom = moment(filters[1]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrAfter(formattedFrom);
            }
            if (filters[0]) {
              let formattedTo = moment(filters[0]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrBefore(formattedTo);
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Requested From:"
                  value={requestDateFrom || filterList[index][0]}
                  onChange={(newValue) => {
                    setRequestDateFrom(newValue);
                    filterList[index][0] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Requested To:"
                  value={requestDateTo || filterList[index][1]}
                  onChange={(newValue) => {
                    setRequestDateTo(newValue);
                    filterList[index][1] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Stack>
          ),
        },
        customBodyRenderLite: (dataIndex) => {
          return typeof rows[dataIndex].dateRequested === "string"
            ? moment(rows[dataIndex].dateRequested, "DD-MM-YYYY").format(
                "MMMM Do YYYY, h:mm:ss a"
              )
            : moment(rows[dataIndex].dateRequested.toDate()).format(
                "MMMM Do YYYY, h:mm:ss a"
              );
        },
      },
    },
    {
      name: "deviceInfo",
      label: "Device",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          if (typeof rows[dataIndex].deviceInfo === "string") {
            return (
              <Typography
                sx={{ fontSize: "0.9rem", fontFamily: "Rubik, sans-serif" }}
              >
                USSD
              </Typography>
            );
          }

          return (
            <Typography
              sx={{ fontSize: "0.9rem", fontFamily: "Rubik, sans-serif" }}
            >
              Mobile App
            </Typography>
          );
        },
      },
    },
    {
      name: "source",
      label: "Source",
      options: {
        filter: true,
      },
    },
    {
      name: "note",
      label: "Note",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          if (rows[dataIndex].note !== "") {
            return <Typography>{rows[dataIndex].note}</Typography>;
          } else {
            return <Typography>None</Typography>;
          }
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          if (rows[dataIndex].status === "cancelled") {
            return (
              <Tooltip
                title="Transaction has failed checks."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "red",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(255,0,0,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "red" }} />
                  </span>
                  Cancelled
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "approved") {
            return (
              <Typography
                sx={{
                  color: "#4B6043",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "600",
                  fontSize: ".88rem",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(75,96,67,.2)",
                  padding: ".2rem",
                  width: "100%",
                  borderRadius: "7px",
                }}
              >
                <span
                  style={{
                    marginRight: ".5rem",
                  }}
                >
                  <CircleIcon sx={{ fontSize: ".6rem", color: "#4B6043" }} />
                </span>{" "}
                Approved
              </Typography>
            );
          } else if (rows[dataIndex].status === "requested") {
            return (
              <Tooltip title="Received request from user." placement="bottom">
                <Typography
                  sx={{
                    color: "#FF4500",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(255,69,0,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#FF4500" }} />
                  </span>{" "}
                  Requested
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "processed") {
            return (
              <Tooltip
                title="Request sent to Africas Talking."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#009966",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(0,153,102,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#009966" }} />
                  </span>{" "}
                  Processed
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "manual_transfer") {
            return (
              <Tooltip title="Airtime processed manually." placement="bottom">
                <Typography
                  sx={{
                    color: "#0047AB",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(0,71,171,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#0047AB" }} />
                  </span>{" "}
                  Manual Transfer
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "manual_cancelled") {
            return (
              <Tooltip title="Airtime manually cancelled." placement="bottom">
                <Typography
                  sx={{
                    color: "#900D09",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(144,13,9,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#900D09" }} />
                  </span>{" "}
                  Manual Cancellation
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "sent") {
            return (
              <Tooltip
                title="Callback came back. Airtime sent to buyer."
                placement="bottom"
              >
                <Typography
                  sx={{
                    color: "#8FD400",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(143,212,0,.2)",
                    padding: ".2rem",
                    width: "85%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#8FD400" }} />
                  </span>{" "}
                  Sent
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "failed") {
            return (
              <Tooltip title="Transaction Failed." placement="bottom">
                <Typography
                  sx={{
                    color: "#C30010",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(95,0,16,.2)",
                    padding: ".2rem",
                    width: "95%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#C30010" }} />
                  </span>{" "}
                  Failed
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "manual_reversed") {
            return (
              <Tooltip title="Airtime manually reversed." placement="bottom">
                <Typography
                  sx={{
                    color: "#8A00C2",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(138,0,194,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#8A00C2" }} />
                  </span>{" "}
                  Manual Reversed
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "approved") {
            return (
              <Tooltip title="Transaction passed all tests." placement="bottom">
                <Typography
                  sx={{
                    color: "#4B6043",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(75,96,67,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#4B6043" }} />
                  </span>{" "}
                  Approved
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "stk_sent") {
            return (
              <Tooltip title="STK push sent to sender." placement="bottom">
                <Typography
                  sx={{
                    color: "#299617",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(41,150,23,.2)",
                    padding: ".2rem",
                    width: "95%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#299617" }} />
                  </span>{" "}
                  STK Sent
                </Typography>
              </Tooltip>
            );
          } else if (rows[dataIndex].status === "received") {
            return (
              <Tooltip title="C2B callback received." placement="bottom">
                <Typography
                  sx={{
                    color: "#228C22",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(34,140,34,.2)",
                    padding: ".2rem",
                    width: "100%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#228C22" }} />
                  </span>{" "}
                  Received
                </Typography>
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title={`${rows[dataIndex].status}`} placement="bottom">
                <Typography
                  sx={{
                    color: "#AA5B00",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: ".88rem",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(170,91,0,.2)",
                    padding: ".2rem",
                    width: "95%",
                    borderRadius: "7px",
                  }}
                >
                  <span
                    style={{
                      marginRight: ".5rem",
                    }}
                  >
                    <CircleIcon sx={{ fontSize: ".6rem", color: "#AA5B00" }} />
                  </span>{" "}
                  {rows[dataIndex].status}
                </Typography>
              </Tooltip>
            );
          }
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <AirtimeMoreMenu
              airtimeId={rows[dataIndex]["docId"]}
              airtimeData={rows[dataIndex]}
            />
          );
        },
      },
    },
  ];

  const handleChangePage = (tableState) => {
    const { page } = tableState;
    setPage(page);

    if (searchInput.length <= 0) {
      const fetchMoreData = async (key) => {
        console.log("LAST KEY: ", key);
        if (key) {
          const pendingRef = collection(db, "airtime");

          try {
            let fetchedData = [];
            let lastKey = null;
            const pendingQuery = query(
              pendingRef,
              where("status", "in", ["received", "processed"]),
              orderBy("dateRequested", "desc"),
              startAfter(key),
              limit(500)
            );

            const pendingSnapshot = await getDocs(pendingQuery);
            lastKey = pendingSnapshot.docs[pendingSnapshot.docs.length - 1];
            console.log("LAST KEY: ", lastKey);
            pendingSnapshot.forEach((doc) => {
              fetchedData.push({ ...doc.data(), docId: doc.id });
            });

            setRows(rows.concat(fetchedData));
            setLastKey(lastKey);
            setIsLoading(false);
          } catch (err) {
            console.log(
              "Error while fetching more pending airtime transactions",
              err
            );
          }
        }
      };

      fetchMoreData(lastKey);
    }
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage, 10));
    setPage(0);
  };

  const options = {
    responsive: "standard",
    rowsPerPage,
    rowsPerPageOptions: [10, 25, 50, 100],
    download: true,
    print: false,
    confirmFilters: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handleChangePage(tableState);
          break;
        case "changeRowsPerPage":
          handleChangeRowsPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
      <Box style={{ marginTop: "40px" }}>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Rubik, sans-serif",
            fontWeight: "500",
            fontSize: ".88rem",
            backgroundColor: "#009966",

            "&:hover": {
              backgroundColor: "rgba(0,153,102,.8)",
            },
          }}
          onClick={() => handleFilterSubmit(applyNewFilters)}
        >
          Apply Filters
        </Button>
      </Box>
    ),
  };

  // pull data from firebase and set table rows
  useEffect(() => {
    const fetchPending = async () => {
      const pendingRef = collection(db, "airtime");
      try {
        let fetchedData = [];
        let lastKey = null;
        const pendingQuery = query(
          pendingRef,
          where("status", "in", ["received", "processed"]),
          orderBy("dateRequested", "desc"),
          limit(1500)
        );

        const pendingSnapshot = await getDocs(pendingQuery);
        lastKey = pendingSnapshot.docs[pendingSnapshot.docs.length - 1];
        pendingSnapshot.forEach((doc) => {
          fetchedData.push({ ...doc.data(), docId: doc.id });
        });

        setRows(fetchedData);
        setLastKey(lastKey);
        setIsLoading(false);
      } catch (err) {
        console.log("Error while fetching pending airtime transactions", err);
      }
    };

    fetchPending();
  }, []);

  console.log("airtime: ", rows);

  return (
    <div style={{ backgroundColor: "#F7F7F7" }}>
      <EnhancedTableToolbar
        setSearchInput={setSearchInput}
        searchRef={searchRef}
        handleSearch={() => handleSearch(searchInput)}
        tableTitle="Pending Airtime"
      />
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme()}>
          {isLoading ? (
            <CircularStatic />
          ) : (
            <MUIDataTable data={rows} columns={columns} options={options} />
          )}
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
}
