import React, { useState } from "react";
import { Link as RouterLink, Outlet } from "react-router-dom";
import ReactPanZoom from "react-image-pan-zoom-rotate";

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import NoData from "../shared/NoData/noData";

export default function ViewFortuneAccount({ accountId, accountData }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <VisibilityIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="View Account"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
        <DialogTitle sx={{ fontWeight: "bold" }}>
          Fortune Account Details
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                fontFamily: "Rubik, sans-serif",
                textDecoration: "underline",
              }}
            >
              General Details
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "95%",
              }}
            >
              <Box>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  First Name:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {accountData.first_name}
                  </span>
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Second Name:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {accountData.second_name}
                  </span>
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Last Name:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {accountData.last_name}
                  </span>
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Phone Number:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {accountData.phone_number}
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "95%",
              mt: "1rem",
            }}
          >
            <Box>
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
              >
                Date Registered:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {accountData.dateRegistered}
                </span>
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
              >
                Date of Birth:{" "}
                <span style={{ fontWeight: "normal" }}>{accountData.dob}</span>
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
              >
                Postal Address:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {accountData.postal_address}
                </span>
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
              >
                Industry:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {accountData.industry}
                </span>
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              width: "100%",
              mt: "1rem",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                fontFamily: "Rubik, sans-serif",
                textDecoration: "underline",
              }}
            >
              Account Images
            </Typography>
            <Box
              sx={{
                mt: "1rem",
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "30%",
                }}
              >
                <Box sx={{ position: "relative", overflow: "hidden" }}>
                  {accountData?.photograph ? (
                    <ReactPanZoom
                      image={accountData?.photograph}
                      alt="face pic"
                    />
                  ) : (
                    <NoData text="No Face image available" />
                  )}
                </Box>

                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Face
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "30%",
                }}
              >
                <Box sx={{ position: "relative", overflow: "hidden" }}>
                  {accountData?.ID_front ? (
                    <ReactPanZoom
                      image={accountData?.ID_front}
                      alt="face pic"
                    />
                  ) : (
                    <NoData text="No Front ID image available" />
                  )}
                </Box>

                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Front ID Pic
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "30%",
                }}
              >
                <Box sx={{ position: "relative", overflow: "hidden" }}>
                  {accountData?.ID_back ? (
                    <ReactPanZoom image={accountData?.ID_back} alt="face pic" />
                  ) : (
                    <NoData text="No Back ID image available" />
                  )}
                </Box>

                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Back ID Pic
                </Typography>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Outlet />
    </div>
  );
}
