import React, { useState, forwardRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";

import { logInWithEmailAndPassword, auth } from "../../firebase/firebase";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import FilledInput from "@mui/material/FilledInput";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import logo1 from "../../assets/images/Asilimia_Leja_WhitePink_RGB.svg";
import googlePlay from "../../assets/images/google-play-badge.png";

//styles
import {
  LeftDiv,
  LoginPage,
  LogoStyles,
  RightDiv,
  RightContent,
  Heading,
  InputContainer,
  InputLabels,
  ContactNumber,
  ContactEmail,
  ContactsContainer,
  Download,
} from "../styles/loginStyles";
import { PasswordResetDialog } from "./passwordReset";

function Login() {
  const [isLogged, setIsLogged] = useState(false); // logged in state
  const [emailValidation, setEmailValidation] = useState(null); // email validation
  const [open, setOpen] = useState(false); // open and close alert
  const [showPassword, setShowPassword] = useState(false);
  let navigate = useNavigate();

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClick = () => {
    setOpen(true);
  };

  // handle closing of alert
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // handle email link sign in
  useEffect(() => {
    const handleEmailLink = () => {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem("emailForSignIn");

        if (!email) {
          email = window.prompt("Please provide your email for confirmation");
        }

        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            // clear email from storage
            window.localStorage.removeItem("emailForSignIn");
          })
          .catch((error) => {
            console.log("Error signing in with email link: ", error);
          });
      }
    };

    handleEmailLink();
  }, []);

  const InputFeedback = ({ error }) =>
    error ? (
      <Typography
        color="error"
        sx={{
          fontSize: "0.7rem",
          marginLeft: "1rem",
          fontFamily: "Rubik, sans-serif",
        }}
      >
        {error}
      </Typography>
    ) : null;

  // validating form inputs
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required!"),
    password: Yup.string().required("Password is required!"),
  });

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      const response = await logInWithEmailAndPassword(
        values.email,
        values.password
      );

      console.log("login response", response);
      if (!response.errorMessage) {
        console.log("user", response);
        localStorage.setItem("user", JSON.stringify(response));
        setIsLogged(true);
        setTimeout(() => {
          navigate("/home/clients", { replace: true });
        }, 1000);
      } else {
        console.log("Wrong Credentials!");
        // setEmailValidation(response.errorMessage);
      }

      handleClick();
    },
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  return (
    <LoginPage>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={isLogged ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isLogged
            ? "Logged in successfully!!!"
            : "Incorrect Email or Password!!!"}
        </Alert>
      </Snackbar>
      <LeftDiv className="left">
        <Box sx={{ width: "90%" }}>
          <Box>
            <LogoStyles className="logo-img">
              <img src={logo1} alt="logo" />
            </LogoStyles>
          </Box>
        </Box>
      </LeftDiv>
      <RightDiv className="right">
        <RightContent>
          <Heading>Kindly provide the details below to login:</Heading>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Box sx={{ mt: "1.2rem" }}>
                <InputContainer>
                  <InputLabels>Email Address</InputLabels>
                  <TextField
                    sx={{ mb: "0.3rem", backgroundColor: "white" }}
                    placeholder="Enter your email"
                    variant="filled"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {touched.email && <InputFeedback error={errors.email} />}
                </InputContainer>
                <InputContainer>
                  <InputLabels>Password</InputLabels>
                  <FilledInput
                    id="filled-adornment-password"
                    sx={{ mb: "0.3rem", backgroundColor: "white" }}
                    placeholder="Enter your password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </InputContainer>
                {touched.password && <InputFeedback error={errors.password} />}
              </Box>
              <PasswordResetDialog />
              <Button
                color="success"
                type="submit"
                variant="contained"
                sx={{
                  padding: "1rem 0",
                  marginTop: "3rem",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  backgroundColor: "#009966",
                  color: "white",

                  "&:hover": {
                    backgroundColor: "rgba(0,153,102,.8)",
                  },
                }}
                fullWidth
              >
                <Typography sx={{ fontWeight: "bold", fontSize: ".9rem" }}>
                  Login
                </Typography>
              </Button>
            </Form>
          </FormikProvider>
        </RightContent>
        <ContactsContainer>
          <ContactNumber>+254 7682 12665</ContactNumber>
          <ContactEmail>support@asilimia.co.ke</ContactEmail>
          <Download
            href="https://play.google.com/store/apps/details?id=com.leja"
            target="_blank"
            rel="noreferrer"
          >
            <Button sx={{ ml: "2rem", width: "80%" }}>
              <img src={googlePlay} alt="google play" />
            </Button>
          </Download>
        </ContactsContainer>
      </RightDiv>
    </LoginPage>
  );
}

export default Login;
