import React, { useState, useEffect } from "react";
import moment from "moment";
import { ref, getDownloadURL } from "firebase/storage";
import ReactPanZoom from "react-image-pan-zoom-rotate";

import { storage } from "../../firebase/firebase";

import { Typography, Box, Divider } from "@mui/material";
import NoData from "../shared/NoData/noData";

import CircularStatic from "../shared/Loader/loader";
import Uploader from "../shared/Uploader/uploader";

// styles
const boxContainer = {
  display: "flex",
  justifyContent: "space-around",
  width: "100%",
};

const box = {
  display: "flex",
  marginLeft: "1rem",
};

export default function UserProfile({ userId, userData }) {
  const [front, setFront] = useState(null);
  const [back, setBack] = useState(null);
  const [selfie, setSelfie] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const userIdFrontRef = ref(storage, `idImages/${userId}/front.png`);
  const userIdBackRef = ref(storage, `idImages/${userId}/back.png`);
  const userIdSelfieRef = ref(storage, `idImages/${userId}/face.png`);

  // get front-side id image
  const getFrontIdImg = () => {
    getDownloadURL(userIdFrontRef)
      .then((url) => {
        setFront(url);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error while Downloading img: ", err);
        setIsLoading(false);
      });
  };

  // get back-side id image
  const getBackIdImg = () => {
    getDownloadURL(userIdBackRef)
      .then((url) => {
        setBack(url);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error while Downloading img: ", err);
        setIsLoading(false);
      });
  };

  // get user face image
  const getSelfieImg = () => {
    getDownloadURL(userIdSelfieRef)
      .then((url) => {
        setSelfie(url);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error while Downloading img: ", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getFrontIdImg();
    getBackIdImg();
    getSelfieImg();
  }, []);

  return (
    <Box>
      <Box sx={{ marginBottom: "2rem", fontFamily: "Rubik, sans-serif" }}>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            textDecoration: "underline",
            fontFamily: "Rubik, sans-serif",
          }}
        >
          General Details
        </Typography>
        <Box sx={boxContainer}>
          <Box sx={box}>
            <Typography
              sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
            >
              National Id:
            </Typography>
            <p
              style={{
                paddingLeft: "1rem",
                margin: "0",
                fontFamily: "Rubik, sans-serif",
              }}
            >
              {userData?.idNumber}
            </p>
          </Box>
          <Box sx={box}>
            <Typography
              sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
            >
              First Name:
            </Typography>
            <p
              style={{
                paddingLeft: "1rem",
                margin: "0",
                fontFamily: "Rubik, sans-serif",
              }}
            >
              {userData?.firstName}
            </p>
          </Box>
          <Box sx={box}>
            <Typography
              sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
            >
              Last Name:
            </Typography>
            <p
              style={{
                paddingLeft: "1rem",
                margin: "0",
                fontFamily: "Rubik, sans-serif",
              }}
            >
              {userData?.lastName}
            </p>
          </Box>
          <Box sx={box}>
            <Typography
              sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
            >
              Email:
            </Typography>
            <p
              style={{
                paddingLeft: "1rem",
                margin: "0",
                fontFamily: "Rubik, sans-serif",
              }}
            >
              {userData?.email}
            </p>
          </Box>
          <Box sx={box}>
            <Typography
              sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
            >
              Phone Number:
            </Typography>
            <p
              style={{
                paddingLeft: "1rem",
                margin: "0",
                fontFamily: "Rubik, sans-serif",
              }}
            >
              {userData?.phoneNumber}
            </p>
          </Box>
          <Box sx={box}>
            <Typography
              sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
            >
              Referral Code:
            </Typography>
            <p
              style={{
                paddingLeft: "1rem",
                margin: "0",
                fontFamily: "Rubik, sans-serif",
              }}
            >
              {userData?.myRefCode}
            </p>
          </Box>
        </Box>
        <Box
          sx={{
            ...boxContainer,
            marginTop: "1rem",
            fontFamily: "Rubik, sans-serif",
          }}
        >
          <Box sx={box}>
            <Typography
              sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
            >
              Is Loan Qualified ?:
            </Typography>
            <p
              style={{
                paddingLeft: "1rem",
                margin: "0",
                fontFamily: "Rubik, sans-serif",
              }}
            >
              {userData?.isQualified ? (
                <span style={{ color: "#009966" }}>Yes</span>
              ) : (
                <span style={{ color: "red" }}>No</span>
              )}
            </p>
          </Box>
          <Box sx={box}>
            <Typography
              sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
            >
              Is Fraudulent ?:
            </Typography>
            <p
              style={{
                paddingLeft: "1rem",
                margin: "0",
                fontFamily: "Rubik, sans-serif",
              }}
            >
              {userData?.fraudulent ? (
                <span style={{ color: "red" }}>Yes</span>
              ) : (
                <span style={{ color: "#009966" }}>No</span>
              )}
            </p>
          </Box>
          <Box sx={box}>
            <Typography
              sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
            >
              Is Suspended ?:
            </Typography>
            <p
              style={{
                paddingLeft: "1rem",
                margin: "0",
                fontFamily: "Rubik, sans-serif",
              }}
            >
              {userData?.isSuspended ? (
                <span style={{ color: "red" }}>Yes</span>
              ) : (
                <span style={{ color: "#009966" }}>No</span>
              )}
            </p>
          </Box>
          <Box sx={box}>
            <Typography
              sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
            >
              Is Sales ?:
            </Typography>
            <p
              style={{
                paddingLeft: "1rem",
                margin: "0",
                fontFamily: "Rubik, sans-serif",
              }}
            >
              {userData?.isSales ? (
                <span style={{ color: "#009966" }}>Yes</span>
              ) : (
                <span style={{ color: "red" }}>No</span>
              )}
            </p>
          </Box>
          <Box sx={box}>
            <Typography
              sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
            >
              Created Fortune Account?:
            </Typography>
            <p
              style={{
                paddingLeft: "1rem",
                margin: "0",
                fontFamily: "Rubik, sans-serif",
              }}
            >
              {userData?.fortuneAccountCreated ? (
                <span style={{ color: "#009966" }}>Yes</span>
              ) : (
                <span style={{ color: "red" }}>No</span>
              )}
            </p>
          </Box>
          <Box sx={box}>
            <Typography
              sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
            >
              Loan Limit:
            </Typography>
            <p
              style={{
                paddingLeft: "1rem",
                margin: "0",
                fontFamily: "Rubik, sans-serif",
              }}
            >
              {userData?.loanLimit !== undefined ? userData?.loanLimit : ""}
            </p>
          </Box>
          <Box sx={box}>
            <Typography
              sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
            >
              Loan Rate:
            </Typography>
            <p
              style={{
                paddingLeft: "1rem",
                margin: "0",
                fontFamily: "Rubik, sans-serif",
              }}
            >
              {userData?.loanRate !== undefined ? userData?.loanRate : ""}%
            </p>
          </Box>
        </Box>
        <Box
          sx={{
            ...boxContainer,
            marginTop: "1rem",
            fontFamily: "Rubik, sans-serif",
          }}
        >
          <Box sx={box}>
            <Typography
              sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
            >
              Is Referral Code Suspended ?:
            </Typography>
            <p
              style={{
                paddingLeft: "1rem",
                margin: "0",
                fontFamily: "Rubik, sans-serif",
              }}
            >
              {userData?.isRefCodeSuspended ? (
                <span style={{ color: "red" }}>Yes</span>
              ) : (
                <span style={{ color: "#009966" }}>No</span>
              )}
            </p>
          </Box>
          {userData?.isRefCodeSuspended && (
            <Box sx={box}>
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
              >
                Ref Code Suspension Reason:
              </Typography>
              <p
                style={{
                  paddingLeft: "1rem",
                  margin: "0",
                  fontFamily: "Rubik, sans-serif",
                }}
              >
                {userData?.refCodeSuspensionDetails?.reason !== undefined
                  ? userData?.refCodeSuspensionDetails?.reason
                  : ""}
              </p>
            </Box>
          )}
          {userData?.fraudulent && (
            <Box sx={box}>
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
              >
                Fraudulent Reason:
              </Typography>
              <p
                style={{
                  paddingLeft: "1rem",
                  margin: "0",
                  fontFamily: "Rubik, sans-serif",
                }}
              >
                {userData?.fraudulentReason !== undefined
                  ? userData?.fraudulentReason
                  : ""}
              </p>
            </Box>
          )}
          {userData?.isSuspended && (
            <Box sx={box}>
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
              >
                Suspension Reason:
              </Typography>
              <p
                style={{
                  paddingLeft: "1rem",
                  margin: "0",
                  fontFamily: "Rubik, sans-serif",
                }}
              >
                {userData?.suspensionDetails?.reason !== undefined
                  ? userData?.suspensionDetails?.reason
                  : ""}
              </p>
            </Box>
          )}
        </Box>
      </Box>
      <Divider
        sx={{
          borderBottomWidth: 3,
          marginBottom: "2rem",
          backgroundColor: "333333",
        }}
      />
      <Box
        sx={{
          marginBottom: "1rem",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "30%",
          }}
        >
          {isLoading ? (
            <CircularStatic />
          ) : (
            <Box sx={{ position: "relative", overflow: "hidden" }}>
              {userData?.faceImagePath ? (
                <ReactPanZoom image={userData?.faceImagePath} alt="face pic" />
              ) : selfie ? (
                <ReactPanZoom image={selfie} alt="face pic" />
              ) : (
                <NoData text="No Face image available" />
              )}
            </Box>
          )}
          <Typography
            sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
          >
            Face
          </Typography>
          <Uploader userId={userId} type="face" imgCategory="identification" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "30%",
          }}
        >
          {isLoading ? (
            <CircularStatic />
          ) : (
            <Box sx={{ position: "relative", overflow: "hidden" }}>
              {userData?.idFrontImagePath ? (
                <ReactPanZoom
                  image={userData?.idFrontImagePath}
                  alt="face pic"
                />
              ) : front ? (
                <ReactPanZoom image={front} alt="front id pic" />
              ) : (
                <NoData text="No Front ID image available" />
              )}
            </Box>
          )}
          <Typography
            sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
          >
            Front ID Pic
          </Typography>
          <Uploader
            userId={userId}
            type="frontIdImage"
            imgCategory="identification"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "30%",
          }}
        >
          {isLoading ? (
            <CircularStatic />
          ) : (
            <Box sx={{ position: "relative", overflow: "hidden" }}>
              {userData?.idBackImagePath ? (
                <ReactPanZoom
                  image={userData?.idBackImagePath}
                  alt="face pic"
                />
              ) : back ? (
                <ReactPanZoom image={back} alt="back id pic" />
              ) : (
                <NoData text="No Back ID image available" />
              )}
            </Box>
          )}
          <Typography
            sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
          >
            Back ID Pic
          </Typography>
          <Uploader
            userId={userId}
            type="backIdImage"
            imgCategory="identification"
          />
        </Box>
      </Box>
      <Divider
        sx={{
          borderBottomWidth: 3,
          marginBottom: "2rem",
          backgroundColor: "333333",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: "bold",
              textDecoration: "underline",
              fontFamily: "Rubik, sans-serif",
            }}
          >
            Device Details
          </Typography>
          <Box>
            <Box sx={box}>
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
              >
                Manufacturer:
              </Typography>
              <p
                style={{
                  paddingLeft: "1rem",
                  margin: "0",
                  fontFamily: "Rubik, sans-serif",
                }}
              >
                {userData?.deviceInfo?.manufacturer
                  ? userData?.deviceInfo?.manufacturer
                  : "Not Provided"}
              </p>
            </Box>
            <Box sx={box}>
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
              >
                Model:
              </Typography>
              <p
                style={{
                  paddingLeft: "1rem",
                  margin: "0",
                  fontFamily: "Rubik, sans-serif",
                }}
              >
                {userData?.deviceInfo?.model
                  ? userData?.deviceInfo?.model
                  : "Not Provided"}
              </p>
            </Box>
            <Box sx={box}>
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
              >
                Operating System:
              </Typography>
              <p
                style={{
                  paddingLeft: "1rem",
                  margin: "0",
                  fontFamily: "Rubik, sans-serif",
                }}
              >
                {userData?.deviceInfo?.isAndroidDevice
                  ? "Android"
                  : userData?.deviceInfo?.isIosDevice
                  ? "iOS"
                  : "Not Provided"}
              </p>
            </Box>
            <Box sx={box}>
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
              >
                OS Version:
              </Typography>
              <p
                style={{
                  paddingLeft: "1rem",
                  margin: "0",
                  fontFamily: "Rubik, sans-serif",
                }}
              >
                {userData?.deviceInfo?.version
                  ? userData?.deviceInfo?.version
                  : "Not Provided"}
              </p>
            </Box>
            <Box sx={box}>
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
              >
                UUID:
              </Typography>
              <p
                style={{
                  paddingLeft: "1rem",
                  margin: "0",
                  fontFamily: "Rubik, sans-serif",
                }}
              >
                {userData?.deviceInfo?.androidId
                  ? userData?.deviceInfo?.androidId
                  : "Not Provided"}
              </p>
            </Box>
            <Box sx={box}>
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
              >
                Country:
              </Typography>
              <p
                style={{
                  paddingLeft: "1rem",
                  margin: "0",
                  fontFamily: "Rubik, sans-serif",
                }}
              >
                {userData?.deviceInfo?.country
                  ? userData?.deviceInfo?.country
                  : "Not Provided"}
              </p>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: "bold",
              textDecoration: "underline",
              fontFamily: "Rubik, sans-serif",
            }}
          >
            Bank Details
          </Typography>
          <Box>
            <Box sx={box}>
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
              >
                Bank Code:
              </Typography>
              <p
                style={{
                  paddingLeft: "1rem",
                  margin: "0",
                  fontFamily: "Rubik, sans-serif",
                }}
              >
                {userData?.deviceInfo?.bankCode
                  ? userData?.deviceInfo?.bankCode
                  : "Not Provided"}
              </p>
            </Box>
            <Box sx={box}>
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
              >
                Bank Name:
              </Typography>
              <p
                style={{
                  paddingLeft: "1rem",
                  margin: "0",
                  fontFamily: "Rubik, sans-serif",
                }}
              >
                {userData?.deviceInfo?.bankName
                  ? userData?.deviceInfo?.bankName
                  : "Not Provided"}
              </p>
            </Box>
            <Box sx={box}>
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
              >
                Bank Account Number:
              </Typography>
              <p
                style={{
                  paddingLeft: "1rem",
                  margin: "0",
                  fontFamily: "Rubik, sans-serif",
                }}
              >
                {userData?.deviceInfo?.bankAccountNumber
                  ? userData?.deviceInfo?.bankAccountNumber
                  : "Not Provided"}
              </p>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: "bold",
              textDecoration: "underline",
              fontFamily: "Rubik, sans-serif",
            }}
          >
            User Qualification
          </Typography>
          <Box>
            <Box sx={box}>
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
              >
                Status:
              </Typography>
              <p
                style={{
                  paddingLeft: "1rem",
                  margin: "0",
                  fontFamily: "Rubik, sans-serif",
                }}
              >
                {userData?.isQualified ? (
                  <Typography
                    sx={{ color: "#009966", fontFamily: "Rubik, sans-serif" }}
                  >
                    Qualified
                  </Typography>
                ) : (
                  <Typography
                    sx={{ color: "red", fontFamily: "Rubik, sans-serif" }}
                  >
                    Not Qualified
                  </Typography>
                )}
              </p>
            </Box>
            <Box sx={box}>
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
              >
                Reason:
              </Typography>
              <p
                style={{
                  paddingLeft: "1rem",
                  margin: "0",
                  fontFamily: "Rubik, sans-serif",
                }}
              >
                {userData?.qualificationReason
                  ? userData?.qualificationReason
                  : "Not Provided"}
              </p>
            </Box>
            <Box sx={box}>
              <Typography
                sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
              >
                Date Qualified:
              </Typography>
              <p
                style={{
                  paddingLeft: "1rem",
                  margin: "0",
                  fontFamily: "Rubik, sans-serif",
                }}
              >
                {userData?.dateQualified
                  ? moment(userData?.dateQualified?.toDate()).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )
                  : "No Date Available"}
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
