import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";

export default function useIdle({ onIdle, onPrompt }) {
  const [isIdle, setIsIdle] = useState(false);

  const handleOnPrompt = () => {
    onPrompt();
  };

  const handleOnIdle = () => {
    setIsIdle(true);
    console.log("user is idle");
    console.log("Last Active", getLastActiveTime());
    onIdle();
  };

  const handleOnActive = () => {
    setIsIdle(false);
  };

  const handleOnAction = () => {
    setIsIdle(false);
  };

  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: 1000 * 60 * process.env.REACT_APP_TIMEOUT,
    promptBeforeIdle: 1000 * 60 * process.env.REACT_APP_PROMPT_TIMEOUT,
    onPrompt: handleOnPrompt,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
  });

  return {
    getRemainingTime,
    getLastActiveTime,
    isIdle,
    reset,
  };
}
