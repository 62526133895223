import React, { useState, forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import BlockIcon from "@mui/icons-material/Block";
import { SendMoneyOperations } from "../../../firebase/operations";

export default function ManualCancel({ transactionId, transactionData }) {
  const [open, setOpen] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleCancel = async () => {
    const response = await SendMoneyOperations.manualCancel(transactionId);
    console.log("RESPONSE: ", response);
    if (response.isCancelled) {
      handleClose();
      setIsCancelled(true);
      setOpenSnackbar(true);
    } else {
      setErrorMsg(response.err);
      setOpenSnackbar(true);
    }
  };

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isCancelled ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isCancelled
            ? "Manual Cancellation made successfully!!!"
            : `Cannot Manually Cancel transfer: ${errorMsg}`}
        </Alert>
      </Snackbar>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <BlockIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Manually Cancel"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        onBackdropClick="false"
        maxWidth="xl"
      >
        <DialogTitle
          sx={{
            fontFamily: "Rubik, sans-serif",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          Cancel Transaction
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>
            Are you sure you want to manually cancel{" "}
            <span style={{ fontWeight: "bold" }}>
              Ksh. {transactionData.amount}
            </span>{" "}
            from{" "}
            <span style={{ fontWeight: "bold" }}>
              {transactionData.displayName}
            </span>{" "}
            to{" "}
            <span style={{ fontWeight: "bold" }}>
              {transactionData.beneficiary}
            </span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Rubik, sans-serif",
              fontWeight: "500",
              fontSize: ".88rem",
              backgroundColor: "red",

              "&:hover": {
                backgroundColor: "rgba(255,0,0,.8)",
              },
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
