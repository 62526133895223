// Business related transaction status
export const BusinessTransactionStatus = {
  PENDING: "pending",
  ACCEPTED: "accepted",
  PROCESSED: "processed",
  SENT: "sent",
  CANCELLED: "cancelled",
};

// Business related transaction type
export const BusinessTransactionTypes = {
  DEPOSIT: "deposit",
  WITHDRAWAL: "withdrawal",
  SENDMONEY: "sendmoney",
  AIRTIME: "airtime",
  JUMIA: "jumia",
  PAYMENT: "payment",
};

// Business related payment options
export const BusinessPaymentOptions = {
  MERCHANT_WALLET: "merchant_wallet",
  PAYBILLNO: "paybill",
  TILLNO: "till",
};

// Business related KYC status options
export const BusinessKYCStatus = {
  LEVEL_0: "level_0", // no item submitted
  LEVEL_1: "level_1", // only one item submitted
  LEVEL_2: "level_2", // only two item submitted
  LEVEL_3: "level_3", // all required items submitted
  LEVEL_4: "level_4", // business is verified
};

//  Business related KYC verification status
export const BusinessVerificationStates = {
  VERIFIED: "verified",
  DECLINED: "declined",
  PENDING: "pending",
};
