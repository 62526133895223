import {
  collection,
  getDocs,
  query,
  startAfter,
  limit,
  orderBy,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  where,
  serverTimestamp,
  addDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "./firebase";

import { transactionStatus, airtimeStatus } from "../utils/transferStatuses";
import { BusinessVerificationStates } from "../utils/businessStatuses";
import { LoanPendingAction, LoanStatus } from "../utils/loanStatuses-Actions";
import {
  checkTransactionExists,
  getSentExists,
} from "../utils/checkTransactionExists";

export const sessionUser = JSON.parse(localStorage.getItem("user"));

/******* PAGINATION OPERATIONS *******/
export const Pagination = {
  // fetch the first batch
  fetchFirstBatch: async (col, order, whereClause = null) => {
    try {
      let data
      if (whereClause) {
        data = await getDocs(
            query(
                collection(db, col),
                where(whereClause[0], whereClause[1], whereClause[2]),
                orderBy(order, "desc"),
                limit(1500)
            )
        );
      } else {
        data = await getDocs(
            query(collection(db, col), orderBy(order, "desc"), limit(1500))
        );
      }

      let fetchedData = [];
      let lastKey = "";
      lastKey = data.docs[data.docs.length - 1];
      data.forEach((doc) => {
        fetchedData.push({ ...doc.data(), docId: doc.id });
      });

      return { fetchedData, lastKey };
    } catch (error) {
      console.log("Error: ", error);
    }
  },

  // to fetch the next batch of data
  fetchNextBatch: async (key, col, order, whereClause = null) => {
    try {
      let data;
      if (whereClause) {
        data = await getDocs(
            query(
                collection(db, col),
                where(whereClause[0], whereClause[1], whereClause[2]),
                orderBy(order, "desc"),
                startAfter(key),
                limit(500)
            )
        );
      } else {
        data = await getDocs(
            query(
                collection(db, col),
                orderBy(order, "desc"),
                startAfter(key),
                limit(500)
            )
        );
      }
      let fetchedData = [];
      let lastKey = "";
      lastKey = data.docs[data.docs.length - 1];
      data.forEach((doc) => {
        fetchedData.push({ ...doc.data(), docId: doc.id });
      });

      console.log("NEXT: ", fetchedData);
      return { fetchedData, lastKey };
    } catch (error) {
      console.log("Error: ", error);
    }
  },
};

/******* MESSAGES CRUD OPERATIONS *******/
export const MessagesOperations = {
  // fetch all messages
  fetchMessages: async () => {
    const messagesRef = doc(db, "settings", "messages");
    const messagesSnapshot = await getDoc(messagesRef);

    let fetchedMessages = [];

    fetchedMessages.push(messagesSnapshot.data());

    return fetchedMessages;
  },

  // update message
  updateMessage: async (messageId, values) => {
    const messagesRef = doc(db, "settings", "messages");
    let updated = false;
    try {
      await updateDoc(messagesRef, {
        [messageId]: values.messageDescription,
      });

      updated = true;

      return { updated };
    } catch (err) {
      console.log("Error while updating: ", err);
      return { updated, err };
    }
  },

  // create new message
  createMessage: async (messageData, values) => {
    const messagesRef = doc(db, "settings", "messages");
    let created = false;
    try {
      let checker = true;

      for (let i = 0; i <= messageData.messageData.length - 1; i++) {
        if (
          values.messageType === Object.values(messageData.messageData)[i][0]
        ) {
          checker = false;
          console.log("Shouldn't update!!");
        }
      }

      if (checker) {
        await updateDoc(messagesRef, {
          [values.messageType]: values.messageDescription,
        });
        console.log("checker: ", checker);
      }

      created = true;
      return { created, checker };
    } catch (err) {
      console.log("Error while creating message: ", err);
      return { created, err };
    }
  },
};

/******* USER CRUD OPERATIONS *******/
export const UsersOperations = {
  currentUser: async () => {
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    const userRef = doc(db, "users", sessionUser.user.uid);
    const userSnapshot = await getDoc(userRef);
    return userSnapshot.data();
  },
  // update user
  updateUser: async (userId, values) => {
    let isUpdated = false;
    const usersRef = doc(db, "users", userId);

    try {
      await updateDoc(usersRef, values);
      isUpdated = true;
      return { isUpdated };
    } catch (err) {
      console.log("Error while updating: ", err);
      return { isUpdated, err };
    }
  },

    // update over aged user 65+
    updateUserOverAged: async (userId, values) => {
      const sessionUser = JSON.parse(localStorage.getItem("user"));
      let isUpdated = false;
      const usersRef = doc(db, "users", userId);
      try {
        await updateDoc(usersRef, 
          {
            ...values,
            doneBy: sessionUser.user.uid,
            doneByEmail: sessionUser.user.email,
            unsuspendProfileDate: serverTimestamp(),
            isProfileSuspended: false,
          },
        );
        isUpdated = true;
  
        return { isUpdated };
      } catch (error) {
        console.log("Error while suspending: ", error);
        return { error, isUpdated };
      }
    },
      // third ttempt iprs
      updateDevice: async (userId, values) => {
        const sessionUser = JSON.parse(localStorage.getItem("user"));
        let isUpdated = false;
        const usersRef = doc(db, "users", userId);
        try {
          await updateDoc(usersRef, {
              ...values,
              doneBy: sessionUser.user.uid,
              doneByEmail: sessionUser.user.email,
              deviceStatusUpdated: true,
            },
          );
          isUpdated = true;
    
          return { isUpdated };
        } catch (error) {
          console.log("Error while suspending: ", error);
          return { error, isUpdated };
        }
      },

          // update over aged user 65+
    updateUserIprsThirdAttempt: async (userId, values) => {
      const sessionUser = JSON.parse(localStorage.getItem("user"));
      let isUpdated = false;
      const usersRef = doc(db, "users", userId);
      try {
        await updateDoc(usersRef, 
          {
            ...values,
            doneBy: sessionUser.user.uid,
            doneByEmail: sessionUser.user.email,
            customerSupportThirdIprsAttempt: true,
          },
        );
        isUpdated = true;
  
        return { isUpdated };
      } catch (error) {
        console.log("Error while suspending: ", error);
        return { error, isUpdated };
      }
    },
    //   try {
    //     await updateDoc(usersRef, values);
        
    //     isUpdated = true;
    //     return { isUpdated };
    //   } catch (err) {
    //     console.log("Error while updating: ", err);
    //     return { isUpdated, err };
    //   }
    // },

  // delete user
  deleteUser: async (userId) => {
    const deleted = false;
    const usersRef = doc(db, "users", userId);

    return await deleteDoc(usersRef)
      .then(() => ({ deleted: true }))
      .catch((err) => ({ deleted, err }));
  },

  // suspend user
  suspendUser: async (values, userId) => {
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    let suspended = false;
    const usersRef = doc(db, "users", userId);
    try {
      await updateDoc(usersRef, {
        suspensionDetails: {
          ...values,
          doneBy: sessionUser.user.uid,
          doneByEmail: sessionUser.user.email,
          updatedAt: serverTimestamp(),
        },
        isSuspended: true,
      });
      suspended = true;

      return { suspended };
    } catch (error) {
      console.log("Error while suspending: ", error);
      return { error, suspended };
    }
  },

  // suspend user ref code
  suspendUserRefCode: async (values, userId) => {
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    let suspended = false;
    const usersRef = doc(db, "users", userId);
    try {
      await updateDoc(usersRef, {
        refCodeSuspensionDetails: {
          ...values,
          doneBy: sessionUser.user.uid,
          doneByEmail: sessionUser.user.email,
          updatedAt: serverTimestamp(),
        },
        isRefCodeSuspended: true,
      });
      suspended = true;

      return { suspended };
    } catch (error) {
      console.log("Error while suspending: ", error);
      return { error, suspended };
    }
  },

  // activate user
  activateUser: async (values, userId) => {
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    let activated = false;
    const usersRef = doc(db, "users", userId);
    try {
      await updateDoc(usersRef, {
        activationDetails: {
          ...values,
          doneBy: sessionUser.user.uid,
          doneByEmail: sessionUser.user.email,
          updatedAt: serverTimestamp(),
        },
        isSuspended: false,
      });
      activated = true;

      return { activated };
    } catch (error) {
      console.log("Error while activating: ", error);
      return { error, activated };
    }
  },

  // assign role
  assignRole: async (adminId, values) => {
    let updated = false;
    const adminRef = doc(db, "admins", adminId);
    const userRef = doc(db, "users", adminId);

    try {
      await updateDoc(adminRef, values);
      await updateDoc(userRef, values);
      updated = true;
      return { updated };
    } catch (err) {
      console.log("Error while updating: ", err);
      return { updated, err };
    }
  },
};

/******* SEND MONEY OPERATIONS *******/
export const SendMoneyOperations = {
  // manually transfer money
  manualTransfer: async (transactionId) => {
    const transactionRef = doc(db, "send_money_transactions", transactionId);
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    let isTransferred = false;
    try {
      return await updateDoc(transactionRef, {
        manuallyProcessedBy: sessionUser?.user.uid,
        status: transactionStatus.MANUAL_TRANSFER,
      }).then(async () => {
        console.log("Transaction transferred successfully!");
        isTransferred = true;
        await Pagination.fetchFirstBatch(
          "send_money_transactions",
          "dateRequested"
        );
        return { isTransferred };
      });
    } catch (err) {
      console.log("Error while updating: ", err);
      return { isTransferred, err };
    }
  },

  // manually cancel transfer money
  manualCancel: async (transactionId) => {
    const transactionRef = doc(db, "send_money_transactions", transactionId);
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    let isCancelled = false;
    try {
      return await updateDoc(transactionRef, {
        manuallyProcessedBy: sessionUser?.user.uid,
        status: transactionStatus.MANUAL_CANCELLED,
      }).then(async () => {
        console.log("Transaction cancelled successfully!");
        isCancelled = true;
        await Pagination.fetchFirstBatch(
          "send_money_transactions",
          "dateRequested"
        );
        return { isCancelled };
      });
    } catch (err) {
      console.log("Error while updating: ", err);
      return { isCancelled, err };
    }
  },

  // manually reverse money
  manualReverse: async (transactionId) => {
    const transactionRef = doc(db, "send_money_transactions", transactionId);
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    let isReversed = false;
    try {
      return await updateDoc(transactionRef, {
        manuallyProcessedBy: sessionUser?.user.uid,
        status: transactionStatus.REVERSE,
      }).then(async () => {
        console.log("Transaction reversed successfully!");
        isReversed = true;
        await Pagination.fetchFirstBatch(
          "send_money_transactions",
          "dateRequested"
        );
        return { isReversed };
      });
    } catch (err) {
      console.log("Error while updating: ", err);
      return { isReversed, err };
    }
  },

  // manually change status
  manualChangeStatus: async (transactionId) => {
    const transactionRef = doc(db, "send_money_transactions", transactionId);
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    let isChanged = false;
    try {
      return await updateDoc(transactionRef, {
        manuallyProcessedBy: sessionUser?.user.uid,
        status: transactionStatus.MANUAL_SENT,
      }).then(async () => {
        console.log("Transaction status changed successfully!");
        isChanged = true;
        await Pagination.fetchFirstBatch(
          "send_money_transactions",
          "dateRequested"
        );
        return { isChanged };
      });
    } catch (err) {
      console.log("Error while updating: ", err);
      return { isChanged, err };
    }
  },

  // check status from safaricom
  checkStatus: async (transactionId) => {
    const transactionRef = doc(db, "send_money_transactions", transactionId);
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    let isChecked = false;
    try {
      return await updateDoc(transactionRef, {
        statusCheckedBy: sessionUser?.user.uid,
        status: "check_status",
      }).then(async () => {
        console.log("Transaction status checked successfully!");
        isChecked = true;
        await Pagination.fetchFirstBatch(
          "send_money_transactions",
          "dateRequested"
        );
        return { isChecked };
      });
    } catch (err) {
      console.log("Error while updating: ", err);
      return { isChecked, err };
    }
  },
};

/******* SEND TO MANY OPERATIONS *******/
export const SendToManyOperations = {
  // manually transfer send to many transaction
  manualTransfer: async (transactionId, transactionData) => {
    let isTransferred = false;
    const transactionRef = doc(
      db,
      "send_money_to_many_transactions",
      transactionId
    );
    const sessionUser = JSON.parse(localStorage.getItem("user"));

    try {
      return await updateDoc(transactionRef, {
        ...transactionData,
        manuallyProcessedBy: sessionUser?.user.uid,
        lastUpdated: serverTimestamp(),
      }).then(async () => {
        console.log("Transaction transferred successfully!");
        isTransferred = true;
        return { isTransferred };
      });
    } catch (err) {
      console.log("Error while updating: ", err);
      return { err, isTransferred };
    }
  },

  // manually reverse send to many transaction
  manualReverse: async (transactionId, transactionData) => {
    let isReversed = false;
    const transactionRef = doc(
      db,
      "send_money_to_many_transactions",
      transactionId
    );
    const sessionUser = JSON.parse(localStorage.getItem("user"));

    try {
      return await updateDoc(transactionRef, {
        ...transactionData,
        manuallyProcessedBy: sessionUser?.user.uid,
        lastUpdated: serverTimestamp(),
      }).then(async () => {
        console.log("Transaction reversed successfully!");
        isReversed = true;
        return { isReversed };
      });
    } catch (err) {
      console.log("Error while updating: ", err);
      return { err, isReversed };
    }
  },

  // manually cancel send to many transaction
  manualCancel: async (transactionId, transactionData) => {
    let isCancelled = false;
    const transactionRef = doc(
      db,
      "send_money_to_many_transactions",
      transactionId
    );
    const sessionUser = JSON.parse(localStorage.getItem("user"));

    try {
      return await updateDoc(transactionRef, {
        ...transactionData,
        manuallyProcessedBy: sessionUser?.user.uid,
        lastUpdated: serverTimestamp(),
      }).then(async () => {
        console.log("Transaction cancelled successfully!");
        isCancelled = true;
        return { isCancelled };
      });
    } catch (err) {
      console.log("Error while updating: ", err);
      return { err, isCancelled };
    }
  },
};

/******* AIRTIME OPERATIONS *******/
export const AirtimeOperations = {
  manualTransfer: async (airtimeId) => {
    const airtimeRef = doc(db, "airtime", airtimeId);
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    try {
      await updateDoc(airtimeRef, {
        manuallyProcessedBy: sessionUser?.user.uid,
        status: airtimeStatus.MANUAL_TRANSFER,
      });
      console.log("Airtime transferred successfully!");
      await Pagination.fetchFirstBatch("airtime", "dateRequested");
      return "manual_transfer"; // Return a string indicating success
    } catch (err) {
      console.error("Error while updating: ", err);
      return err.message || "error"; // Return a string indicating an error occurred
    }
  },
// export const AirtimeOperations = {
//   // manually transfer airtime
//   manualTransfer: async (airtimeId) => {
//     const airtimeRef = doc(db, "airtime", airtimeId);
//     const sessionUser = JSON.parse(localStorage.getItem("user"));
//     let isTransferred = false;
//     try {
//       return await updateDoc(airtimeRef, {
//         manuallyProcessedBy: sessionUser?.user.uid,
//         status: airtimeStatus.MANUAL_TRANSFER,
//       }).then(async () => {
//         // const updatedDoc = await getDoc(airtimeRef);
//         // const updatedStatus = updatedDoc.data().status;
//         console.log("Airtime transferred successfully!");
//         isTransferred = true;
//         await Pagination.fetchFirstBatch("airtime", "dateRequested");
//         return { isTransferred };
//       });
//     } catch (err) {
//       console.log("Error while updating: ", err);
//       return { isTransferred, err };
//     }
//   },

// /******* AIRTIME OPERATIONS *******/
// export const AirtimeOperations = {
//   // manually transfer airtime
//   manualTransfer: async (airtimeId) => {
//     const airtimeRef = doc(db, "airtime", airtimeId);
//     const sessionUser = JSON.parse(localStorage.getItem("user"));
//     try {
//       return await updateDoc(airtimeRef, {
//         manuallyProcessedBy: sessionUser?.user.uid,
//         status: airtimeStatus.MANUAL_TRANSFER,
//       }).then(async () => {
//         const updatedDoc = await getDoc(airtimeRef);
//         const updatedStatus = updatedDoc.data().status;
//         console.log("Airtime transferred successfully!");
//         return updatedStatus;
//       });
//     } catch (err) {
//       console.log("Error while updating: ", err);
//       return err;
//     }
//   },

  // manually cancel airtime
  manualCancel: async (airtimeId) => {
    const airtimeRef = doc(db, "airtime", airtimeId);
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    try {
      return await updateDoc(airtimeRef, {
        manuallyCancelledBy: sessionUser?.user.uid,
        status: airtimeStatus.MANUAL_CANCELLED,
      }).then(async () => {
        const updatedDoc = await getDoc(airtimeRef);
        const updatedStatus = updatedDoc.data().status;
        console.log("Airtime cancelled successfully! ", updatedStatus);
        return updatedStatus;
      });
    } catch (err) {
      console.log("Error while updating: ", err);
      return err;
    }
  },

  // manually reverse airtime
  reverse: async (airtimeId) => {
    const airtimeRef = doc(db, "airtime", airtimeId);
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    try {
      return await updateDoc(airtimeRef, {
        manuallyReversedBy: sessionUser?.user.uid,
        status: airtimeStatus.MANUAL_REVERSED,
      }).then(async () => {
        const updatedDoc = await getDoc(airtimeRef);
        const updatedStatus = updatedDoc.data().status;
        console.log("Airtime reversed successfully!");
        return updatedStatus;
      });
    } catch (err) {
      console.log("Error while updating: ", err);
      return err;
    }
  },
    // manually cancel airtime
    manualChangeStatus: async (airtimeId) => {
      const airtimeRef = doc(db, "airtime", airtimeId);
      const sessionUser = JSON.parse(localStorage.getItem("user"));
      try {
        return await updateDoc(airtimeRef, {
          manuallyProcessedBy: sessionUser?.user.uid,
          status: airtimeStatus.MANUAL_SENT,
        }).then(async () => {
          const updatedDoc = await getDoc(airtimeRef);
          const updatedStatus = updatedDoc.data().status;
          console.log("Airtime status changed successfully! ", updatedStatus);
          return updatedStatus;
        });
      } catch (err) {
        console.log("Error while updating: ", err);
        return err;
      }
    },

  // check airtime status from safaricom
  checkStatus: async (airtimeId) => {
    const airtimeRef = doc(db, "airtime", airtimeId);
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    let isChecked = false;
    try {
      return await updateDoc(airtimeRef, {
        statusCheckedBy: sessionUser?.user.uid,
        status: "status_check",
      }).then(async () => {
        console.log("Airtime status checked successfully!");
        isChecked = true;
        await Pagination.fetchFirstBatch("airtime", "dateRequested");
        return { isChecked };
      });
    } catch (err) {
      console.log("Error while updating: ", err);
      return { isChecked, err };
    }
  },
};

/******* REFERRAL CODE OPERATIONS *******/
export const ReferralCodeOperations = {
  manuallyProcessReward: async (codeId, codeData) => {
    let isProcessed = false;
    const codeRef = doc(db, "users_referrals", codeId);
    const sessionUser = JSON.parse(localStorage.getItem("user"));

    try {
      return await updateDoc(codeRef, {
        ...codeData,
        manuallyProcessedBy: sessionUser?.user.uid,
        lastUpdated: serverTimestamp(),
      }).then(async () => {
        console.log("Transaction processed successfully!");
        isProcessed = true;
        return { isProcessed };
      });
    } catch (err) {
      console.log("Error while updating: ", err);
      return { err, isProcessed };
    }
  },
};

/******* LOANS OPERATIONS *******/
export const LoanOperations = {
  // check loan status
  checkStatus: async (loanId) => {
    let exists = false;
    const loanRef = collection(db, "loans_v2", loanId, "disbursements");

    try {
      const loanSnap = await getDocs(loanRef);
      const mpesaCode = loanSnap.docs[0]?.data()?.sentMpesaCode;

      const mpesaTransactionRef = doc(db, "mpesa_transactions", mpesaCode);
      const mpesaTransactionSnap = await getDoc(mpesaTransactionRef);

      if (mpesaTransactionSnap.exists()) {
        exists = true;

        return { exists };
      } else {
        console.log("Loan doesn't exists!");
        return { exists };
      }
    } catch (error) {
      console.log("Error while checking loan status: ", error);
      return { error, exists };
    }
  },

  // approve a loan
  approveLoan: async (loanId) => {
    const loanRef = doc(db, "loans_v2", loanId);
    try {
      return await updateDoc(loanRef, {
        status: LoanStatus.APPROVED,
        dateApproved: serverTimestamp(),
        lastUpdated: serverTimestamp(),
      }).then(async () => {
        const approvedDoc = await getDoc(loanRef);
        const updatedStatus = approvedDoc.data().status;
        console.log("Approved Doc: ", approvedDoc.data());
        console.log("Loan approved successfully! ", updatedStatus);
        return updatedStatus;
      });
    } catch (err) {
      console.log("Error while approving: ", err);
      return err;
    }
  },

  // get installment
  getInstallment: async (loanId, paymentNumber) => {
    console.log("id: ", loanId);
    console.log("payment number: ", paymentNumber);
    const installmentRef = collection(db, "loans_v2_instalments");
    const installmentQuery = query(
      installmentRef,
      where("paymentNumber", "==", paymentNumber),
      where("loanId", "==", loanId)
    );
    const installmentSnapshot = await getDocs(installmentQuery);

    console.log("size: ", installmentSnapshot.size);

    return installmentSnapshot;
  },

  // add loan to loan history
  addLoanHistoryItem: async (sessionUser, loanId, loanData, statusCode) => {
    const data = {};
    data["status"] = loanData.status;
    data[statusCode] = {
      amountRequested: loanData.amountRequested,
      amountToRepay: loanData.amountToRepay,
      principalToRepay: loanData.principalToRepay,
      interestToRepay: loanData.interestToRepay,
      interest: loanData.interest,
      status: loanData.status,
      doneBy: sessionUser.user.uid,
      doneByEmail: sessionUser.user.email,
      actionDate: serverTimestamp(),
    };

    if (loanData.processingFeeToRepay)
      data[statusCode]["processingFeeToRepay"] = loanData.processingFeeToRepay;
    if (loanData.processingFeePaid)
      data[statusCode]["processingFeePaid"] = loanData.processingFeePaid;
    if (loanData.processingDutyToRepay)
      data[statusCode]["processingDutyToRepay"] =
        loanData.processingDutyToRepay;
    if (loanData.processingDutyPaid)
      data[statusCode]["processingDutyPaid"] = loanData.processingDutyPaid;
    if (loanData.principalPaid)
      data[statusCode]["principalPaid"] = loanData.principalPaid;
    if (loanData.interestPaid)
      data[statusCode]["interestPaid"] = loanData.interestPaid;
    if (loanData.transactionAmount)
      data[statusCode]["transactionAmount"] = loanData.transactionAmount;
    if (loanData.transactionSource)
      data[statusCode]["transactionSource"] = loanData.transactionSource;
    if (loanData.transactionCode)
      data[statusCode]["transactionCode"] = loanData.transactionCode;
    if (loanData.previousStatus)
      data[statusCode]["previousStatus"] = loanData.previousStatus;
    if (loanData.dateApproved)
      data[statusCode]["dateApproved"] = loanData.dateApproved;
    if (loanData.dateDisbursed)
      data[statusCode]["dateDisbursed"] = loanData.dateDisbursed;
    if (loanData.isDisbursed)
      data[statusCode]["isDisbursed"] = loanData.isDisbursed;
    if (loanData.amountPaid)
      data[statusCode]["amountPaid"] = loanData.amountPaid;
    if (loanData.note) data[statusCode]["note"] = loanData.note;

    const loanHistoryDocRef = doc(db, "loans_v2_history", loanId);
    const snapshot = await getDoc(loanHistoryDocRef);

    if (snapshot.exists) {
      await updateDoc(loanHistoryDocRef, data);
    } else {
      data["sendTo"] = loanData.sendTo;
      data["initAmountToRepay"] = loanData.initAmountToRepay;
      data["amountRequested"] = loanData.amountRequested;
      data["amountToDisburse"] = loanData.amountToDisburse;
      data["monthlyPayment"] = loanData.monthlyPayment;
      data["firstPaymentDate"] = loanData.firstPaymentDate;
      data["dateRequested"] = loanData.dateRequested;
      data["dueDate"] = loanData.dueDate;
      data["currency"] = loanData.currency;
      data["clientType"] = loanData.clientType;
      data["clientId"] = loanData.clientId;
      data["owner"] = loanData.owner;
      await setDoc(doc(loanHistoryDocRef), data);
    }
  },

  // make loan payment
  makePayment: async (loanId, loanData, values) => {
    // payment types
    const isMonthlyPayment = false;
    const isFullRepayment = false;
    const isPartialRepayment = false;

    let isPaid = false;

    let isProcessing = false;

    let actionType;

    if (values.type === "monthly") {
      actionType = "add_monthly_payment";
    }

    if (values.type === "full") {
      actionType = "add_full_payment";
    }

    if (values.type === "partial") {
      actionType = "add_partial_payment";
    }

    const installment = await LoanOperations.getInstallment(
      loanId,
      loanData.currentInstalment || 1
    );

    if (installment.exists) {
      loanData.transactionAmount = installment.docs[0].data().amountToRepay;
    }

    const matchExists = await checkTransactionExists(
      values.transId,
      values.amount
    );

    console.log("INSTALLMENT: ", installment.size);

    const loanRef = doc(db, "loans_v2", loanId);

    try {
      isProcessing = true;
      await updateDoc(loanRef, {
        pendingAction: {
          isProcessing: true,
          type: LoanPendingAction.REPAY,
          isRepaying: true,
          actionType: actionType,
          matchWasFound: matchExists,
          isMonthlyPayment: isMonthlyPayment,
          paymentNumber: installment.docs[0].data().paymentNumber,
          instalmentId: installment.docs[0].id,
          repaymentDate: new Date(values.lastUpdated),
          transactionCode: values.transId,
          transactionSource: values.source,
          transactionAmount: values.amount,
          doneBy: sessionUser.user.uid,
          doneByEmail: sessionUser.user.email,
          doneOn: serverTimestamp(),
        },
        hasPendingAction: true,
        lastUpdated: serverTimestamp(),
      });

      isPaid = true;
      loanData.status = actionType;
      await LoanOperations.addLoanHistoryItem(sessionUser, loanId, loanData);
      isProcessing = false;

      return { isPaid };
    } catch (err) {
      console.log("Error while approving: ", err);
      return { isPaid, err };
    }
  },

  // cancel loan
  cancelLoan: async (values, loanData, loanId) => {
    const exists = await getSentExists(loanData);
    const loanRef = doc(db, "loans_v2", loanId);

    let isCancelled = false;

    try {
      await updateDoc(loanRef, {
        note: values.note,
        pendingAction: {
          note: values.note,
          matchWasFound: exists,
          type: LoanPendingAction.CANCEL,
          actionType: "manual_confirmation",
          doneBy: sessionUser.user.uid,
          doneByEmail: sessionUser.user.email,
          doneOn: serverTimestamp(),
        },
        actionType: "manual_confirmation",
        hasPendingAction: true,
        updatedBy: sessionUser.user.uid,
        updatedByEmail: sessionUser.user.email,
        lastUpdated: serverTimestamp(),
      });

      isCancelled = true;

      loanData.status = "cancelled_manually";
      await LoanOperations.addLoanHistoryItem(sessionUser, loanId, loanData);
      return { isCancelled };
    } catch (error) {
      console.log("Error while approving: ", error);
      return { isCancelled, error };
    }
  },

  // confirm loan cancellation
  confirmLoanCancellation: async (loanData, loanId) => {
    loanData.status = "cancelled";
    loanData.dateCancelled = serverTimestamp();
    loanData.lastUpdated = serverTimestamp();

    const loanDocRef = doc(db, "loans_v2", loanId);
    const loanInstallmentsRef = collection(db, "loans_v2_instalments");
    const removedInstallmentsRef = collection(
      db,
      "loans_v2_instalments_removed"
    );
    const removedDoc = async () =>
      await addDoc(
        collection(db, "loans_v2_removed"),
        Object.assign({}, loanData)
      );

    // await LoanOperations.addLoanHistoryItem(
    //   sessionUser,
    //   loanId,
    //   loanData,
    //   LoanStatusCodes.LOAN_CODE_CANCELLED
    // );

    // adding payments collection to the removed loan document
    // and deleting the loan from loans V2 collection
    const paymentsQuery = collection(db, "loans_v2", loanId, "payments");
    const paymentsSnapshot = await getDocs(paymentsQuery);
    if (paymentsSnapshot.size > 0) {
      await Promise.all(
        paymentsSnapshot.docs?.map(async (docSnapshot) => {
          const removedRef = await removedDoc();
          const removedPaymentsQuery = collection(
            db,
            `loans_v2_removed/${removedRef.id}/payments`
          );
          await addDoc(removedPaymentsQuery, {
            ...docSnapshot.data(),
          });
          await deleteDoc(
            doc(db, "loans_v2", loanId, "payments", docSnapshot.id)
          );
        })
      );
    }

    // adding disbursements collection to the removed loan document
    // and deleting the loan from loans V2 collection
    const disbursementQuery = collection(
      db,
      "loans_v2",
      loanId,
      "disbursements"
    );
    const disbursementsSnapshot = await getDocs(disbursementQuery);
    if (disbursementsSnapshot.size > 0) {
      await Promise.all(
        disbursementsSnapshot.docs?.map(async (docSnapshot) => {
          const removedRef = await removedDoc();
          const removedDisbursementQuery = collection(
            db,
            `loans_v2_removed/${removedRef.id}/disbursements`
          );
          await addDoc(removedDisbursementQuery, {
            ...docSnapshot.data(),
          });
          await deleteDoc(
            doc(db, "loans_v2", loanId, "disbursements", docSnapshot.id)
          );
        })
      );
    }

    if (disbursementsSnapshot.size < 1 && paymentsSnapshot.size < 1) {
      await removedDoc();
    }
  },
};

/******* FORTUNE LOAN OPERATIONS *******/
export const FortuneLoansOperations = {
  // check loan status
  checkFortuneLoanStatus: async (loanId) => {
    let check = false;
    const loanRef = doc(db, "loans_v2", loanId);

    try {
      await updateDoc(loanRef, {
        status: LoanStatus.CHECK_FAILED_FORTUNE_B2C,
      });

      check = true;
      return { check };
    } catch (error) {
      console.log("Error while checking fortune loan status: ", error);
      return { error, check };
    }
  },

  // manually disburse loan
  manuallyDisburseLoan: async (loanId) => {
    let isDisbursed = false;
    const loanRef = doc(db, "loans_v2", loanId);

    try {
      await updateDoc(loanRef, {
        status: LoanStatus.DISBURSE_FAILED_FORTUNE_B2C,
        lastUpdated: serverTimestamp(),
        manuallyDisbursedBy: {
          disbursedBy: sessionUser.user.uid,
          disbursedByEmail: sessionUser.user.email,
          dateDisbursed: serverTimestamp(),
        },
      });

      isDisbursed = true;
      return { isDisbursed };
    } catch (error) {
      console.log("Error while disbursing loan: ", error);
      return { error, isDisbursed };
    }
  },
};

/******* TICKETS OPERATIONS *******/
export const TicketsOperations = {
  // close ticket
  closeTicket: async (ticketId) => {
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    const ticketRef = doc(db, "tickets", ticketId);
    let closed;
    try {
      await updateDoc(ticketRef, {
        status: "closed",
        closedBy: sessionUser.user.uid,
        closedByEmail: sessionUser.user.email,
        closedAt: serverTimestamp(),
        lastUpdated: serverTimestamp(),
      });

      closed = true;

      return closed;
    } catch (err) {
      closed = false;
      console.log("Error while approving: ", err);
      return closed;
    }
  },

  // add message to message history
  addMessageHistoryItem: async (changes, messageId) => {
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    try {
      const historyItemQuery = collection(
        db,
        `messages_history/${messageId}/items`
      );

      await setDoc(doc(historyItemQuery), {
        ...changes,
        doneBy: sessionUser.user.uid,
        doneByEmail: sessionUser.user.email,
        actionDate: serverTimestamp(),
      });
      console.log("Sent to message history");
    } catch (err) {
      console.log("Couldn't add message to message history: ", err);
    }
  },

  // send sms
  sendSms: async (changes) => {
    let isSent = false;
    try {
      const messageDocRef = await addDoc(collection(db, "messages"), changes);
      await TicketsOperations.addMessageHistoryItem(changes, messageDocRef?.id);

      isSent = true;
      return { isSent };
    } catch (err) {
      return { err, isSent };
    }
  },

  // add message template
  addMessageTemplate: async (values) => {
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    let isAdded = false;
    const templateRef = collection(db, "ticket_response_templates");
    try {
      await addDoc(templateRef, {
        ...values,
        createdBy: sessionUser.user.uid,
        createdByEmail: sessionUser.user.email,
        createdAt: serverTimestamp(),
      });
      isAdded = true;

      return { isAdded };
    } catch (err) {
      console.log("Couldn't add template: ", err);
      return { Error: err, isAdded };
    }
  },

  // update message template
  updateMessageTemplate: async (templateId, values) => {
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    let isUpdated = false;
    const templateRef = doc(db, "ticket_response_templates", templateId);
    try {
      await updateDoc(templateRef, {
        ...values,
        updatedBy: sessionUser.user.uid,
        updatedByEmail: sessionUser.user.email,
        updatedAt: serverTimestamp(),
      });
      isUpdated = true;

      return { isUpdated };
    } catch (err) {
      console.log("Couldn't update template: ", err);
      return { Error: err, isUpdated };
    }
  },

  // delete template
  deleteTemplate: async (templateId) => {
    let deleted = false;
    const templateRef = doc(db, "ticket_response_templates", templateId);

    return await deleteDoc(templateRef)
      .then(() => {
        deleted = true;

        return { deleted };
      })
      .catch((err) => {
        console.log("Couldn't delete template: ", err);
        return { Error: err, deleted };
      });
  },
};

/******* KYC OPERATIONS *******/
export const KYCOperations = {
  // verify KYC
  verifyKYC: async (values, businessId) => {
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    let verified = false;
    const kycRef = doc(db, "merchant_businesses", businessId);

    try {
      await updateDoc(kycRef, {
        verificationDetails: {
          ...values,
          doneBy: sessionUser.user.uid,
          doneByEmail: sessionUser.user.email,
          updatedAt: serverTimestamp(),
        },
        businessVerificationStatus: BusinessVerificationStates.VERIFIED,
      });
      verified = true;
      return { verified };
    } catch (error) {
      console.log("Error while verifying: ", error);
      return { error, verified };
    }
  },

  // Decline KYC
  declineKYC: async (values, businessId) => {
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    let declined = false;
    const kycRef = doc(db, "merchant_businesses", businessId);

    try {
      await updateDoc(kycRef, {
        verificationDetails: {
          reason: values.comment,
          doneBy: sessionUser.user.uid,
          doneByEmail: sessionUser.user.email,
          updatedAt: serverTimestamp(),
        },
        businessVerificationStatus: BusinessVerificationStates.DECLINED,
      });

      declined = true;
      return { declined };
    } catch (error) {
      console.log("Error while declining: ", error);
      return { error, declined };
    }
  },

  // on-board business
  onBoardBusiness: async (values) => {
    let created = false;
    const kycRef = collection(db, "merchant_businesses");

    try {
      await addDoc(kycRef, values);
      created = true;

      return { created };
    } catch (error) {
      console.log("Error while declining: ", error);
      return { error, created };
    }
  },

    // update business
    updateBusiness: async (values, businessId) => {
      const sessionUser = JSON.parse(localStorage.getItem("user"));
      let isUpdated = false;
      const businessRef = doc(db, "merchant_businesses", businessId);
      try {
        await updateDoc(businessRef, {
          ...values,
          businessVerificationStatus: BusinessVerificationStates.PENDING,
          updatedBy: sessionUser.user.uid,
          updatedAt: serverTimestamp(),
        });
        isUpdated = true;

        return { isUpdated };
      } catch (error) {
        console.log("Error while updating: ", error);
        return { error, isUpdated };
      }
    },

  deleteBusiness: async (business, businessId) => {
    let deleted = false;
    const businessRef = doc(db, "merchant_businesses", businessId);
    const deletedBusinessRef = collection(db, "merchant_businesses_deleted");

    try {
      await addDoc(deletedBusinessRef, business);
      await deleteDoc(businessRef);
      deleted = true;

      return { deleted };
    } catch (err) {
      console.log("Error while deleting: ", err);
      return { deleted, err };
    }
  }
};
