import React, { useState, forwardRef, useRef } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import imageCompression from "browser-image-compression";

import MuiAlert from "@mui/material/Alert";

import { storage, db } from "../../../firebase/firebase";

import { Button, Box, Snackbar, Typography } from "@mui/material";

function Uploader({ userId, businessId, type, imgCategory }) {
  const [file, setFile] = useState(null); // store uploaded image
  const [percent, setPercent] = useState(0); // progress
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [open, setOpen] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [alert, setAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const inputRef = useRef();

  console.log("userId: ", userId);

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  // handle file upload event
  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // handle firebase upload
  const handleUpload = () => {
    console.log("IMAGE: ", file);

    if (file !== null) {
      if (file.size > 3072) {
        //compress the file uploaded
        async function handleImageCompression() {
          console.log("originalFile instanceof Blob", file instanceof Blob); // true
          console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

          const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          try {
            const compressedFile = await imageCompression(file, options);
            console.log(
              "compressedFile instanceof Blob",
              compressedFile instanceof Blob
            ); // true
            console.log(
              `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
            ); // smaller than maxSizeMB
            console.log(`compressedFile ${compressedFile.name}`);

            // check image categories (either business related or user related)
            if (imgCategory === "business") {
              setAlert(false);
              const userImageRef = ref(
                storage,
                `businessImages/${userId}/${businessId}/${compressedFile?.name}`
              );

              const uploadTask = uploadBytesResumable(
                userImageRef,
                compressedFile,
                {
                  contentType: file.type,
                }
              );

              uploadTask.on(
                "state_changed",
                (snapshot) => {
                  const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  );

                  // update progress
                  setPercent(percent);
                  inputRef.current.value = null;
                  setIsUploaded(true);
                  setOpenSnackbar(true);
                },
                (err) => {
                  console.log("Cant upload: ", err);
                  setErrorMsg(err);
                  setOpenSnackbar(true);
                },
                () => {
                  // download url
                  getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    console.log("download URL: ", url);

                    const updateBusiness = async () => {
                      const businessRef = doc(
                        db,
                        "merchant_businesses",
                        businessId
                      );

                      if (type === "business") {
                        await updateDoc(businessRef, {
                          businessImagePath: url,
                        });
                      }

                      if (type === "permit") {
                        await updateDoc(businessRef, {
                          permitImagePath: url,
                        });
                      }
                    };

                    updateBusiness();
                  });
                }
              );
            }

            if (imgCategory === "identification") {
              setAlert(false);
              const userImageRef = ref(
                storage,
                `idImages/${userId}/${compressedFile?.name}`
              );

              const uploadTask = uploadBytesResumable(
                userImageRef,
                compressedFile
              );

              uploadTask.on(
                "state_changed",
                (snapshot) => {
                  const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  );

                  // update progress
                  setPercent(percent);
                  inputRef.current.value = null;
                  setIsUploaded(true);
                  setOpenSnackbar(true);
                },
                (err) => {
                  console.log("Cant upload: ", err);
                  setErrorMsg(err);
                  setOpenSnackbar(true);
                },
                () => {
                  // download url
                  getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    console.log("download URL: ", url);

                    const updateUser = async () => {
                      const userRef = doc(db, "users", userId);

                      if (type === "face") {
                        await updateDoc(userRef, {
                          faceImagePath: url,
                        });
                      }

                      if (type === "backIdImage") {
                        await updateDoc(userRef, {
                          idBackImagePath: url,
                        });
                      }

                      if (type === "frontIdImage") {
                        await updateDoc(userRef, {
                          idFrontImagePath: url,
                        });
                      }
                    };

                    updateUser();
                  });
                }
              );
            }
          } catch (error) {
            console.log(error);
          }
        }

        handleImageCompression();
      }
    } else {
      setOpenSnackbar(true);
      setAlert(true);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mt: "1rem",
      }}
    >
      <input
        style={{ width: "65%", fontFamily: "Rubik, sans-serif" }}
        type="file"
        onChange={handleChange}
        accept="image/*"
        ref={inputRef}
      />
      {alert ? (
        <Typography
          sx={{
            fontFamily: "Rubik, sans-serif",
            mt: "0.6rem",
            fontSize: "0.75rem",
            color: "red",
          }}
        >
          Kindly choose an image!
        </Typography>
      ) : (
        ""
      )}
      <Button
        sx={{
          fontFamily: "Rubik, sans-serif",
          fontWeight: "500",
          fontSize: ".78rem",
          backgroundColor: "#009966",
          color: "white",
          width: "70%",
          mt: ".8rem",

          "&:hover": {
            backgroundColor: "rgba(0,153,102,.8)",
          },
        }}
        onClick={handleUpload}
      >
        Upload Image
      </Button>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isUploaded ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isUploaded
            ? "Image uploaded successfully!!!"
            : "Cannot upload image: " + errorMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Uploader;
