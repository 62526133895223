import React, { useState, forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";

import {
  Box,
  ListItemText,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  TextField,
  Button,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { passwordReset } from "../../firebase/firebase";

import { InputContainer, InputLabels } from "../styles/loginStyles";

export const PasswordResetDialog = () => {
  const [isReset, setIsReset] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // validating form inputs
  const PasswordResetSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required!"),
  });

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: PasswordResetSchema,
    onSubmit: async (values) => {
      const response = await passwordReset(values.email);

      if (response.isReset) {
        handleClose();
        setIsReset(true);
        setOpenSnackbar(true);
      } else {
        setErrorMsg(response.error);
        setOpenSnackbar(true);
      }
    },
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={isReset ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isReset
            ? "Password reset email sent successfully!!!"
            : "Cannot send password reset email: " + errorMsg}
        </Alert>
      </Snackbar>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemText
          sx={{
            width: "30%",
            textAlign: "right",
            fontFamily: "Rubik, sans-serif",
            color: "#009966",
            cursor: "pointer",

            "@media(max-width: 768px)": {
              color: "white",
            },
          }}
          primary="Forgot Password?"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle
          sx={{ fontFamily: "Rubik, sans-serif", fontWeight: "bold" }}
        >
          Enter email to receive password reset link
        </DialogTitle>
        <DialogContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Box sx={{ mt: "1.2rem" }}>
                <InputContainer>
                  <InputLabels>Email Address</InputLabels>
                  <TextField
                    sx={{ mb: "4rem" }}
                    placeholder="Enter your email"
                    variant="filled"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </InputContainer>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  sx={{
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "500",
                    fontSize: ".88rem",
                    color: "red",
                    border: "2px solid red",
                    borderRadius: "10px",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "500",
                    fontSize: ".88rem",
                    backgroundColor: "#009966",
                    color: "white",

                    "&:hover": {
                      backgroundColor: "rgba(0,153,102,.8)",
                    },
                  }}
                  type="submit"
                >
                  Send Reset Email
                </Button>
              </Box>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Dialog>{" "}
    </>
  );
};
