import React, { useState, forwardRef } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Link as RouterLink } from "react-router-dom";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import {
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { UsersOperations } from "../../firebase/operations";

export default function AssignRole({ adminId, adminData }) {
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const UpdateAdminSchema = Yup.object().shape({
    role: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      accessRole: "",
    },
    validationSchema: UpdateAdminSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await UsersOperations.assignRole(adminId, values);

      if (response.updated) {
        setIsUpdated(true);
        setOpenSnackbar(true);
        resetForm();
        handleClose();
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        setErrorMsg(response.err);
        setOpenSnackbar(true);
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isUpdated ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isUpdated
            ? "Admin Updated successfully!!!"
            : "Cannot Update Admin: " + errorMsg}
        </Alert>
      </Snackbar>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Assign Role"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle
          sx={{ fontFamily: "Rubik, sans-serif", fontWeight: "500" }}
        >
          Update {adminData.name} Access Role
        </DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  Access Role
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  {...getFieldProps("accessRole")}
                  name="accessRole"
                  label="Access Role"
                  error={Boolean(touched.accessRole && errors.accessRole)}
                  helperText={touched.accessRole && errors.accessRole}
                >
                  <MenuItem value="SuperAdmin">SuperAdmin</MenuItem>
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="CustomerSupport">CustomerSupport</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  color: "red",
                  border: "2px solid red",
                  borderRadius: "10px",
                  mr: "2rem",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  backgroundColor: "#009966",
                  color: "white",

                  "&:hover": {
                    backgroundColor: "rgba(0,153,102,.8)",
                  },
                }}
                type="submit"
              >
                Update
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
