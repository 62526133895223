import GroupIcon from "@mui/icons-material/Group";
import PaidIcon from "@mui/icons-material/Paid";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ShieldIcon from "@mui/icons-material/Shield";
// import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import NumbersIcon from "@mui/icons-material/Numbers";

const sidebarConfig = [
  {
    title: "clients",
    path: "/home/clients",
    label: "Clients",
    icon: <GroupIcon />,
  },
  {
    title: "transactions",
    path: "/home/transactions",
    label: "Transactions",
    icon: <PaidIcon />,
  },
  {
    title: "loans",
    path: "/home/loans",
    label: "Loans",
    icon: <CreditScoreIcon />,
  },
  {
    title: "Businesses",
    path: "/home/businesses",
    label: "Businesses",
    icon: <AddBusinessRoundedIcon />,
  },
  {
    title: "Airtime",
    path: "/home/airtime",
    label: "Airtime",
    icon: <AddIcCallIcon />,
  },
  // {
  //   title: "Fortune Accounts",
  //   path: "/home/fortune_accounts",
  //   label: "Fortune Accounts",
  //   icon: <AccountBalanceIcon />,
  // },
  {
    title: "Referral Codes",
    path: "/home/referral-codes",
    label: "Referral Codes",
    icon: <NumbersIcon />,
  },
  {
    title: "Support",
    path: "/home/support",
    label: "Support",
    icon: <HeadsetMicIcon />,
  },
  {
    title: "Access Management",
    path: "/home/access-management",
    label: "Access Management",
    icon: <ShieldIcon />,
  },
  {
    title: "settings",
    path: "/home/settings",
    label: "Settings",
    icon: <SettingsApplicationsIcon />,
  },
  {
    title: "Notifications",
    path: "/home/notifications",
    label: "Notifications",
    icon: <NotificationsActiveIcon />,
  },
];

export default sidebarConfig;
