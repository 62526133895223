import React, { useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";

import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Business from "./business";
import MerchantTransactions from "../Transactions/MerchantTransactions/merchantTransactions";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

export default function BusinessTabs() {
    const theme = useTheme();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <AppBar elevation={0} position="static" color="transparent">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{
                        "& button": {
                            backgroundColor: "#f2fffb",
                            color: "#757874",
                            fontWeight: "400",
                            fontFamily: "Rubik, sans-serif",
                            fontSize: ".88rem",
                        },
                        "& button.Mui-selected": {
                            fontFamily: "Rubik, sans-serif",
                            fontWeight: "700",
                            fontSize: ".88rem",
                            color: "#FFFFFF",
                            backgroundColor: "#009966",
                        },
                    }}
                    TabIndicatorProps={{
                        style: { backgroundColor: "#009966", height: "3px" },
                    }}
                    textColor="#009966"
                    variant="standard"
                    aria-label="full width tabs example"
                >
                    <Tab label="Lipa na Leja" {...a11yProps(0)} />
                    <Tab label="Business Transactions" {...a11yProps(1)} />
                    <Tab label="Merchant Businesses" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <Business viewMode={'businesses'} />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <MerchantTransactions />
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <Business viewMode={'merchant_businesses'}/>
                </TabPanel>
            </SwipeableViews>
        </Box>
    );
}
