import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { LoanOperations } from "../../firebase/operations";

export default function CheckLoanStatus({ loanId, loanData }) {
  const [open, setOpen] = useState(false);
  const [exists, setExists] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheck = async () => {
    const response = await LoanOperations.checkStatus(loanId);

    if (response.exists) {
      setOpen(true);
      setExists(true);
    } else {
      setOpen(true);
    }
  };

  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleCheck}
      >
        <ListItemIcon>
          <QuestionMarkIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Check Loan Status"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <DialogTitle
          sx={{
            fontFamily: "Rubik, sans-serif",
            fontWeight: "bold",
          }}
        >
          Loan Disbursement Status
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>
            The loan disbursement status for KSh.{" "}
            <span style={{ fontWeight: "bold" }}>
              {loanData?.amountRequested}
            </span>{" "}
            requested on{" "}
            <span style={{ fontWeight: "bold" }}>
              {typeof loanData?.dateRequested === "string"
                ? moment(loanData?.dateRequested, "DD-MM-YYYY").format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )
                : moment(loanData?.dateRequested.toDate()).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
            </span>{" "}
            was{" "}
            <span
              style={{ fontWeight: "bold", color: exists ? "#009966" : "red" }}
            >
              {exists ? "disbursed " : "NOT disbursed"}
            </span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button
            variant="contained"
            sx={{
              fontFamily: "Rubik, sans-serif",
              fontWeight: "500",
              fontSize: ".88rem",
              backgroundColor: "#009966",

              "&:hover": {
                backgroundColor: "rgba(0,153,102,.8)",
              },
            }}
            onClick={handleCheck}
          >
            Check Status
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
