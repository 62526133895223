import { useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
// material
import { Menu, IconButton } from "@mui/material";
import UpdateUser from "./updateUser";
import ViewUser from "./viewUser";
import DeleteUser from "./deleteUser";
import SuspendUser from "./suspendUser";
import ActivateUser from "./activateUser";
import CreateBusiness from "./onBoardBusiness";
import SuspendUserRefCode from "./suspendRefCode";
import UpdateDevice from "./updateDevice";

export default function UsersMoreMenu({ userId, userData }) {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: { width: 250, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <ViewUser userId={userId} userData={userData} />
        <UpdateUser userId={userId} userData={userData} />
        <DeleteUser userId={userId} userData={userData} />
        {userData?.isSuspended === false ||
        userData?.isSuspended === undefined ? (
          <SuspendUser userId={userId} userData={userData} />
        ) : (
          ""
        )}
        {userData?.isSuspended ? (
          <ActivateUser userId={userId} userData={userData} />
        ) : (
          ""
        )}
        {userData?.isRefCodeSuspended === false ||
        userData?.isRefCodeSuspended === undefined ? (
          <SuspendUserRefCode userId={userId} userData={userData} />
        ) : (
          ""
        )}
        <CreateBusiness userId={userId} userData={userData} />
        <UpdateDevice userId={userId} userData={userData} />
      </Menu>
      <Outlet />
    </>
  );
}
