import React, { useState, forwardRef } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { MessagesOperations } from "../../../firebase/operations";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// modal styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 4,
};

export default function CreateMessage(messageData) {
  const [open, setOpen] = useState(false);
  const [checker, setChecker] = useState(true);
  const [isCreated, setIsCreated] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const CreateMessageSchema = Yup.object().shape({
    messageType: Yup.string().required("Message Type is required"),
    messageDescription: Yup.string().required(
      "Message Description is required"
    ),
  });

  const formik = useFormik({
    initialValues: {
      messageType: "",
      messageDescription: "",
    },
    validationSchema: CreateMessageSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log("Message data: ", values);
      const response = await MessagesOperations.createMessage(
        messageData,
        values
      );
      console.log("RESPONSE: ", response);

      if (response.checker !== true) {
        setChecker(false);
      }

      if (response.created) {
        setIsCreated(true);
        setOpenSnackbar(true);
        handleClose();
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        setErrorMsg(response.err);
        setOpenSnackbar(true);
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div style={{ width: "20%" }}>
      {checker === false ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          elevation={4}
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              sx={{
                fontFamily: "Rubik, sans-serif",
                fontWeight: "500",
                fontSize: ".88rem",
              }}
            >
              This message type exists — create a new one!
            </Typography>
            <Button
              onClick={handleClose}
              sx={{
                fontFamily: "Rubik, sans-serif",
                fontWeight: "500",
                fontSize: ".88rem",
                color: "#009966",
              }}
            >
              Close
            </Button>
          </Box>
        </Modal>
      ) : (
        ""
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isCreated ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isCreated
            ? "Message Created successfully!!!"
            : "Cannot Create Message: " + errorMsg}
        </Alert>
      </Snackbar>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          fontFamily: "Rubik, sans-serif",
          fontWeight: "500",
          fontSize: ".88rem",
          backgroundColor: "#009966",

          "&:hover": {
            backgroundColor: "rgba(0,153,102,.8)",
          },
        }}
        startIcon={<AddOutlinedIcon />}
      >
        Add Message
      </Button>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle
          sx={{ fontFamily: "PRubik, sans-serif", fontWeight: "bold" }}
        >
          Add Message
        </DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="messageType"
                type="text"
                margin="normal"
                label="Message Type"
                {...getFieldProps("messageType")}
                error={Boolean(touched.messageType && errors.messageType)}
                helperText={touched.messageType && errors.messageType}
              />
              <TextField
                fullWidth
                autoComplete="messageDescription"
                type="text"
                multiline
                rows={4}
                margin="normal"
                label="Message Description"
                {...getFieldProps("messageDescription")}
                error={Boolean(
                  touched.messageDescription && errors.messageDescription
                )}
                helperText={
                  touched.messageDescription && errors.messageDescription
                }
              />
            </DialogContent>
            <DialogActions>
              <Button
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  color: "red",
                  border: "2px solid red",
                  borderRadius: "10px",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  backgroundColor: "#009966",
                  color: "white",

                  "&:hover": {
                    backgroundColor: "rgba(0,153,102,.8)",
                  },
                }}
                type="submit"
              >
                Create
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
