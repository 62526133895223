import React, { useState, forwardRef } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";

import { functions } from "../../../firebase/firebase";

import {
  Box,
  Typography,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { useCSVReader } from "react-papaparse";
import { httpsCallable } from "firebase/functions";

const styles = {
  csvReader: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  },
  browseFile: {
    width: "20%",
    fontFamily: "Rubik, sans-serif",
    fontWeight: "500",
    fontSize: ".88rem",
    backgroundColor: "#009966",
    color: "white",
    borderRadius: "7px 0 0 7px",
    border: "none",
    cursor: "pointer",
  },
  acceptedFile: {
    border: "1px solid #ccc",
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: "80%",
  },
  remove: {
    borderRadius: "0 7px 7px 0",
    padding: "0 20px",
    border: "none",
    fontFamily: "Rubik, sans-serif",
    backgroundColor: "red",
    color: "white",
    fontWeight: "500",
    cursor: "pointer",
  },
  progressBarBackgroundColor: {
    backgroundColor: "#009966",
  },
};

// customizable alert
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PromotionalMsgs() {
  const { CSVReader } = useCSVReader();
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const sendBulkMessages = (data, resetForm) => {
    const sendMessages = httpsCallable(functions, "onBulkSms");
    console.log("Bulk messages details to be sent: ", data);
    try {
      sendMessages(data);
      resetForm();
      setIsSent(true);
      setOpenSnackbar(true);
    } catch (error) {
      setErrorMsg(error);
      setOpenSnackbar(true);
      return error;
    }
  };

  const bulkMsgsSchema = Yup.object().shape({
    message: Yup.string().required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema: bulkMsgsSchema,
    onSubmit: (values, { resetForm }) => {
      values["phoneNumbers"] = phoneNumbers;
      sendBulkMessages(values, resetForm);
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={isSent ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isSent
            ? "Messages Sent successfully!!!"
            : "Cannot Send Messages: " + errorMsg}
        </Alert>
      </Snackbar>
      <CSVReader
        onUploadAccepted={(results) => {
          results?.data.shift(); // remove label
          results?.data.pop(); // remove last empty string
          setPhoneNumbers(results?.data.join().split(","));
        }}
      >
        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
          <>
            <Typography
              sx={{
                fontFamily: "Rubik, sans-serif",
                mb: "1rem",
                fontSize: "1.2rem",
              }}
            >
              Upload Recipient Numbers
            </Typography>
            <div style={styles.csvReader}>
              <button
                type="button"
                {...getRootProps()}
                style={styles.browseFile}
              >
                Browse file
              </button>
              <div style={styles.acceptedFile}>
                {acceptedFile && acceptedFile.name}
              </div>
              <button {...getRemoveFileProps()} style={styles.remove}>
                Remove
              </button>
            </div>
            <ProgressBar style={styles.progressBarBackgroundColor} />
          </>
        )}
      </CSVReader>
      <Box>
        <Typography
          sx={{
            fontFamily: "Rubik, sans-serif",
            mt: "2rem",
            fontSize: "1.2rem",
          }}
        >
          Type Message
        </Typography>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="message"
                type="text"
                multiline
                rows={4}
                margin="normal"
                label="Message"
                {...getFieldProps("message")}
                error={Boolean(touched.message && errors.message)}
                helperText={touched.message && errors.message}
              />
            </DialogContent>
            <DialogActions>
              <Button
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  color: "red",
                  border: "2px solid red",
                  borderRadius: "10px",
                  marginRight: "2rem",
                }}
                type="reset"
              >
                Cancel
              </Button>
              <Button
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  backgroundColor: "#009966",
                  color: "white",

                  "&:hover": {
                    backgroundColor: "rgba(0,153,102,.8)",
                  },
                }}
                type="submit"
              >
                Send
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Box>
    </>
  );
}
