import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { MessagesOperations } from "../../../firebase/operations";
import MessagesMoreMenu from "./messagesMoreMenu";

import { EnhancedTableToolbar } from "./messagesToolbar";
import CircularStatic from "../../shared/Loader/loader";

import { tableStyles } from "../../styles/tableStyles";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: "true",
});

const getMuiTheme = () => createTheme(tableStyles);

export default function Messages() {
  const [data, setData] = useState(); // table rows
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns = [
    {
      name: "",
      label: "Message Type",
      options: {
        filter: false,
      },
    },
    {
      name: "",
      label: "Message Description",
      options: {
        filter: false,
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex]) {
            return (
              <MessagesMoreMenu
                messageId={data[dataIndex][0]}
                messageData={data[dataIndex]}
              />
            );
          }
        },
      },
    },
  ];

  const options = {
    responsive: "standard",
    rowsPerPage,
    rowsPerPageOptions: [10, 25, 50, 100],
    download: true,
    print: false,
    filter: false,
    viewColumns: false,
  };

  // pull data from firebase and set table rows
  useEffect(() => {
    MessagesOperations.fetchMessages()
      .then((res) => {
        res.map((message) => {
          setData(Object.entries(message));
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
  }, []);

  return (
    <div style={{ backgroundColor: "#F7F7F7" }}>
      <EnhancedTableToolbar messageData={data} />
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme()}>
          {isLoading ? (
            <CircularStatic />
          ) : (
            <MUIDataTable data={data} columns={columns} options={options} />
          )}
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
}
