import { Tooltip, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

export const StatusStyles = (status) => {
  if (status === "cancelled") {
    return (
      <Tooltip
        title="Transaction has failed checks or manually cancelled."
        placement="bottom"
      >
        <Typography
          sx={{
            color: "red",
            fontFamily: "Rubik, sans-serif",
            fontWeight: "600",
            fontSize: ".88rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            backgroundColor: "rgba(255,0,0,.2)",
            padding: ".2rem 1rem",
            width: "60%",
            borderRadius: "7px",
          }}
        >
          <span
            style={{
              marginRight: ".5rem",
            }}
          >
            <CircleIcon sx={{ fontSize: ".6rem", color: "red" }} />
          </span>
          Cancelled
        </Typography>
      </Tooltip>
    );
  } else if (status === "processed") {
    return (
      <Tooltip title="Triggered Safaricom to send B2C." placement="bottom">
        <Typography
          sx={{
            color: "#009966",
            fontFamily: "Rubik, sans-serif",
            fontWeight: "600",
            fontSize: ".88rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            backgroundColor: "rgba(0,153,102,.2)",
            padding: ".2rem 1rem",
            width: "60%",
            borderRadius: "7px",
          }}
        >
          <span
            style={{
              marginRight: ".5rem",
            }}
          >
            <CircleIcon sx={{ fontSize: ".6rem", color: "#009966" }} />
          </span>{" "}
          Processed
        </Typography>
      </Tooltip>
    );
  } else if (status === "approved") {
    return (
      <Tooltip title="Transaction passed all tests." placement="bottom">
        <Typography
          sx={{
            color: "#4B6043",
            fontFamily: "Rubik, sans-serif",
            fontWeight: "600",
            fontSize: ".88rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            backgroundColor: "rgba(75,96,67,.2)",
            padding: ".2rem 1rem",
            width: "60%",
            borderRadius: "7px",
          }}
        >
          <span
            style={{
              marginRight: ".5rem",
            }}
          >
            <CircleIcon sx={{ fontSize: ".6rem", color: "#4B6043" }} />
          </span>{" "}
          Approved
        </Typography>
      </Tooltip>
    );
  } else if (status === "manual_sent") {
    return (
      <Tooltip title="Status has been manually changed." placement="bottom">
        <Typography
          sx={{
            color: "#03C04A",
            fontFamily: "Rubik, sans-serif",
            fontWeight: "600",
            fontSize: ".88rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            backgroundColor: "rgba(3,192,74,.2)",
            padding: ".2rem 1rem",
            width: "98%",
            borderRadius: "7px",
          }}
        >
          <span
            style={{
              marginRight: ".5rem",
            }}
          >
            <CircleIcon sx={{ fontSize: ".6rem", color: "#03C04A" }} />
          </span>{" "}
          Manual Sent
        </Typography>
      </Tooltip>
    );
  } else if (status === "sent") {
    return (
      <Tooltip
        title="Money received by recipient. B2C Callback came back."
        placement="bottom"
      >
        <Typography
          sx={{
            color: "#3BB143",
            fontFamily: "Rubik, sans-serif",
            fontWeight: "600",
            fontSize: ".88rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            backgroundColor: "rgba(59,177,67,.2)",
            padding: ".2rem 1rem",
            width: "60%",
            borderRadius: "7px",
          }}
        >
          <span
            style={{
              marginRight: ".5rem",
            }}
          >
            <CircleIcon sx={{ fontSize: ".6rem", color: "#3BB143" }} />
          </span>{" "}
          Sent
        </Typography>
      </Tooltip>
    );
  } else if (status === "failed") {
    return (
      <Tooltip title="Transaction Failed." placement="bottom">
        <Typography
          sx={{
            color: "#C30010",
            fontFamily: "Rubik, sans-serif",
            fontWeight: "600",
            fontSize: ".88rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            backgroundColor: "rgba(95,0,16,.2)",
            padding: ".2rem 1rem",
            width: "60%",
            borderRadius: "7px",
          }}
        >
          <span
            style={{
              marginRight: ".5rem",
            }}
          >
            <CircleIcon sx={{ fontSize: ".6rem", color: "#C30010" }} />
          </span>{" "}
          Failed
        </Typography>
      </Tooltip>
    );
  } else if (status === "manual_transfer") {
    return (
      <Tooltip
        title="Transaction was manually sent to recipient."
        placement="bottom"
      >
        <Typography
          sx={{
            color: "#0047AB",
            fontFamily: "Rubik, sans-serif",
            fontWeight: "600",
            fontSize: ".88rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            backgroundColor: "rgba(0,71,171,.2)",
            padding: ".2rem 1rem",
            width: "60%",
            borderRadius: "7px",
          }}
        >
          <span
            style={{
              marginRight: ".5rem",
            }}
          >
            <CircleIcon sx={{ fontSize: ".6rem", color: "#0047AB" }} />
          </span>{" "}
          Manual Transfer
        </Typography>
      </Tooltip>
    );
  } else if (status === "manual_cancelled") {
    return (
      <Tooltip
        title="Transaction cancellation has been triggered manually."
        placement="bottom"
      >
        <Typography
          sx={{
            color: "#A30000",
            fontFamily: "Rubik, sans-serif",
            fontWeight: "600",
            fontSize: ".88rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            backgroundColor: "rgba(163,0,0,.2)",
            padding: ".2rem 1rem",
            width: "60%",
            borderRadius: "7px",
          }}
        >
          <span
            style={{
              marginRight: ".5rem",
            }}
          >
            <CircleIcon sx={{ fontSize: ".6rem", color: "#A30000" }} />
          </span>{" "}
          Manual Cancelled
        </Typography>
      </Tooltip>
    );
  } else if (status === "received") {
    return (
      <Tooltip title="C2B callback received." placement="bottom">
        <Typography
          sx={{
            color: "#228C22",
            fontFamily: "Rubik, sans-serif",
            fontWeight: "600",
            fontSize: ".88rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            backgroundColor: "rgba(34,140,34,.2)",
            padding: ".2rem 1rem",
            width: "60%",
            borderRadius: "7px",
          }}
        >
          <span
            style={{
              marginRight: ".5rem",
            }}
          >
            <CircleIcon sx={{ fontSize: ".6rem", color: "#228C22" }} />
          </span>{" "}
          Received
        </Typography>
      </Tooltip>
    );
  } else if (status === "stk_sent") {
    return (
      <Tooltip title="STK push sent to sender." placement="bottom">
        <Typography
          sx={{
            color: "#299617",
            fontFamily: "Rubik, sans-serif",
            fontWeight: "600",
            fontSize: ".88rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            backgroundColor: "rgba(41,150,23,.2)",
            padding: ".2rem 1rem",
            width: "60%",
            borderRadius: "7px",
          }}
        >
          <span
            style={{
              marginRight: ".5rem",
            }}
          >
            <CircleIcon sx={{ fontSize: ".6rem", color: "#299617" }} />
          </span>{" "}
          STK Sent
        </Typography>
      </Tooltip>
    );
  } else if (status === "reverse") {
    return (
      <Tooltip title="Transaction reverse is triggered." placement="bottom">
        <Typography
          sx={{
            color: "#A53E76",
            fontFamily: "Rubik, sans-serif",
            fontWeight: "600",
            fontSize: ".88rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            backgroundColor: "rgba(165,62,118,.2)",
            padding: ".2rem 1rem",
            width: "60%",
            borderRadius: "7px",
          }}
        >
          <span
            style={{
              marginRight: ".5rem",
            }}
          >
            <CircleIcon sx={{ fontSize: ".6rem", color: "#A53E76" }} />
          </span>{" "}
          Reverse Triggered
        </Typography>
      </Tooltip>
    );
  } else if (status === "reversed") {
    return (
      <Tooltip title="Transaction reversed to sender." placement="bottom">
        <Typography
          sx={{
            color: "#800020",
            fontFamily: "Rubik, sans-serif",
            fontWeight: "600",
            fontSize: ".88rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            backgroundColor: "rgba(128,0,32,.2)",
            padding: ".2rem 1rem",
            width: "60%",
            borderRadius: "7px",
          }}
        >
          <span
            style={{
              marginRight: ".5rem",
            }}
          >
            <CircleIcon sx={{ fontSize: ".6rem", color: "#800020" }} />
          </span>{" "}
          Reversed
        </Typography>
      </Tooltip>
    );
  } else if (status === "check_status") {
    return (
      <Tooltip
        title="Checking transaction status from safaricom."
        placement="bottom"
      >
        <Typography
          sx={{
            color: "#ff781f",
            fontFamily: "Rubik, sans-serif",
            fontWeight: "600",
            fontSize: ".88rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            backgroundColor: "rgba(255,120,31,.2)",
            padding: ".2rem 1rem",
            width: "60%",
            borderRadius: "7px",
          }}
        >
          <span
            style={{
              marginRight: ".5rem",
            }}
          >
            <CircleIcon sx={{ fontSize: ".6rem", color: "#ff781f" }} />
          </span>{" "}
          Checking Status...
        </Typography>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={status} placement="bottom">
        <Typography
          sx={{
            color: "#AA5B00",
            fontFamily: "Rubik, sans-serif",
            fontWeight: "600",
            fontSize: ".88rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            backgroundColor: "rgba(170,91,0,.2)",
            padding: ".2rem 1rem",
            width: "95%",
            borderRadius: "7px",
          }}
        >
          <span
            style={{
              marginRight: ".5rem",
            }}
          >
            <CircleIcon sx={{ fontSize: ".6rem", color: "#AA5B00" }} />
          </span>{" "}
          {status}
        </Typography>
      </Tooltip>
    );
  }
};
