import React, { useState } from "react";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";

import {
  Typography,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

// styles
const boxContainer = {
  display: "flex",
  justifyContent: "space-around",
  width: "100%",
};

const box = {
  display: "flex",
  marginLeft: "1rem",
};

export default function ViewRefCode({ codeData }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <MenuItem
        component={RouterLink}
        key="view"
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <VisibilityIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="View Ref Code"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        onBackdropClick="false"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>
          Referral Code Details
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "100%",
              marginBottom: "2rem",
              fontFamily: "Rubik, sans-serif",
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "bold",
                textDecoration: "underline",
                fontFamily: "Rubik, sans-serif",
              }}
            >
              General Details
            </Typography>
            <Box sx={boxContainer}>
              <Box sx={box}>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Referral Code:
                </Typography>
                <p
                  style={{
                    paddingLeft: "1rem",
                    margin: "0",
                    fontFamily: "Rubik, sans-serif",
                  }}
                >
                  {codeData?.refCode}
                </p>
              </Box>
              <Box sx={box}>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Date Created:
                </Typography>
                <p
                  style={{
                    paddingLeft: "1rem",
                    margin: "0",
                    fontFamily: "Rubik, sans-serif",
                  }}
                >
                  {codeData?.dateCreated
                    ? moment(codeData?.dateCreated?.toDate()).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )
                    : "No Date Available"}
                </p>
              </Box>
              <Box sx={box}>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Last Updated:
                </Typography>
                <p
                  style={{
                    paddingLeft: "1rem",
                    margin: "0",
                    fontFamily: "Rubik, sans-serif",
                  }}
                >
                  {codeData?.lastUpdated
                    ? moment(codeData?.lastUpdated?.toDate()).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )
                    : "No Date Available"}
                </p>
              </Box>
              <Box sx={box}>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Ref Code Status:
                </Typography>
                <p
                  style={{
                    paddingLeft: "1rem",
                    margin: "0",
                    fontFamily: "Rubik, sans-serif",
                  }}
                >
                  {codeData?.status}
                </p>
              </Box>
            </Box>
            <Divider
              sx={{
                borderBottomWidth: 3,
                marginBottom: "1rem",
                marginTop: "1rem",
                backgroundColor: "333333",
              }}
            />
            <Box
              sx={{
                ...boxContainer,
                marginTop: "1rem",
                fontFamily: "Rubik, sans-serif",
              }}
            >
              <Box>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Referral Code Messages:
                </Typography>
                {codeData?.messages?.length > 0 ? (
                  <ul style={{ margin: "0", fontFamily: "Rubik, sans-serif" }}>
                    {codeData?.messages?.map((message, index) => (
                      <li key={index}>{message}</li>
                    ))}
                  </ul>
                ) : (
                  <p
                    style={{
                      paddingLeft: "1rem",
                      margin: "0",
                      fontFamily: "Rubik, sans-serif",
                    }}
                  >
                    No Messages
                  </p>
                )}
              </Box>
              <Box>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Referred:
                </Typography>
                <Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontFamily: "Rubik, sans-serif",
                      }}
                    >
                      Referred Name:
                    </Typography>
                    <p
                      style={{
                        paddingLeft: "1rem",
                        margin: "0",
                        fontFamily: "Rubik, sans-serif",
                      }}
                    >
                      {codeData?.referred.firstName}{" "}
                      {codeData?.referred.lastName}
                    </p>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontFamily: "Rubik, sans-serif",
                      }}
                    >
                      Referred Phone Number:
                    </Typography>
                    <p
                      style={{
                        paddingLeft: "1rem",
                        margin: "0",
                        fontFamily: "Rubik, sans-serif",
                      }}
                    >
                      {codeData?.referred.phoneNumber}
                    </p>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Referrer:
                </Typography>
                <Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontFamily: "Rubik, sans-serif",
                      }}
                    >
                      Referrer Name:
                    </Typography>
                    <p
                      style={{
                        paddingLeft: "1rem",
                        margin: "0",
                        fontFamily: "Rubik, sans-serif",
                      }}
                    >
                      {codeData?.referrer.firstName}{" "}
                      {codeData?.referrer.lastName}
                    </p>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontFamily: "Rubik, sans-serif",
                      }}
                    >
                      Referrer Phone Number:
                    </Typography>
                    <p
                      style={{
                        paddingLeft: "1rem",
                        margin: "0",
                        fontFamily: "Rubik, sans-serif",
                      }}
                    >
                      {codeData?.referrer.phoneNumber}
                    </p>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Divider
              sx={{
                borderBottomWidth: 3,
                marginBottom: "1rem",
                marginTop: "1rem",
                backgroundColor: "333333",
              }}
            />
            <Box>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  fontFamily: "Rubik, sans-serif",
                }}
              >
                Reward Details
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {codeData?.rewards.map((reward, index) => (
                  <Box
                    key={index}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontFamily: "Rubik, sans-serif",
                        }}
                      >
                        Reward Phone Number:
                      </Typography>
                      <p
                        style={{
                          paddingLeft: "1rem",
                          margin: "0",
                          fontFamily: "Rubik, sans-serif",
                        }}
                      >
                        {reward.toPhoneNumber}
                      </p>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontFamily: "Rubik, sans-serif",
                        }}
                      >
                        Reward Amount:
                      </Typography>
                      <p
                        style={{
                          paddingLeft: "1rem",
                          margin: "0",
                          fontFamily: "Rubik, sans-serif",
                        }}
                      >
                        {reward.amount}
                      </p>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontFamily: "Rubik, sans-serif",
                        }}
                      >
                        Reward Event:
                      </Typography>
                      <p
                        style={{
                          paddingLeft: "1rem",
                          margin: "0",
                          fontFamily: "Rubik, sans-serif",
                        }}
                      >
                        {reward.event}
                      </p>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontFamily: "Rubik, sans-serif",
                        }}
                      >
                        Reward is claimed?:
                      </Typography>
                      {reward.isClaimed ? (
                        <p
                          style={{
                            paddingLeft: "1rem",
                            margin: "0",
                            fontFamily: "Rubik, sans-serif",
                            fontWeight: "bold",
                            color: "#009966",
                          }}
                        >
                          Yes
                        </p>
                      ) : (
                        <p
                          style={{
                            paddingLeft: "1rem",
                            margin: "0",
                            fontFamily: "Rubik, sans-serif",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          No
                        </p>
                      )}
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontFamily: "Rubik, sans-serif",
                        }}
                      >
                        Reward Note:
                      </Typography>
                      <p
                        style={{
                          paddingLeft: "1rem",
                          margin: "0",
                          fontFamily: "Rubik, sans-serif",
                        }}
                      >
                        {reward.note}
                      </p>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontFamily: "Rubik, sans-serif",
                        }}
                      >
                        Reward Type:
                      </Typography>
                      <p
                        style={{
                          paddingLeft: "1rem",
                          margin: "0",
                          fontFamily: "Rubik, sans-serif",
                        }}
                      >
                        {reward.type}
                      </p>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
