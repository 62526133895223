import React, { useState, forwardRef, useRef } from "react";
import moment from "moment";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { Link as RouterLink } from "react-router-dom";
import {
  collection,
  orderBy,
  limit,
  serverTimestamp,
  setDoc,
  doc,
} from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import VisibilityIcon from "@mui/icons-material/Visibility";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import SendIcon from "@mui/icons-material/Send";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MessageIcon from "@mui/icons-material/Message";
import ReplyIcon from "@mui/icons-material/Reply";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  Box,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import PersonIcon from "@mui/icons-material/Person";

import { db } from "../../../firebase/firebase";
import NoData from "../../shared/NoData/noData";
import { TicketsOperations } from "../../../firebase/operations";
import MoreInfo from "./moreInfo";
import MessageTemplate from "./messageTemplate";
import CreateTemplate from "./createTemplate";
import Templates from "./templates";

export const ViewTicket = ({ ticketId, ticketData }) => {
  const [open, setOpen] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [openEditTemplate, setOpenEditTemplate] = useState(false);
  const [message, setMessage] = useState("");

  const messageRef = useRef(null);

  const comments = [];
  const sessionUser = JSON.parse(localStorage.getItem("user")); // session user

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleEditTemplateOpen = () => {
    setOpenEditTemplate(true);
  };

  const handleEditTemplateClose = () => {
    setOpenEditTemplate(false);
  };

  const handleTemplateOpen = () => {
    setOpenTemplate(true);
  };

  const handleTemplateClose = () => {
    setOpenTemplate(false);
  };

  // handle message setting
  const handleMessageSet = (msg) => {
    setMessage(msg);
  };

  // handle close ticket
  const handleCloseTicket = async () => {
    const response = await TicketsOperations.closeTicket(ticketId);

    console.log("RESPONSE: ", response);

    if (response) {
      setIsClosed(true);
      setOpenSnackbar(true);
      handleClose();
      window.location.reload();
    } else {
      setOpenSnackbar(true);
    }
  };

  // get customer rep's name
  const getName = () => {
    const name = sessionUser.user.displayName;
    let email = sessionUser.user.email;
    let extractedName = email.split("@")[0];

    if (name) {
      return name;
    } else {
      return extractedName;
    }
  };

  // handle send sms
  const handleSendSms = async (msg) => {
    try {
      const changes = {
        type: "manually_sent_sms",
        owner: "ASILIMIA",
        smsSenderId: "ChangeAgent",
        sendDateTime: moment().toDate(),
        sendOption: "now",
        sentBy: sessionUser.user.uid,
        sentByEmail: sessionUser.user.email,
        description: "Ticket response message",
        messageText: msg,
        recipients: [ticketData.phoneNumber],
        status: "queued",
        dateCreated: serverTimestamp(),
        lastUpdated: serverTimestamp(),
      };
      console.log("CHANGES: ", changes);
      const response = await TicketsOperations.sendSms(changes);

      if (response.isSent) {
        handleClose();
      }
    } catch (error) {
      console.error("SEND SMS ERROR: ", error);
    }
  };

  //fetch comments
  const commentsQuery = collection(db, `tickets/${ticketId}/comments`);

  const [docs, loading, error] = useCollectionData(
    commentsQuery,
    orderBy("dateCreated", "desc"),
    limit(5)
  );

  docs?.map((doc) => {
    comments.push(doc);
  });

  console.log("COMMENTS: ", comments);

  // reply to customer schema
  const replySchema = Yup.object().shape({
    description: Yup.string().required("Comment is required!"),
  });

  const formik = useFormik({
    initialValues: {
      commentType: "agentReply",
      description: "",
      ticketId: ticketId,
      customerRepId: sessionUser.user.uid,
      displayName: getName() + " ~ Customer Rep",
      dateCreated: serverTimestamp(),
    },
    validationSchema: replySchema,
    onSubmit: async (values, { resetForm }) => {
      const commentRef = await setDoc(doc(commentsQuery), {
        ...values,
        uid: commentsQuery.id,
      });

      const response = await TicketsOperations.closeTicket(ticketId);

      console.log("RESPONSE: ", response);

      if (response) {
        setIsClosed(true);
        setOpenSnackbar(true);
        handleClose();
        window.location.reload();
      } else {
        setOpenSnackbar(true);
      }

      if (commentRef.id) {
        resetForm();
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={isClosed ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isClosed
            ? "Ticket closed successfully!!!"
            : "Cannot close Ticket!!!"}
        </Alert>
      </Snackbar>
      <CreateTemplate
        handleTemplateOpen={handleTemplateOpen}
        handleTemplateClose={handleTemplateClose}
        openTemplate={openTemplate}
      />
      <Templates
        handleEditTemplateClose={handleEditTemplateClose}
        openEditTemplate={openEditTemplate}
      />
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <VisibilityIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="View Ticket"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        onBackdropClick="false"
      >
        <DialogTitle
          sx={{ fontFamily: "Rubik, sans-serif", fontWeight: "bold" }}
        >
          Ticket Details
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ width: "48%" }}>
              <Box
                sx={{
                  backgroundColor: "rgb(237,237,237,1)",
                  padding: "1rem 0.5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    <PersonIcon sx={{ mr: ".5rem" }} /> {ticketData.displayName}
                  </Typography>
                  <Typography sx={{ mr: "2rem" }}>
                    <span
                      style={{
                        fontFamily: "Rubik, sans-serif",
                        fontWeight: "bold",
                      }}
                    >
                      Ticket Id:{" "}
                    </span>
                    {ticketData.ticketId}
                  </Typography>
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <Typography
                    sx={{
                      fontFamily: "Rubik, sans-serif",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PriorityHighIcon sx={{ color: "red", mr: ".5rem" }} />{" "}
                    {ticketData.subject} -{" "}
                    {ticketData.issue
                      ? `${ticketData.issue
                          .charAt(0)
                          .toUpperCase()}${ticketData.issue.slice(
                          1,
                          ticketData.issue.length
                        )}`
                      : ""}
                  </Typography>
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <Typography
                    sx={{
                      fontFamily: "Rubik, sans-serif",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AccessTimeIcon sx={{ mr: ".5rem" }} />{" "}
                    {moment(ticketData.dateCreated.toDate()).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Rubik, sans-serif",
                      fontSize: ".8rem",
                      mt: "0.8rem",
                      fontWeight: "bold",
                      color: "gray",
                    }}
                  >
                    Last Updated{" "}
                    {moment(ticketData.dateCreated.toDate()).fromNow()}
                  </Typography>
                </div>
              </Box>
              <Box sx={{ m: "1.2rem 0" }}>
                <Typography
                  sx={{
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  Ticket Description:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {ticketData.description}
                  </span>
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: "rgb(237,237,237,1)",
                  padding: "1rem 0.5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "Rubik, sans-serif",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Rubik, sans-serif",
                        fontWeight: "bold",
                        marginRight: ".5rem",
                      }}
                    >
                      Email:{" "}
                    </span>{" "}
                    {ticketData.email ? ticketData.email : "Not Provided"}
                  </Typography>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "Rubik, sans-serif",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Rubik, sans-serif",
                        fontWeight: "bold",
                        marginRight: ".5rem",
                      }}
                    >
                      Phone Number:{" "}
                    </span>
                    {ticketData.phoneNumber
                      ? ticketData.phoneNumber
                      : "Not Provided"}
                  </Typography>
                </div>
              </Box>
              <Box sx={{ m: "1.2rem 0" }}>
                <Typography
                  sx={{
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  Previous Comments
                </Typography>
                {comments.length > 0 ? (
                  comments.map((comment, index) => (
                    <Box
                      key={index}
                      sx={{
                        mt: "0.7rem",
                        borderBottom: "2px solid lightGray",
                        paddingBottom: ".5rem",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Rubik, sans-serif",
                          fontWeight: "bold",
                        }}
                      >
                        {comment.displayName}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Rubik, sans-serif",
                          fontSize: ".8rem",
                          fontWeight: "bold",
                          color: "gray",
                        }}
                      >
                        {moment(comment?.dateCreated?.toDate()).fromNow()}
                      </Typography>
                      <Typography
                        sx={{
                          mt: "0.2rem",
                          fontFamily: "Rubik, sans-serif",
                          fontStyle: "italic",
                        }}
                      >
                        "{comment.description}"
                      </Typography>
                    </Box>
                  ))
                ) : (
                  <NoData text="No Previous Comments!" />
                )}
              </Box>
              <Box
                sx={{
                  backgroundColor: "rgb(237,237,237,1)",
                  padding: "1rem 0.5rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  Reply to Client
                </Typography>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <MessageTemplate
                      messageRef={messageRef}
                      handleMessageSet={handleMessageSet}
                      touched={touched}
                      errors={errors}
                      getFieldProps={getFieldProps}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        mt: ".5rem",
                      }}
                    >
                      <Button
                        color="secondary"
                        variant="outlined"
                        sx={{
                          fontFamily: "Rubik, sans-serif",
                          fontSize: "0.7rem",
                        }}
                        type="reset"
                        startIcon={<ClearIcon sx={{ color: "purple" }} />}
                      >
                        Reset
                      </Button>
                      <Button
                        onClick={() => handleSendSms(message)}
                        variant="contained"
                        sx={{
                          fontFamily: "Rubik, sans-serif",
                          fontSize: "0.7rem",
                          backgroundColor: "#FA8128",
                        }}
                        startIcon={<SendIcon sx={{ color: "white" }} />}
                      >
                        Send SMS
                      </Button>
                      <Button
                        onClick={handleTemplateOpen}
                        variant="contained"
                        sx={{
                          fontFamily: "Rubik, sans-serif",
                          fontSize: "0.7rem",
                          backgroundColor: "#0000FF",
                        }}
                        startIcon={<ReplyIcon sx={{ color: "white" }} />}
                      >
                        Add template
                      </Button>
                      <Button
                        type="submit"
                        color="success"
                        variant="contained"
                        sx={{
                          fontFamily: "Rubik, sans-serif",
                          fontSize: "0.7rem",
                        }}
                        startIcon={<CheckIcon sx={{ color: "white" }} />}
                      >
                        Submit Response
                      </Button>
                    </Box>
                  </Form>
                </FormikProvider>
              </Box>
              <Box
                sx={{
                  mt: "2rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={handleEditTemplateOpen}
                  variant="outlined"
                  sx={{
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "0.7rem",
                    color: "#0000FF",
                    border: "1px solid #0000FF",
                    padding: "0.4rem 0.6rem",
                  }}
                  startIcon={<MessageIcon sx={{ color: "#0000FF" }} />}
                >
                  Templates
                </Button>
                <Button
                  onClick={handleCloseTicket}
                  variant="contained"
                  sx={{
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "0.7rem",
                    backgroundColor: "#009966",
                  }}
                  startIcon={<HighlightOffIcon sx={{ color: "white" }} />}
                >
                  Close Ticket
                </Button>
              </Box>
            </Box>
            <Box sx={{ width: "48%" }}>
              <Box
                sx={{
                  backgroundColor: "rgb(237,237,237,1)",
                  padding: "1rem 0.5rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  More Information
                </Typography>
              </Box>
              <MoreInfo ticketId={ticketId} ticketData={ticketData} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
