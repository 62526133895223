import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { tableStyles } from "../../styles/tableStyles";
import CircularStatic from "../../shared/Loader/loader";
import TemplateMoreMenu from "./templatesMoreMenu";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: "true",
});

const getMuiTheme = () => createTheme(tableStyles);

export default function Templates({
  handleEditTemplateClose,
  openEditTemplate,
}) {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchTemplates = async () => {
      const fetchedTemplates = await getDocs(
        collection(db, "ticket_response_templates")
      );

      let templates = [];

      fetchedTemplates.forEach((doc) =>
        templates.push({
          docId: doc.id,
          ...doc.data(),
        })
      );

      setRows(templates);
      setIsLoading(false);
    };

    fetchTemplates();
  }, []);

  const columns = [
    {
      name: "template",
      label: "Template",
      options: {
        filter: false,
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          if (rows[dataIndex]) {
            return (
              <TemplateMoreMenu
                templateId={rows[dataIndex]["docId"]}
                templateData={rows[dataIndex]}
              />
            );
          }
        },
      },
    },
  ];

  const handleChangePage = (tableState) => {
    const { page } = tableState;
    setPage(page);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage, 10));
    setPage(0);
  };

  const options = {
    responsive: "standard",
    rowsPerPage,
    rowsPerPageOptions: [10, 25, 50, 100],
    download: false,
    print: false,
    filter: false,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handleChangePage(tableState);
          break;
        case "changeRowsPerPage":
          handleChangeRowsPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    setRowProps: (row) => {
      if (row[4] === "open" || row[4] === "reOpened") {
        return {
          style: {
            backgroundColor: "#E5E5E5",
          },
        };
      }
    },
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={openEditTemplate}
        onClose={handleEditTemplateClose}
      >
        <DialogTitle>
          <Typography
            sx={{
              fontFamily: "Rubik, sans-serif",
              fontWeight: "500",
              fontSize: "1.5rem",
            }}
          >
            Templates
          </Typography>
        </DialogTitle>
        <DialogContent>
          <CacheProvider value={muiCache}>
            <ThemeProvider theme={getMuiTheme()}>
              {isLoading ? (
                <CircularStatic />
              ) : (
                <MUIDataTable data={rows} columns={columns} options={options} />
              )}
            </ThemeProvider>
          </CacheProvider>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              fontFamily: "Rubik, sans-serif",
              fontWeight: "500",
              fontSize: ".88rem",
              backgroundColor: "#009966",
              color: "white",
              mr: "2rem",

              "&:hover": {
                backgroundColor: "rgba(0,153,102,.8)",
              },
            }}
            onClick={handleEditTemplateClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
