import React, { useState, forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { httpsCallable } from "firebase/functions";

import { functions } from "../../../firebase/firebase";

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";

export default function ConfirmPayment({ loanId, loanData }) {
  const [open, setOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const { pendingAction } = loanData;

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // handle payment confirmation
  const handlePayment = async () => {
    const confirmPayment = httpsCallable(
      functions,
      "loanV2Group-onManualRepay"
    );
    confirmPayment({ loanId })
      .then((response) => {
        console.log("RESPONSE: ", response);
        if (response.data.status === "success") {
          setIsConfirmed(true);
          setOpenSnackbar(true);
          handleClose();
        } else {
          setOpenSnackbar(true);
        }
      })
      .catch((error) => {
        console.log("Payment Confirmation Error Message: ", error.message);
        console.log("Payment Confirmation Error Code: ", error.code);
      });
  };

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isConfirmed ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isConfirmed
            ? "Payment confirmed successfully!!!"
            : "Cannot confirm payment!!!"}
        </Alert>
      </Snackbar>
      <MenuItem
        disabled={
          loanData?.pendingAction?.type === "repay" ||
          loanData?.pendingAction?.type === "cancel"
        }
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <CheckIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Confirm Payment"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        onBackdropClick="false"
        maxWidth="xl"
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            fontFamily: "Rubik, sans-serif",
            textDecoration: "underline",
          }}
        >
          Confirm Payment
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{ fontWeight: "normal", fontFamily: "Rubik, sans-serif" }}
          >
            Payment Type:{" "}
            <span style={{ fontWeight: "bold" }}>
              {pendingAction?.actionType === "add_monthly_payment"
                ? "Monthly"
                : pendingAction?.actionType === "add_full_payment"
                ? "Full"
                : pendingAction?.actionType === "add_partial_payment"
                ? "Partial"
                : "Not Provided!"}
            </span>
          </Typography>
          <Typography
            sx={{ fontWeight: "normal", fontFamily: "Rubik, sans-serif" }}
          >
            Done By:{" "}
            <span style={{ fontWeight: "bold" }}>
              {pendingAction?.doneByEmail}
            </span>
          </Typography>
          <Typography
            sx={{ fontWeight: "normal", fontFamily: "Rubik, sans-serif" }}
          >
            Transaction Amount:{" "}
            <span style={{ fontWeight: "bold" }}>
              {pendingAction?.transactionAmount}
            </span>
          </Typography>
          <Typography
            sx={{ fontWeight: "normal", fontFamily: "Rubik, sans-serif" }}
          >
            Transaction Code:{" "}
            <span style={{ fontWeight: "bold" }}>
              {pendingAction?.transactionCode}
            </span>
          </Typography>
          <Typography
            sx={{ fontWeight: "normal", fontFamily: "Rubik, sans-serif" }}
          >
            Transaction Source:{" "}
            <span style={{ fontWeight: "bold" }}>
              {pendingAction?.transactionSource}
            </span>
          </Typography>
          <Typography
            sx={{ fontWeight: "normal", fontFamily: "Rubik, sans-serif" }}
          >
            Done On:{" "}
            <span style={{ fontWeight: "bold" }}>
              {moment(pendingAction?.doneOn?.toDate()).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}
            </span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Rubik, sans-serif",
              fontWeight: "500",
              fontSize: ".88rem",
              backgroundColor: "#009966",

              "&:hover": {
                backgroundColor: "rgba(0,153,102,.8)",
              },
            }}
            onClick={handlePayment}
          >
            Confirm Payment
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
