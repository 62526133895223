import {Button, Grid, ListItemIcon, ListItemText, MenuItem, Modal, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, {useEffect, useRef, useState} from "react";
import ValidatedTextField from "../TextField/validatedTextField";
import {
    BusinessKYCStatus as MerchantKYCStatus,
    BusinessTransactionStatus as BusinessVerifiedStates, BusinessVerificationStates
} from "../../utils/businessStatuses";
import {KYCOperations, sessionUser, UsersOperations} from "../../firebase/operations";
import {Link as RouterLink} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from "@mui/material/CircularProgress";

const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

const RegisterBusiness = ({refresh, mode, businessData, businessId}) => {
    console.log(businessData, businessId, mode)
    const formValid = useRef({
        businessName: false,
        businessPhone: false,
        paymentSystem: false,
        paybillNumber: false,
        accountNumber: false,
        tillNumber: false
    });
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")
    const [ formValues, setFormValues ] = useState({
        businessName: businessData?.businessName || "",
        businessPhone: businessData?.phoneNumber || "",
        paymentSystem: businessData?.businessType || "",
        paybillNumber: businessData?.payBillNo || "",
        accountNumber: businessData?.accountNo || "",
        tillNumber: businessData?.tillNo || ""
    });
    const [ loading, setLoading ] = useState(false)
    const [isSales, setIsSales] = useState(false)
    const [currentUser, setCurrentUser] = useState({})

    useEffect(() => {
        getCurrentUser();
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleSubmit = async (e) => {
        e.preventDefault();

        setErrorMessage("")
        if (!formValues.businessName || !formValues.businessPhone || !formValues.paymentSystem) {
            setErrorMessage("Please fill all fields")
            return;
        }

        if (formValues.paymentSystem === "paybill" && (!formValues.paybillNumber || !formValues.accountNumber)) {
            setErrorMessage("Please fill all fields")
            return;
        }

        if (formValues.paymentSystem === "till" && !formValues.tillNumber) {
            setErrorMessage("Please fill all fields")
            return;
        }

        console.log(formValues)

        let payload = {}
        if (mode === 'edit'){
            payload = {
                paymentOption: formValues.paymentSystem,
                businessName: formValues.businessName,
                businessType: formValues.paymentSystem,
                payBillNo: formValues.paybillNumber,
                accountNo:  formValues.accountNumber,
                tillNo: formValues.tillNumber,
            };
        } else {
            const sessionUser = JSON.parse(localStorage.getItem('user'));

            payload = {
                userId: sessionUser.user.uid,
                paymentOption: formValues.paymentSystem,
                phoneNumber: formValues.businessPhone,
                businessName: formValues.businessName,
                businessType: formValues.paymentSystem,
                onBoardedBy: currentUser.phoneNumber,
                isPinVerified: false,
                // pinHash: await argon2.hash('1234'),
                payBillNo: formValues.paybillNumber,
                accountNo:  formValues.accountNumber,
                tillNo: formValues.tillNumber,
                status: MerchantKYCStatus.LEVEL_0,
                businessVerificationStatus: BusinessVerifiedStates.PENDING,
                createdAt: new Date(),
                updatedAt: new Date()
            };
        }

        if (formValues.paymentSystem === "till") {
            delete payload.payBillNo;
            delete payload.accountNo;
        }

        if (formValues.paymentSystem === "paybill") {
            delete payload.tillNo;
        }

        setLoading(true)

        // Verify if business name is valid by sending a request to the server
        const businessNamePayload = {
            till: payload.businessType === "till" ? payload.tillNo : null,
            paybill: payload.businessType === "paybill" ? payload.payBillNo : null,
        }

        const businessNameResponse = await fetch(process.env.REACT_APP_ON_SYNC_HAKIKISHA_URL,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(businessNamePayload),
            })

        const businessNameData = await businessNameResponse.json()
        if (businessNameData.error || !businessNameData.data || !businessNameData.data.hakikisha_name) {
            setLoading(false)
            setErrorMessage("Failed to verify business name. Please try again later.")
            return
        }

        const businessName = businessNameData.data.hakikisha_name
        if (businessName === "Operator does not exist") {
            setLoading(false)
            setErrorMessage("Operator does not exist. Please check the provided till/paybill details and try again.")
            return
        }

        payload.hakikishaStatus = BusinessVerificationStates.VERIFIED
        payload.hakikishaName = businessName;

        const response = mode === 'edit'
            ? await KYCOperations.updateBusiness(payload, businessId)
            : await KYCOperations.onBoardBusiness(payload);

        setLoading(false)

        if(response.error) {
            setErrorMessage(response.error)
        } else {
            handleClose();
            refresh();
        }
    }

    const getCurrentUser = async () => {
        const user = await UsersOperations.currentUser();
        const { isSales } = user;
        setIsSales(isSales);
        setCurrentUser(user)
    }

    return (
        <>
        { mode === 'edit' ?
            (
                <MenuItem
                    component={RouterLink}
                    to="#"
                    sx={{ color: "text.secondary" }}
                    onClick={handleOpen}
                >
                    <ListItemIcon>
                        <EditIcon width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText
                        primary="Update Details"
                        primaryTypographyProps={{ variant: "body2" }}
                    />
                </MenuItem>
            ) : (
                <>
                    { isSales && (
                        <Button
                            variant="contained"
                            onClick={handleOpen}
                            sx={{
                                backgroundColor: "#009966",
                                color: "white",
                                marginLeft: "10px",
                                "&:hover": {
                                    backgroundColor: "#009966",
                                },
                            }}
                        >
                            Register Business
                        </Button>
                    )}
                </>
            )}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{pb: 1}}>
                        { mode === 'edit' ? 'Update Business Details' : 'Register Business'}
                    </Typography>
                    <form onSubmit={handleSubmit} onChange={() => setErrorMessage("")}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <ValidatedTextField
                                    label="Business Name"
                                    validator={value => value ? "" : "Business Name is required"}
                                    onTouch={isValid => formValid.current.businessName = isValid}
                                    onChange={(value) => setFormValues({...formValues, businessName: value})}
                                    defaultValue={businessData?.businessName}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ValidatedTextField
                                    label="Business Phone"
                                    validator={value => {
                                        if (!value) return "Business Phone is required";
                                        if (!value.startsWith("254")) return "Business Phone must start with 254";
                                        return "";
                                    }}
                                    onTouch={isValid => formValid.current.businessPhone = isValid}
                                    onChange={(value) => setFormValues({...formValues, businessPhone: value})}
                                    defaultValue={businessData?.phoneNumber}
                                    disabled={mode === 'edit'}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ValidatedTextField
                                    label="Payment System"
                                    validator={value => value ? "" : "Payment System is required"}
                                    onTouch={isValid => formValid.current.paymentSystem = isValid}
                                    onChange={(value) => setFormValues({...formValues, paymentSystem: value})}
                                    defaultValue={businessData?.businessType}
                                    inputType="select"
                                >
                                    <MenuItem value="paybill">Pay Bill</MenuItem>
                                    <MenuItem value="till">Buy Goods</MenuItem>
                                </ValidatedTextField>
                            </Grid>
                            { formValues.paymentSystem === "paybill" && (
                                <>
                                    <Grid item xs={12} md={6}>
                                        <ValidatedTextField
                                            label="Paybill Number"
                                            validator={value => value ? "" : "Paybill Number is required"}
                                            onTouch={isValid => formValid.current.paybillNumber = isValid}
                                            onChange={(value) => setFormValues({...formValues, paybillNumber: value})}
                                            type="number"
                                            defaultValue={businessData?.payBillNo}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <ValidatedTextField
                                            label="Account Number"
                                            validator={value => value ? "" : "Account Number is required"}
                                            onTouch={isValid => formValid.current.accountNumber = isValid}
                                            onChange={(value) => setFormValues({...formValues, accountNumber: value})}
                                            defaultValue={businessData?.accountNo}
                                        />
                                    </Grid>
                                </>
                            )}

                            { formValues.paymentSystem === "till" && (
                                <Grid item xs={12} md={6}>
                                    <ValidatedTextField
                                        label="Till Number"
                                        validator={value => value ? "" : "Till Number is required"}
                                        onTouch={isValid => formValid.current.tillNumber = isValid}
                                        onChange={(value) => setFormValues({...formValues, tillNumber: value})}
                                        type="number"
                                        defaultValue={businessData?.tillNo}
                                    />
                                </Grid>
                            )}
                        </Grid>

                        {errorMessage && (
                            <Typography
                                color="error"
                                sx={{marginTop: "10px"}}
                                variant="subtitle2"
                                component="div">
                                {errorMessage}
                            </Typography>
                        )}


                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: "20px",
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                sx={{
                                    backgroundColor: "#009966",
                                    color: "white",
                                    marginTop: "10px",
                                    "&:hover": {
                                        backgroundColor: "#009966",
                                    },
                                    padding: "10px 20px",
                                }}
                            >
                                { mode === 'edit' ? 'Update Details' : 'Register Business' }

                                {loading && (
                                    <Box
                                        component="span"
                                        sx={{
                                            display: "inline-block",
                                            position: "relative",
                                            top: "4px",
                                            left: "10px",
                                            right: "10px",
                                        }}
                                    >
                                        <CircularProgress size={20} color="inherit" />
                                    </Box>
                                )}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </>
    )
}

export default RegisterBusiness;