import React, { useState, forwardRef, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { ref, getDownloadURL } from "firebase/storage";
import { Link as RouterLink, Outlet, useNavigate } from "react-router-dom";
import ReactPanZoom from "react-image-pan-zoom-rotate";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Snackbar,
  Box,
  Divider,
  Typography,
  Modal,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { UsersOperations } from "../../../firebase/operations";
import CircularStatic from "../../shared/Loader/loader";
import { auth, storage } from "../../../firebase/firebase";
import NoData from "../../shared/NoData/noData";
import Uploader from "../../shared/Uploader/uploader";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import { serverTimestamp } from "firebase/firestore";

// modal styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  padding: "2rem",
};

export default function UpdateUserOverSixtyFive({ userId, userData }) {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [front, setFront] = useState(null);
  const [back, setBack] = useState(null);
  const [selfie, setSelfie] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);

  const userIdFrontRef = ref(storage, `idImages/${userId}/front.png`);
  const userIdBackRef = ref(storage, `idImages/${userId}/back.png`);
  const userIdSelfieRef = ref(storage, `idImages/${userId}/face.png`);

  // get front-side id image
  const getFrontIdImg = () => {
    getDownloadURL(userIdFrontRef)
      .then((url) => {
        setFront(url);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error while Downloading img: ", err);
        setIsLoading(false);
      });
  };

  // get back-side id image
  const getBackIdImg = () => {
    getDownloadURL(userIdBackRef)
      .then((url) => {
        setBack(url);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error while Downloading img: ", err);
        setIsLoading(false);
      });
  };

  // get user face image
  const getSelfieImg = () => {
    getDownloadURL(userIdSelfieRef)
      .then((url) => {
        setSelfie(url);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error while Downloading img: ", err);
        setIsLoading(false);
      });
  };

  // check if email exists in authentication build in firebase
  const checkEmail = async (email) => {
    if (!email) return false;

    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      console.log("SIGN IN METHOD: ", signInMethods.length);
      return signInMethods.length > 0;
    } catch (error) {
      console.log("Error while checking if email exists: ", error);
      throw error;
    }
  };

  useEffect(() => {
    getFrontIdImg();
    getBackIdImg();
    getSelfieImg();
  }, []);

  const navigate = useNavigate();

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/home/clients");
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };
  

  const UpdateUserSchema = Yup.object().shape({
    idNumber: Yup.string(),
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string().optional(),
    phoneNumber: Yup.string(),
    isQualified: Yup.string(),
    isSales: Yup.string(),
    fraudulent: Yup.string(),
    iprsAgeManuallyVerified: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      idNumber: userData?.idNumber,
      firstName: userData?.firstName ? userData?.firstName : "",
      lastName: userData?.lastName ? userData?.lastName : "",
      email: userData?.email ? userData?.email : "",
      phoneNumber: userData?.phoneNumber ? userData?.phoneNumber : "",
      isQualified: userData?.isQualified ? userData?.isQualified : false,
      isSales: userData?.isSales ? userData?.isSales : false,
      fraudulent: userData?.fraudulent ? userData?.fraudulent : false,
      iprsAgeManuallyVerified: userData?.iprsAgeManuallyVerified ? userData?.iprsAgeManuallyVerified: false,
    },
    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log("USER VALUES: ", values);
      const { email } = values;
      const emailExists = await checkEmail(
        email !== userData?.email ? email : ""
      );

      if (!emailExists) {
        values["lastUpdated"] = serverTimestamp();
        const response = await UsersOperations.updateUserOverAged(userId, values);
        if (response.isUpdated) {
          setIsUpdated(true);
          setOpenSnackbar(true);
          resetForm();
          handleClose();
        } else {
          setErrorMsg(response.err);
          setOpenSnackbar(true);
        }
      } else {
        setOpenModal(true);
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, values } = formik;

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        elevation={4}
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{
              fontFamily: "Rubik, sans-serif",
              fontWeight: "500",
              fontSize: "1rem",
            }}
          >
            Email Checker
          </Typography>
          <Box
            sx={{
              border: "2px solid red",
              mt: "0.8rem",
              borderRadius: "7px",
              backgroundColor: "rgba(255,0,0,.2)",
            }}
          >
            <Typography
              id="modal-modal-description"
              sx={{ fontFamily: "Rubik, sans-serif", m: 2, color: "red" }}
            >
              The <strong>email</strong> input <strong>already exists</strong>.
              Update user with a new email.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              onClick={handleModalClose}
              sx={{
                fontFamily: "Rubik, sans-serif",
                fontWeight: "500",
                fontSize: ".88rem",
                color: "#009966",
                border: "2px solid #009966",
                mt: "1rem",
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isUpdated ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isUpdated
            ? "User Updated successfully!!!"
            : "Cannot Update User: " + errorMsg}
        </Alert>
      </Snackbar>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Update User"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        onBackdropClick="false"
      >
        <DialogTitle
          sx={{ fontFamily: "Rubik, sans-serif", fontWeight: "bold" }}
        >
          Update {userData?.firstName}'s Details
        </DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  sx={{ width: "45%" }}
                  autoComplete="firstName"
                  type="text"
                  margin="normal"
                  label="First Name"
                  {...getFieldProps("firstName")}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
                <TextField
                  sx={{ width: "45%" }}
                  autoComplete="lastName"
                  type="text"
                  margin="normal"
                  label="Last Name"
                  {...getFieldProps("lastName")}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Box>
              <TextField
                fullWidth
                autoComplete="email"
                type="text"
                margin="normal"
                label="Email"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  sx={{ width: "45%" }}
                  autoComplete="idNumber"
                  type="text"
                  margin="normal"
                  label="ID Number"
                  {...getFieldProps("idNumber")}
                  error={Boolean(touched.idNumber && errors.idNumber)}
                  helperText={touched.idNumber && errors.idNumber}
                />
                <TextField
                  sx={{ width: "45%" }}
                  autoComplete="phoneNumber"
                  type="text"
                  margin="normal"
                  label="Phone Number"
                  {...getFieldProps("phoneNumber")}
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <FormControl
                  sx={{ marginTop: "1rem", width: "30%" }}
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Is Loan Qualified?
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    {...getFieldProps("chargeGroupId")}
                    name="isQualified"
                    defaultValue={
                      userData?.isQualified ? userData?.isQualified : false
                    }
                    label="Is Qualified?"
                    error={Boolean(touched.isQualified && errors.isQualified)}
                    helperText={touched.isQualified && errors.isQualified}
                  >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  sx={{ marginTop: "1rem", width: "30%" }}
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Is Fraudulent?
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    {...getFieldProps("chargeGroupId")}
                    name="fraudulent"
                    defaultValue={
                      userData?.fraudulent ? userData?.fraudulent : false
                    }
                    label="Is Fraudulent?"
                    error={Boolean(touched.fraudulent && errors.fraudulent)}
                    helperText={touched.fraudulent && errors.fraudulent}
                  >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  sx={{ marginTop: "1rem", width: "30%" }}
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Is Sales?
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    {...getFieldProps("chargeGroupId")}
                    name="isSales"
                    defaultValue={userData?.isSales ? userData?.isSales : false}
                    label="Is Sales?"
                    error={Boolean(touched.isSales && errors.isSales)}
                    helperText={touched.isSales && errors.isSales}
                  >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  sx={{ marginTop: "1rem", width: "30%" }}
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Is Age Manually verified ?
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    {...getFieldProps("chargeGroupId")}
                    name="isSales"
                    defaultValue={userData?.iprsAgeManuallyVerified ? userData?.iprsAgeManuallyVerified : false}
                    label="Is Sales?"
                    error={Boolean(touched.iprsAgeManuallyVerified && errors.iprsAgeManuallyVerified)}
                    helperText={touched.iprsAgeManuallyVerified && errors.iprsAgeManuallyVerified}
                  >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Divider
                sx={{
                  borderBottomWidth: 3,
                  margin: "1.5rem 0",
                  backgroundColor: "333333",
                }}
              />
              <Box
                sx={{
                  marginBottom: "1rem",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "30%",
                  }}
                >
                  {isLoading ? (
                    <CircularStatic />
                  ) : (
                    <Box sx={{ position: "relative", overflow: "hidden" }}>
                      {userData?.faceImagePath ? (
                        <ReactPanZoom
                          image={userData?.faceImagePath}
                          alt="face pic"
                        />
                      ) : selfie ? (
                        <ReactPanZoom image={selfie} alt="face pic" />
                      ) : (
                        <NoData text="No Face image available" />
                      )}
                    </Box>
                  )}
                  <Typography
                    sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                  >
                    Face
                  </Typography>
                  <Uploader
                    userId={userId}
                    type="face"
                    imgCategory="identification"
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "30%",
                  }}
                >
                  {isLoading ? (
                    <CircularStatic />
                  ) : (
                    <Box sx={{ position: "relative", overflow: "hidden" }}>
                      {userData?.idFrontImagePath ? (
                        <ReactPanZoom
                          image={userData?.idFrontImagePath}
                          alt="face pic"
                        />
                      ) : front ? (
                        <ReactPanZoom image={front} alt="front id pic" />
                      ) : (
                        <NoData text="No Front ID image available" />
                      )}
                    </Box>
                  )}
                  <Typography
                    sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                  >
                    Front ID Pic
                  </Typography>
                  <Uploader
                    userId={userId}
                    type="frontIdImage"
                    imgCategory="identification"
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "30%",
                  }}
                >
                  {isLoading ? (
                    <CircularStatic />
                  ) : (
                    <Box sx={{ position: "relative", overflow: "hidden" }}>
                      {userData?.idBackImagePath ? (
                        <ReactPanZoom
                          image={userData?.idBackImagePath}
                          alt="face pic"
                        />
                      ) : back ? (
                        <ReactPanZoom image={back} alt="back id pic" />
                      ) : (
                        <NoData text="No Back ID image available" />
                      )}
                    </Box>
                  )}
                  <Typography
                    sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                  >
                    Back ID Pic
                  </Typography>
                  <Uploader
                    userId={userId}
                    type="backIdImage"
                    imgCategory="identification"
                  />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  color: "red",
                  border: "2px solid red",
                  borderRadius: "10px",
                  marginRight: "2rem",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  backgroundColor: "#009966",
                  color: "white",

                  "&:hover": {
                    backgroundColor: "rgba(0,153,102,.8)",
                  },
                }}
                type="submit"
              >
                Update
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
      <Outlet />
    </div>
  );
}
