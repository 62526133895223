import React, { useState, forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import { FortuneLoansOperations } from "../../../firebase/operations";

export default function ManuallyDisburseFortuneLoan({ loanId, loanData }) {
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [disbursed, setDisbursed] = useState(false);

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDisbursement = async () => {
    const response = await FortuneLoansOperations.manuallyDisburseLoan(loanId);

    if (response.isDisbursed) {
      setDisbursed(true);
      handleClose();
      setOpenSnackbar(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      setErrorMsg(response.error);
      setOpenSnackbar(true);
    }
  };

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={disbursed ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {disbursed
            ? "Loan disbursed successfully!!!"
            : "Cannot disburse loan: " + errorMsg}
        </Alert>
      </Snackbar>
      <MenuItem
        onClick={handleClickOpen}
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
      >
        <ListItemIcon>
          <SendToMobileIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Manually Disburse"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <DialogTitle
          sx={{
            fontFamily: "Rubik, sans-serif",
            fontWeight: "bold",
          }}
        >
          Manually Disburse Loan
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>
            Disburse the loan disbursement for KSh.{" "}
            <span style={{ fontWeight: "bold" }}>
              {loanData?.amountRequested}
            </span>{" "}
            requested on{" "}
            <span style={{ fontWeight: "bold" }}>
              {typeof loanData?.dateRequested === "string"
                ? moment(loanData?.dateRequested, "DD-MM-YYYY").format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )
                : moment(loanData?.dateRequested.toDate()).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
            </span>{" "}
            by{" "}
            <span style={{ fontWeight: "bold" }}>
              {`${loanData?.firstName} ${loanData?.lastName}`}
            </span>
            ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: "red" }} onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Rubik, sans-serif",
              fontWeight: "500",
              fontSize: ".88rem",
              backgroundColor: "#009966",

              "&:hover": {
                backgroundColor: "rgba(0,153,102,.8)",
              },
            }}
            onClick={handleDisbursement}
          >
            Disburse Loan
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
