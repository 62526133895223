import React, { useState, forwardRef } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";

import { TicketsOperations } from "../../../firebase/operations";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export default function CreateTemplate({ handleTemplateClose, openTemplate }) {
  const [open, setOpen] = useState(false);
  const [isAdded, setIsAdded] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const CreateTemplateSchema = Yup.object().shape({
    template: Yup.string().required("Message Description is required"),
  });

  const formik = useFormik({
    initialValues: {
      template: "",
    },
    validationSchema: CreateTemplateSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log("IN HERE");
      console.log("Message data: ", values);
      const response = await TicketsOperations.addMessageTemplate(values);
      console.log("RESPONSE: ", response);

      if (response.isAdded) {
        setIsAdded(true);
        setOpenSnackbar(true);
        resetForm();
        handleTemplateClose();
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        setOpenSnackbar(true);
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <div style={{ width: "20%" }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isAdded ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isAdded
            ? "Template Created successfully!!!"
            : "Cannot Create Template!!!"}
        </Alert>
      </Snackbar>
      <Dialog
        PaperProps={{
          style: { width: "90%" },
        }}
        open={openTemplate}
        onClose={handleTemplateClose}
        onBackdropClick
      >
        <DialogTitle
          sx={{ fontFamily: "Rubik, sans-serif", fontWeight: "bold" }}
        >
          Add Template
        </DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="template"
                type="text"
                multiline
                rows={3}
                margin="normal"
                label="Template Message"
                {...getFieldProps("template")}
                error={Boolean(touched.template && errors.template)}
                helperText={touched.template && errors.template}
              />
            </DialogContent>
            <DialogActions>
              <Button
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  color: "red",
                  border: "2px solid red",
                  borderRadius: "10px",
                }}
                onClick={handleTemplateClose}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  backgroundColor: "#009966",
                  color: "white",

                  "&:hover": {
                    backgroundColor: "rgba(0,153,102,.8)",
                  },
                }}
                type="submit"
              >
                Create
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
