import { useRef, useState } from "react";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
// material
import { Menu, IconButton } from "@mui/material";
import ManualProcessCode from "./processCode";

export default function ReferralCodeMoreMenu({ codeId, codeData, rewardData }) {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <ManualProcessCode
          codeId={codeId}
          codeData={codeData}
          rewardData={rewardData}
        />
      </Menu>
    </>
  );
}
