import React, { useEffect, useState, useRef } from "react";
import MaterialReactTable from "material-react-table";
import moment from "moment";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { collection, query, where, getDocs } from "firebase/firestore";

import {
  Typography,
  Table,
  TableBody,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import { EnhancedTableToolbar } from "./sendToManyToolbar";

import { db } from "../../../firebase/firebase";
import { Pagination } from "../../../firebase/operations";
import CircularStatic from "../../shared/Loader/loader";
import { tableStyles } from "../../styles/tableStyles";
import SendToManyMoreMenu from "./sendToManyMoreMenu";
import { StatusStyles } from "../../styles/statusStyles";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: "true",
});

const getMuiTheme = () => createTheme(tableStyles);

export default function SendMoneyToMany() {
  const [data, setData] = useState([]); // table rows
  const [lastKey, setLastKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");

  // extract beneficiaries
  const extractData = (data) => {
    const extractedData = [];

    for (const row of data) {
      const beneficiaries = [];
      Object.keys(row).forEach((key) => {
        if (key.startsWith("2547") || key.startsWith("2541")) {
          beneficiaries.push({
            beneficiaryNo: key,
            amount: row[key].amount,
            status: row[key].status,
            dateCreated: row[key].dateCreated,
          });
        }
      });

      extractedData.push({
        ...row,
        beneficiariesArr: beneficiaries,
      });
    }

    return extractedData;
  };

  const searchRef = useRef(null);

  const handleSearch = async (input) => {
    const senderNoMatches = [];
    const namesMatched = [];
    const codesMatched = [];

    let numCheck = false;

    const numRegex = /^\d+$/;

    const inputCheck = (string) => {
      if (string.match(numRegex)) {
        numCheck = true;
        return numCheck;
      }

      return numCheck;
    };

    inputCheck(input);

    console.log("CHECK: ", numCheck);

    // check for numbers
    if (numCheck) {
      // get sender number match query
      const searchSenderNoQuery = query(
        collection(db, "send_money_to_many_transactions"),
        where("phoneNumber", "==", input)
      );

      const senderNoSnapshot = await getDocs(searchSenderNoQuery);

      senderNoSnapshot.forEach((doc) =>
        senderNoMatches.push({ ...doc.data(), docId: doc.id })
      );
      setData(senderNoMatches);
    } else {
      const strCheck = /^[a-zA-Z0-9]+$/;

      if (strCheck.test(input) !== true) {
        //check for names
        const searchNameQuery = query(
          collection(db, "send_money_to_many_transactions"),
          where("displayName", "==", input)
        );

        const nameSnapshot = await getDocs(searchNameQuery);
        nameSnapshot.forEach((doc) =>
          namesMatched.push({ ...doc.data(), docId: doc.id })
        );

        setData(namesMatched);
      } else {
        const searchMpesaCodeQuery = query(
          collection(db, "mpesa_paybill_stk_session"),
          where("MpesaReceiptNumber", "==", input)
        );

        const codeSnapshot = await getDocs(searchMpesaCodeQuery);
        codeSnapshot.forEach((doc) =>
          codesMatched.push({ ...doc.data(), docId: doc.id })
        );
      }
    }
  };

  const columns = [
    {
      accessorKey: "displayName",
      header: "Name",
      enableGrouping: false,
    },
    {
      accessorKey: "phoneNumber",
      header: "Sender Number",
    },
    {
      accessorKey: "amount",
      header: "Amount Sent",
    },
    {
      accessorKey: "dateCreated",
      header: "Date Created",
      filterFn: "lessThanOrEqualTo",
      sortingFn: "datetime",
      accessorFn: (row) => row?.dateCreated?.toDate(), // Convert Firebase timestamp to Date object
      Cell: ({ cell }) => {
        const date = cell?.getValue();
        return (
          <Typography>
            {moment(date).format("MMMM Do YYYY, h:mm:ss a")}
          </Typography>
        ); // Format the date using moment.js
      },
      Filter: ({ column }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            onChange={(newValue) => {
              column.setFilterValue(newValue);
            }}
            value={column.getFilterValue()}
            renderInput={(params) => (
              <TextField variant="standard" {...params} />
            )}
          />
        </LocalizationProvider>
      ),
    },
    {
      accessorKey: "status",
      header: "Status",
      Cell: ({ cell }) => {
        const status = cell?.getValue();
        return StatusStyles(status);
      },
    },
    {
      accessorKey: "note",
      header: "Note",
    },
  ];

  // pull data from firebase and set table rows
  useEffect(() => {
    Pagination.fetchFirstBatch("send_money_to_many_transactions", "dateCreated")
      .then((res) => {
        setData(res.fetchedData);
        setLastKey(res.lastKey);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
  }, []);

  const extractedData = extractData(data);
  console.log("TABLE DATA >>> ", extractedData);

  return (
    <div style={{ backgroundColor: "#F7F7F7" }}>
      <EnhancedTableToolbar
        setSearchInput={setSearchInput}
        searchRef={searchRef}
        handleSearch={() => handleSearch(searchInput)}
        tableTitle="Send To Many Transactions"
      />
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme()}>
          {isLoading ? (
            <CircularStatic />
          ) : (
            <MaterialReactTable
              data={extractedData}
              columns={columns}
              enableColumnResizing
              enableColumnFilterModes
              enableGrouping
              renderDetailPanel={({ row }) => (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead sx={{ backgroundColor: "rgba(0,153,102,0.1)" }}>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Beneficiary
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }} align="right">
                          Amount Sent
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }} align="right">
                          Date Created
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }} align="right">
                          Status
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.original.beneficiariesArr.map((beneficiary) => {
                        return (
                          <TableRow
                            key={beneficiary.beneficiaryNo}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {beneficiary.beneficiaryNo}
                            </TableCell>
                            <TableCell align="right">
                              {beneficiary.amount}
                            </TableCell>
                            <TableCell align="right">
                              {moment(beneficiary.dateCreated?.toDate()).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </TableCell>
                            <TableCell
                              sx={{ display: "flex", justifyContent: "end" }}
                              align="right"
                            >
                              {StatusStyles(beneficiary.status)}
                            </TableCell>
                            <TableCell align="right">
                              <SendToManyMoreMenu
                                transactionId={row.original.docId}
                                transactionData={row.original}
                                rowData={beneficiary}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              initialState={{
                density: "compact",
                expanded: false, //expand all groups by default
                grouping: ["phoneNumber"], //an array of columns to group by by default (can be multiple)
                pagination: { pageIndex: 0, pageSize: 20 },
                sorting: [{ id: "dateCreated", desc: true }], //sort by dateCreated by default
              }}
              muiToolbarAlertBannerChipProps={{ color: "primary" }}
              muiTableContainerProps={{ sx: { maxHeight: 700 } }}
            />
          )}
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
}
