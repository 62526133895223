import styled from "styled-components";
import Market from "../../assets/images/market.png";

// login page
export const LoginPage = styled.div({
  display: "flex",
  width: "100%",
  fontFamily: "Rubik, sans-serif",
  height: "auto",

  "@media(max-width: 768px)": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundImage: `url(${Market})`,
    backgroundSize: "cover",
    backgroundPositionX: "center",
  },
});

// LEFT login div
export const LeftDiv = styled.div({
  width: "50%",
  height: "100vh",
  backgroundColor: "#FFE7DF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundImage: `url(${Market})`,
  backgroundSize: "cover",
  backgroundPosition: "center",

  "@media(max-width: 768px)": {
    display: "none",
  },
});

// logo
export const LogoStyles = styled.div({
  width: "100%",
});

// RIGHT login div
export const RightDiv = styled.div({
  width: "50%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  "@media(max-width: 768px)": {
    width: "100%",
  },
});

// Right content container
export const RightContent = styled.div({
  margin: "6.5rem 9rem 5rem 9rem",
  width: "60%",

  "@media(max-width: 426px)": {
    width: "90%",
    margin: "9rem 5rem",
  },
});

// heading
export const Heading = styled.p({
  fontSize: "1.3rem",
  fontWeight: "bold",
  maxWidth: "50%",
  textAlign: "left",

  "@media(max-width: 768px)": {
    maxWidth: "90%",
    color: "white",
  },
});

// input containers
export const InputContainer = styled.div({
  display: "flex",
  flexDirection: "column",
});

// input labels
export const InputLabels = styled.p({
  fontWeight: "bold",
  fontSize: ".8rem",
  mb: ".9rem",
  fontFamily: "Rubik, sans-serif",

  "@media(max-width: 768px)": {
    color: "white",
  },
});

// forgot password
export const ForgotPassword = styled.p({
  width: "100%",
  textAlign: "right",
  fontSize: ".8rem",
  color: "#009966",
  marginBottom: "2rem",
  cursor: "pointer",

  "@media(max-width: 768px)": {
    color: "white",
  },
});

// contacts and link
export const ContactsContainer = styled.div({
  width: "80%",
  display: "flex",
  alignItems: "center",
  justifyContent: "end",

  "@media(max-width: 768px)": {
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  "@media(max-width: 426px)": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

// number
export const ContactNumber = styled.p({
  color: "#11284E",
  fontSize: ".9rem",
  fontWeight: "bold",
  paddingRight: "1rem",
  borderRight: "2px solid #11284E",
  margin: 0,

  "@media(max-width: 768px)": {
    color: "white",
    border: "none",
  },

  "@media(max-width: 426px)": {
    fontSize: "1.1rem",
    marginBottom: 0,
  },
});

// email
export const ContactEmail = styled.p({
  color: "#11284E",
  fontSize: ".9rem",
  fontWeight: "bold",
  paddingLeft: "1rem",
  margin: 0,

  "@media(max-width: 768px)": {
    color: "white",
  },

  "@media(max-width: 426px)": {
    fontSize: "1.1rem",
    margin: 0,
  },
});

// download button
export const Download = styled.a({
  "@media(max-width: 426px)": {
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 0,
  },
});
