import React, { useState, useEffect } from "react";
import { httpsCallable } from "firebase/functions";

import { functions } from "../../firebase/firebase";

import { Box, Typography } from "@mui/material";
import CircularStatic from "../shared/Loader/loader";
import NoData from "../shared/NoData/noData";

function UserSummaries({ userId, userData }) {
  const [metrics, setMetrics] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [statusCode, setStatusCode] = useState(null);

  // get current date as end date
  const date = new Date();
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const year = date.getFullYear();

  const endDate = `${year}-${month}-${day} 00:00:00`;

  // get last 6 months date
  const sixMonthsAgo = new Date(
    date.getFullYear(),
    date.getMonth() - 6,
    date.getDate()
  );
  const month_ =
    sixMonthsAgo.getMonth() + 1 < 10
      ? `0${sixMonthsAgo.getMonth() + 1}`
      : sixMonthsAgo.getMonth() + 1;
  const day_ =
    sixMonthsAgo.getDate() < 10
      ? `0${sixMonthsAgo.getDate()}`
      : sixMonthsAgo.getDate();
  const year_ = sixMonthsAgo.getFullYear();

  const startDate = `${year_}-${month_}-${day_} 00:00:00`;

  // transactions and counts
  const sendMoney = [];
  const sendMoneyCounts = [];
  const receivedMoney = [];
  const receivedMoneyCounts = [];
  const paybill = [];
  const paybillCounts = [];
  const till = [];
  const tillCounts = [];
  const withdrawals = [];
  const withdrawalsCounts = [];

  // transaction and count totals
  let sendMoneyTotal = 0;
  let sendMoneyCountTotal = 0;
  let receivedMoneyTotal = 0;
  let receivedMoneyCountTotal = 0;
  let paybillTotal = 0;
  let paybillCountTotal = 0;
  let tillTotal = 0;
  let tillCountTotal = 0;
  let withdrawalsTotal = 0;
  let withdrawalsCountTotal = 0;

  useEffect(() => {
    // fetch user metrics by id
    const fetchUserMetrics = () => {
      const userSummaries = httpsCallable(
        functions,
        "reportsGroup-onGetUserTransactionHistoryAnalysisSummary"
      );
      const summaries = userSummaries({
        // uid: `${userId}`,
        uid: `${userId}`,
        startDate: startDate,
        endDate: endDate,
        portal: true,
      });

      summaries.then((response) => {
        console.log("SUMMARIES: ", response);
        if (response.data.status === 200) {
          setMetrics(response.data.body);
          setIsLoaded(true);
          setStatusCode(response.data.status);
        } else {
          setIsLoaded(true);
          setStatusCode(response.data.status);
        }
      });
    };

    fetchUserMetrics();
  }, []);

  // populating the different transaction arrays
  //send money
  isLoaded &&
    metrics !== null &&
    metrics["individual payments"]?.map((transaction) => {
      sendMoney.push(transaction.allTime.expense);
      sendMoneyCounts.push(transaction.allTime.count);
    });

  //received money
  isLoaded &&
    metrics !== null &&
    metrics["received payments"]?.map((transaction) => {
      receivedMoney.push(transaction.allTime.income);
      receivedMoneyCounts.push(transaction.allTime.count);
    });

  // paybill
  isLoaded &&
    metrics !== null &&
    metrics.paybill?.map((transaction) => {
      paybill.push(transaction.allTime.expense);
      paybillCounts.push(transaction.allTime.count);
    });

  // till
  isLoaded &&
    metrics !== null &&
    metrics.till?.map((transaction) => {
      till.push(transaction.allTime.expense);
      tillCounts.push(transaction.allTime.count);
    });

  // withdrawals
  isLoaded &&
    metrics !== null &&
    metrics.withdrawal?.map((transaction) => {
      withdrawals.push(transaction.allTime.expense);
      withdrawalsCounts.push(transaction.allTime.count);
    });

  // get the sum of these arrays
  const transactionSums = () => {
    sendMoneyTotal = sendMoney.reduce((acc, cur) => acc + cur, 0);
    sendMoneyCountTotal = sendMoneyCounts.reduce((acc, cur) => acc + cur, 0);
    receivedMoneyTotal = receivedMoney.reduce((acc, cur) => acc + cur, 0);
    receivedMoneyCountTotal = receivedMoneyCounts.reduce(
      (acc, cur) => acc + cur,
      0
    );
    paybillTotal = paybill.reduce((acc, cur) => acc + cur, 0);
    paybillCountTotal = paybillCounts.reduce((acc, cur) => acc + cur, 0);
    tillTotal = till.reduce((acc, cur) => acc + cur, 0);
    tillCountTotal = tillCounts.reduce((acc, cur) => acc + cur, 0);
    withdrawalsTotal = withdrawals.reduce((acc, cur) => acc + cur, 0);
    withdrawalsCountTotal = withdrawalsCounts.reduce(
      (acc, cur) => acc + cur,
      0
    );
  };

  transactionSums();

  console.log("SEND MONEY TOTAL: ", sendMoneyTotal);

  return (
    <div>
      <Typography
        sx={{
          fontFamily: "Rubik, sans-serif",
          fontWeight: "bold",
          fontSize: "1.2rem",
        }}
      >{`${userData.firstName} ${userData.lastName}'s past 6 months transaction summaries`}</Typography>
      {isLoaded === false ? (
        <CircularStatic />
      ) : statusCode !== 200 ? (
        <NoData text="No User Summaries Found!" />
      ) : (
        <Box sx={{ mt: "1rem" }}>
          <Box>
            <Typography
              sx={{ fontFamily: "Rubik, sans-serif", fontWeight: "bold" }}
            >
              Airtime:{" "}
              <span
                style={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "normal",
                }}
              >
                A total of
              </span>{" "}
              Ksh.{" "}
              {metrics?.airtime ? metrics?.airtime[0]?.allTime.expense : "NA"}{" "}
              <span
                style={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "normal",
                }}
              >
                spent in
              </span>{" "}
              {metrics?.airtime ? metrics?.airtime[0]?.allTime.count : "NA"}
              <span
                style={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "normal",
                }}
              >
                {" "}
                transactions from{" "}
              </span>
              <span style={{ fontFamily: "Rubik, sans-serif" }}>
                {metrics?.airtime ? metrics?.airtime[0]?.name : "NA"}
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ fontFamily: "Rubik, sans-serif", fontWeight: "bold" }}
            >
              Send Money:{" "}
              <span
                style={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "normal",
                }}
              >
                A total of
              </span>{" "}
              Ksh. {sendMoneyTotal ? sendMoneyTotal : "NA"}{" "}
              <span
                style={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "normal",
                }}
              >
                spent in
              </span>{" "}
              {sendMoneyCountTotal ? sendMoneyCountTotal : "NA"}
              <span
                style={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "normal",
                }}
              >
                {" "}
                transactions{" "}
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ fontFamily: "Rubik, sans-serif", fontWeight: "bold" }}
            >
              Received Money:{" "}
              <span
                style={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "normal",
                }}
              >
                A total of
              </span>{" "}
              Ksh. {receivedMoneyTotal ? receivedMoneyTotal : "NA"}{" "}
              <span
                style={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "normal",
                }}
              >
                received in
              </span>{" "}
              {receivedMoneyCountTotal ? receivedMoneyCountTotal : "NA"}
              <span
                style={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "normal",
                }}
              >
                {" "}
                transactions{" "}
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ fontFamily: "Rubik, sans-serif", fontWeight: "bold" }}
            >
              Till:{" "}
              <span
                style={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "normal",
                }}
              >
                A total of
              </span>{" "}
              Ksh. {tillTotal ? tillTotal : "NA"}{" "}
              <span
                style={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "normal",
                }}
              >
                spent in
              </span>{" "}
              {tillCountTotal ? tillCountTotal : "NA"}
              <span
                style={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "normal",
                }}
              >
                {" "}
                transactions{" "}
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ fontFamily: "Rubik, sans-serif", fontWeight: "bold" }}
            >
              Paybill:{" "}
              <span
                style={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "normal",
                }}
              >
                A total of
              </span>{" "}
              Ksh. {paybillTotal ? paybillTotal : "NA"}{" "}
              <span
                style={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "normal",
                }}
              >
                spent in
              </span>{" "}
              {paybillCountTotal ? paybillCountTotal : "NA"}
              <span
                style={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "normal",
                }}
              >
                {" "}
                transactions{" "}
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ fontFamily: "Rubik, sans-serif", fontWeight: "bold" }}
            >
              Withdrawals:{" "}
              <span
                style={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "normal",
                }}
              >
                A total of
              </span>{" "}
              Ksh. {withdrawalsTotal ? withdrawalsTotal : "NA"}{" "}
              <span
                style={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "normal",
                }}
              >
                withdrawn in
              </span>{" "}
              {withdrawalsCountTotal ? withdrawalsCountTotal : "NA"}
              <span
                style={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "normal",
                }}
              >
                {" "}
                transactions{" "}
              </span>
            </Typography>
          </Box>
        </Box>
      )}
    </div>
  );
}

export default UserSummaries;
