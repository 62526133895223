import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import SearchIcon from "@mui/icons-material/Search";
import RegisterBusiness from "./registerBusiness";
import PropTypes from "prop-types";
export const EnhancedTableToolbar = (props) => {
  const { numSelected, viewMode } = props;

  const handleChange = () => {
    props.setSearchInput(props.searchRef.current?.value);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1, md: 0 },
          pb: 2,
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        backgroundColor: "#f2fffb",
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              flex: "1 1 100%",
              fontFamily: "Rubik, sans-serif",
              fontWeight: "700",
              fontSize: "26px",
            }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
              { viewMode === "businesses" ? "Lipa Na Leja" : "Merchant Businesses"}
          </Typography>
          <Box
              sx={{ m: "1px 0 1px 1px", width: "50%", display: "flex", justifyContent: "flex-end" }}
          >
              <TextField
                  placeholder="Search by Phone, Name or Channel No."
                  id="outlined-start-adornment"
                  sx={{ backgroundColor: "white", width: "55%" }}
                  inputRef={props.searchRef}
                  onChange={handleChange}
                  InputProps={{
                      endAdornment: (
                          <InputAdornment position="start">
                              <SearchIcon
                                  onClick={props.handleSearch}
                                  sx={{ cursor: "pointer" }}
                              />
                          </InputAdornment>
                      ),
                  }}
              />
              <RegisterBusiness refresh={props.refresh}/>
          </Box>
        </Box>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number,
    setSearchInput: PropTypes.func.isRequired,
    searchRef: PropTypes.object.isRequired,
    handleSearch: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
}
