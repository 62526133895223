import React, { useEffect, useState, useRef } from "react";
import MaterialReactTable from "material-react-table";
import moment from "moment";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { collection, query, where, getDocs } from "firebase/firestore";

import {
  Typography,
  Table,
  TableBody,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  MenuItem,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { db } from "../../firebase/firebase";
import { Pagination } from "../../firebase/operations";
import CircularStatic from "../shared/Loader/loader";
import { tableStyles } from "../styles/tableStyles";
import { EnhancedTableToolbar } from "./referralCodeToolbar";
import ViewRefCode from "./viewRefCode";
import ReferralCodeMoreMenu from "./referralCodeMoreMenu";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: "true",
});

const getMuiTheme = () => createTheme(tableStyles);

function ReferralCode() {
  const [data, setData] = useState([]); // table rows
  const [lastKey, setLastKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");

  const searchRef = useRef(null);

  const handleSearch = async (input) => {
    const codeMatches = [];

    // get ref code match query
    const searchCodeQuery = query(
      collection(db, "users_referrals"),
      where("refCode", "==", input)
    );

    const codeSnapshot = await getDocs(searchCodeQuery);

    codeSnapshot.forEach((doc) =>
      codeMatches.push({ ...doc.data(), docId: doc.id })
    );

    setData(codeMatches);
  };

  const columns = [
    {
      accessorKey: "refCode",
      header: "Referral Code",
      Cell: ({ cell }) => {
        const refCode = cell?.getValue();
        return (
          <Typography
            sx={{ fontSize: "0.88rem", fontFamily: "Rubik, sans-serif" }}
          >
            {refCode}
          </Typography>
        );
      },
    },
    {
      accessorKey: "referred",
      header: "Referred",
      Cell: ({ cell }) => {
        const referred = cell?.getValue();
        return (
          <Typography
            sx={{ fontSize: "0.88rem", fontFamily: "Rubik, sans-serif" }}
          >
            {referred.firstName} {referred.lastName}
          </Typography>
        );
      },
    },
    {
      accessorKey: "referrer",
      header: "Referrer",
      Cell: ({ cell }) => {
        const referrer = cell?.getValue();
        return (
          <Typography
            sx={{ fontSize: "0.88rem", fontFamily: "Rubik, sans-serif" }}
          >
            {referrer.firstName} {referrer.lastName}
          </Typography>
        );
      },
    },
    {
      accessorKey: "dateCreated",
      header: "Date Created",
      filterFn: "lessThanOrEqualTo",
      sortingFn: "datetime",
      accessorFn: (row) => row?.dateCreated?.toDate(), // Convert Firebase timestamp to Date object
      Cell: ({ cell }) => {
        const date = cell?.getValue();
        return (
          <Typography
            sx={{ fontSize: "0.88rem", fontFamily: "Rubik, sans-serif" }}
          >
            {moment(date).format("MMMM Do YYYY, h:mm:ss a")}
          </Typography>
        ); // Format the date using moment.js
      },
      Filter: ({ column }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            onChange={(newValue) => {
              column.setFilterValue(newValue);
            }}
            value={column.getFilterValue()}
            renderInput={(params) => (
              <TextField variant="standard" {...params} />
            )}
          />
        </LocalizationProvider>
      ),
    },
    {
      accessorKey: "status",
      header: "Referral Status",
      Cell: ({ cell }) => {
        const status = cell?.getValue();
        return (
          <Typography
            sx={{ fontSize: "0.88rem", fontFamily: "Rubik, sans-serif" }}
          >
            {status}
          </Typography>
        );
      },
    },
  ];

  // pull data from firebase and set table rows
  useEffect(() => {
    Pagination.fetchFirstBatch("users_referrals", "dateCreated")
      .then((res) => {
        setData(res.fetchedData);
        setLastKey(res.lastKey);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
  }, []);

  console.log("REFERRAL CODES >>> ", data);

  return (
    <div style={{ backgroundColor: "#F7F7F7" }}>
      <EnhancedTableToolbar
        setSearchInput={setSearchInput}
        searchRef={searchRef}
        handleSearch={() => handleSearch(searchInput)}
        tableTitle="Referral Codes"
      />
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme()}>
          {isLoading ? (
            <CircularStatic />
          ) : (
            <MaterialReactTable
              data={data}
              columns={columns}
              enableColumnResizing
              enableColumnFilterModes
              enableGrouping
              enableRowActions
              positionActionsColumn="last"
              renderRowActionMenuItems={({ row }) => [
                <ViewRefCode codeData={row.original} />,
              ]}
              renderDetailPanel={({ row }) => (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead sx={{ backgroundColor: "rgba(0,153,102,0.1)" }}>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontFamily: "Rubik, sans-serif",
                            fontWeight: "bold",
                          }}
                        >
                          To Phone Number
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "Rubik, sans-serif",
                            fontWeight: "bold",
                          }}
                        >
                          Amount
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "Rubik, sans-serif",
                            fontWeight: "bold",
                          }}
                          align="right"
                        >
                          Event
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "Rubik, sans-serif",
                            fontWeight: "bold",
                          }}
                          align="right"
                        >
                          Is Claimed
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "Rubik, sans-serif",
                            fontWeight: "bold",
                          }}
                          align="right"
                        >
                          Note
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.original.rewards.map((reward, index) => {
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              sx={{
                                fontSize: "0.88rem",
                                fontFamily: "Rubik, sans-serif",
                              }}
                              component="th"
                              scope="row"
                            >
                              {reward.toPhoneNumber}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "0.88rem",
                                fontFamily: "Rubik, sans-serif",
                              }}
                              component="th"
                              scope="row"
                            >
                              {reward.amount}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "0.88rem",
                                fontFamily: "Rubik, sans-serif",
                              }}
                              align="right"
                            >
                              {reward.event}
                            </TableCell>
                            <TableCell align="right">
                              {reward.isClaimed ? (
                                <Typography
                                  sx={{
                                    color: "#009966",
                                    fontWeight: "bold",
                                    fontSize: "0.88rem",
                                    fontFamily: "Rubik, sans-serif",
                                  }}
                                >
                                  True
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{
                                    color: "red",
                                    fontWeight: "bold",
                                    fontSize: "0.88rem",
                                    fontFamily: "Rubik, sans-serif",
                                  }}
                                >
                                  False
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "0.88rem",
                                fontFamily: "Rubik, sans-serif",
                              }}
                              align="right"
                            >
                              {reward.note}
                            </TableCell>
                            <TableCell align="right">
                              <ReferralCodeMoreMenu
                                codeId={row.original.docId}
                                codeData={row.original}
                                rewardData={reward}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              initialState={{
                density: "compact",
                expanded: false, //expand all groups by default
                grouping: ["refCode"], //an array of columns to group by by default (can be multiple)
                pagination: { pageIndex: 0, pageSize: 20 },
                sorting: [{ id: "dateCreated", desc: true }], //sort by dateCreated by default
              }}
              muiToolbarAlertBannerChipProps={{ color: "primary" }}
              muiTableContainerProps={{ sx: { maxHeight: 700 } }}
            />
          )}
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
}

export default ReferralCode;
