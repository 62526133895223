import React, { useState, useEffect } from "react";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import Geocode from "react-geocode";
import { collection, query, where, getDocs } from "firebase/firestore";

import { db, storage } from "../../../firebase/firebase";

import {
  Typography,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Button,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import VisibilityIcon from "@mui/icons-material/Visibility";

import NoData from "../../shared/NoData/noData";
import Uploader from "../../shared/Uploader/uploader";
import BusinessTransactions from "../../Businesses/businessTransactions";

// styles
const boxContainer = {
  display: "flex",
  justifyContent: "space-around",
  width: "100%",
};

const box = {
  display: "flex",
  marginLeft: "1rem",
};

function ViewKYC({ userId, businessData, businessId }) {
  const [open, setOpen] = useState(false);
  const [businessImagePath, setBusinessImagePath] = useState(null);
  const [permitImagePath, setPermitImagePath] = useState(null);
  const [businessImg, setBusinessImg] = useState(null);
  const [permitImg, setPermitImg] = useState(null);
  const [location, setLocation] = useState("");
  const [merchant, setMerchant] = useState(null);

  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEOCODE_KEY);

  const businessRef = ref(storage, `${businessImagePath}`);
  const permitRef = ref(storage, `${permitImagePath}`);
  const listRef = ref(storage, `businessImages/${userId}/${businessId}`);

  useEffect(() => {
    const fetchImagePaths = () => {
      listAll(listRef)
        .then((res) => {
          console.log("ITEM: ", res.items);
          setBusinessImagePath(res?.items[0]._location.path_);
          setPermitImagePath(res?.items[1]._location.path_);
        })
        .catch((err) => {
          console.log("ERROR: ", err);
        });
    };

    // get business image
    const getBusinessImg = () => {
      getDownloadURL(businessRef)
        .then((url) => {
          setBusinessImg(url);
        })
        .catch((err) => {
          console.log("Error while Downloading img: ", err);
        });
    };

    // get permit image
    const getPermitImg = () => {
      getDownloadURL(permitRef)
        .then((url) => {
          setPermitImg(url);
        })
        .catch((err) => {
          console.log("Error while Downloading img: ", err);
        });
    };

    fetchImagePaths();
    getBusinessImg();
    getPermitImg();
  }, []);

  // get user data
  const fetchMerchant = async () => {
    const merchantQuery = query(
      collection(db, "users"),
      where("phoneNumber", "==", businessData?.phoneNumber)
    );

    const merchantSnapshot = await getDocs(merchantQuery);

    merchantSnapshot.forEach((doc) =>
      setMerchant({ ...doc.data(), docId: doc.id })
    );
  };

  const getLocation = async (businessData) => {
    const { location } = businessData;
    await Geocode.fromLatLng(location?.latitude, location?.longitude).then(
      (response) => {
        const address = response.results[0].formatted_address;
        console.log("ADDRESS: " + address);
        setLocation(address);
      },
      (error) => {
        console.log("Error while getting location: ", error);
      }
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
    getLocation(businessData);
    fetchMerchant();
  };

  console.log("LOCATION:", location);

  const handleClose = () => {
    setOpen(false);
  };

  console.log("BUSINESS: ", businessData);

  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <VisibilityIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="View Business"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        onBackdropClick="false"
      >
        <DialogTitle
          sx={{ fontFamily: "Rubik, sans-serif", fontWeight: "bold" }}
        >
          Business Details
        </DialogTitle>
        <DialogContent>
          {merchant ? (
            <Box sx={{ marginBottom: "2rem", fontFamily: "Rubik, sans-serif" }}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  fontFamily: "Rubik, sans-serif",
                }}
              >
                General Details
              </Typography>
              <Box sx={boxContainer}>
                <Box sx={box}>
                  <Typography
                    sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                  >
                    National Id:
                  </Typography>
                  <p
                    className="nationalId"
                    style={{
                      paddingLeft: "1rem",
                      margin: "0",
                      fontFamily: "Rubik, sans-serif",
                    }}
                  >
                    {merchant.idNumber}
                  </p>
                </Box>
                <Box sx={box}>
                  <Typography
                    sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                  >
                    First Name:
                  </Typography>
                  <p
                    className="nationalId"
                    style={{
                      paddingLeft: "1rem",
                      margin: "0",
                      fontFamily: "Rubik, sans-serif",
                    }}
                  >
                    {merchant.firstName}
                  </p>
                </Box>
                <Box sx={box}>
                  <Typography
                    sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                  >
                    Last Name:
                  </Typography>
                  <p
                    className="nationalId"
                    style={{
                      paddingLeft: "1rem",
                      margin: "0",
                      fontFamily: "Rubik, sans-serif",
                    }}
                  >
                    {merchant.lastName}
                  </p>
                </Box>
                <Box sx={box}>
                  <Typography
                    sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                  >
                    Email:
                  </Typography>
                  <p
                    className="nationalId"
                    style={{
                      paddingLeft: "1rem",
                      margin: "0",
                      fontFamily: "Rubik, sans-serif",
                    }}
                  >
                    {merchant.email}
                  </p>
                </Box>
                <Box sx={box}>
                  <Typography
                    sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                  >
                    Phone Number:
                  </Typography>
                  <p
                    className="nationalId"
                    style={{
                      paddingLeft: "1rem",
                      margin: "0",
                      fontFamily: "Rubik, sans-serif",
                    }}
                  >
                    {merchant.phoneNumber}
                  </p>
                </Box>
              </Box>
            </Box>
          ) : (
            ""
          )}
          <Divider
            sx={{
              borderBottomWidth: 3,
              marginBottom: "2rem",
              backgroundColor: "333333",
            }}
          />
          <Box>
            {merchant ? (
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  fontFamily: "Rubik, sans-serif",
                  marginBottom: "1.2rem",
                }}
              >
                Business Details
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  fontFamily: "Rubik, sans-serif",
                  marginBottom: "1.2rem",
                }}
              >
                Business Phone Number:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {businessData.phoneNumber}
                </span>
              </Typography>
            )}
            <Box sx={boxContainer}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "50%",
                }}
              >
                <Box sx={{ position: "relative", overflow: "hidden" }}>
                  {businessImg ? (
                    <ReactPanZoom image={businessImg} alt="business pic" />
                  ) : businessData?.businessImagePath ? (
                    <ReactPanZoom
                      image={businessData?.businessImagePath}
                      alt="business pic"
                    />
                  ) : (
                    <NoData text="No Business image available" />
                  )}
                </Box>

                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Business Image
                </Typography>
                <Uploader
                  userId={userId}
                  businessId={businessId}
                  type="business"
                  imgCategory="business"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "50%",
                }}
              >
                <Box sx={{ position: "relative", overflow: "hidden" }}>
                  {permitImg ? (
                    <ReactPanZoom image={permitImg} alt="permit pic" />
                  ) : businessData?.permitImagePath ? (
                    <ReactPanZoom
                      image={businessData?.permitImagePath}
                      alt="business pic"
                    />
                  ) : (
                    <NoData text="No Permit/Work ID/Receipt image available" />
                  )}
                </Box>

                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Permit/Work ID/Receipt Image
                </Typography>
                <Uploader
                  userId={userId}
                  businessId={businessId}
                  type="permit"
                  imgCategory="business"
                />
              </Box>
            </Box>
            <Divider
              sx={{
                borderBottomWidth: 3,
                marginTop: "2rem",
                backgroundColor: "333333",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Box sx={{ mt: "1.5rem" }}>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Business Name:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {businessData?.businessName
                      ? businessData?.businessName
                      : "No Name Provided"}
                  </span>
                </Typography>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Type of Business:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {businessData?.businessType
                      ? businessData?.businessType
                      : "No Type Provided"}
                  </span>
                </Typography>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Business Industry:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {businessData?.businessIndustry
                      ? businessData?.businessIndustry
                      : "No Industry Provided"}
                  </span>
                </Typography>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Business Channel:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {businessData?.channel
                      ? businessData?.channel
                      : "No Channel Provided"}
                  </span>
                </Typography>
              </Box>
              <Box sx={{ mt: "1.5rem" }}>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Location:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {businessData?.location
                      ? typeof businessData?.location === "string"
                        ? businessData?.location
                        : location
                      : "No Location Provided"}
                  </span>
                </Typography>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Created On:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {businessData?.createdAt
                      ? typeof businessData?.createdAt === "string"
                        ? moment(businessData?.createdAt).format("MM-DD-YYYY")
                        : moment(
                            businessData?.createdAt.toDate().toDateString()
                          ).format("MMMM Do YYYY")
                      : "No Date Provided"}
                  </span>
                </Typography>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  On-Boarded By:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {businessData?.onBoardedBy
                      ? businessData?.onBoardedBy
                      : "Not Provided"}
                  </span>
                </Typography>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Is Pin Verified?{" "}
                  {businessData?.isPinVerified ? (
                    <span style={{ fontWeight: "normal", color: "#009966" }}>
                      Yes
                    </span>
                  ) : (
                    <span style={{ fontWeight: "normal", color: "red" }}>
                      No
                    </span>
                  )}
                </Typography>
              </Box>
              <Box sx={{ mt: "1.5rem" }}>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Is Business Verified?{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {businessData?.businessVerificationStatus
                      ? businessData?.businessVerificationStatus
                      : "No Status Provided"}
                  </span>
                </Typography>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Payment Option:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {businessData?.paymentOption
                      ? businessData?.paymentOption
                      : "No Option Provided"}
                  </span>
                </Typography>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  PayBill:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {businessData?.paybill
                      ? businessData?.paybill
                      : "Not Provided"}
                  </span>
                </Typography>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Till Number:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {businessData?.tillNumber
                      ? businessData?.tillNumber
                      : "Not Provided"}
                  </span>
                </Typography>
              </Box>
              <Box sx={{ mt: "1.5rem" }}>
                <Typography
                  sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
                >
                  Account Number:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {businessData?.accountNo
                      ? businessData?.accountNo
                      : "Not Provided"}
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider
            sx={{
              borderBottomWidth: 3,
              margin: "2rem 0",
              backgroundColor: "333333",
            }}
          />
          <Box>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "bold",
                textDecoration: "underline",
                fontFamily: "Rubik, sans-serif",
                marginBottom: "1.2rem",
              }}
            >
              Business Transactions
            </Typography>
            <BusinessTransactions businessId={businessId} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ViewKYC;
