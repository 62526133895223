import { useRef, useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";

import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
// material
import { Menu, IconButton } from "@mui/material";
import ViewKYC from "./viewKYC";
import VerifyKYC from "./verifyKYC";
import DeclineBusiness from "./declineKYC";
import { db } from "../../../firebase/firebase";
import VerifyBusiness from "./verifyBusiness";
import RegisterBusiness from "../../Businesses/registerBusiness";
import DeleteBusiness from "./deleteBusiness";

export default function KYCMoreMenu({ businessId, businessData, viewMode, refresh }) {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [merchant, setMerchant] = useState(null);

  const sessionUser = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    // fetch merchantData
    const fetchMerchant = async () => {
      const merchantQuery = query(
        collection(db, "users"),
        where("phoneNumber", "==", businessData?.phoneNumber)
      );

      const merchantSnapshot = await getDocs(merchantQuery);

      merchantSnapshot.forEach((doc) =>
        setMerchant({ ...doc.data(), docId: doc.id })
      );
    };

    fetchMerchant();
  }, []);

  return (
    <>
      <IconButton ref={ref} onClick={() => setOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: { width: 220, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
          { viewMode === 'merchant_businesses' && [
              <ViewKYC
                  key="viewKYC"
                  userId={merchant?.docId}
                  userData={merchant}
                  businessId={businessId}
                  businessData={businessData}
              />,
              businessData.onBoardedBy !== sessionUser.user.uid && (
                  <VerifyKYC key="verifyKYC" businessData={businessData} businessId={businessId} />
              )
          ]}
          {viewMode === 'businesses' && businessData.hakikishaStatus === "verified" &&  businessData.businessVerificationStatus !== "verified" && [
              <VerifyBusiness key="verifyBusiness" businessData={businessData} businessId={businessId} refresh={refresh} />,
          ]}

          {viewMode === 'businesses' && [
              <RegisterBusiness key="registerBusiness" mode={'edit'} businessData={businessData} businessId={businessId} refresh={refresh} />,
          ]}

        {businessData.onBoardedBy !== sessionUser.user.uid && businessData.businessVerificationStatus !== 'declined' ? (
          <DeclineBusiness
            businessData={businessData}
            businessId={businessId}
          />
        ) : (
          ""
        )}
          <DeleteBusiness key="deleteBusiness" businessData={businessData} businessId={businessId} refresh={refresh} />
      </Menu>
    </>
  );
}
