import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { collection, query, where, getDocs } from "firebase/firestore";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField, Box, Button, Stack, Typography } from "@mui/material";

import { Pagination } from "../../firebase/operations";

import { EnhancedTableToolbar } from "./usersToolbar";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import CircularStatic from "../shared/Loader/loader";

import { tableStyles } from "../styles/tableStyles";
import UsersMoreMenu from "./usersMoreMenu";

import { db } from "../../firebase/firebase";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: "true",
});

// table stylings
const getMuiTheme = () => createTheme(tableStyles);

export default function Clients() {
  const [rows, setRows] = useState([]); // table rows
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lastKey, setLastKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const [registerDateFrom, setRegisterDateFrom] = useState(null);
  const [registerDateTo, setRegisterDateTo] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const searchRef = useRef(null);

  const handleSearch = async (input) => {
    const fNameMatches = [];
    const lNameMatches = [];
    const phoneNumMatches = [];
    let namesMatched = [];

    let numCheck = false;

    const numRegex = /^\d+$/;

    const inputCheck = (string) => {
      if (string.match(numRegex)) {
        numCheck = true;
        return numCheck;
      }

      return numCheck;
    };

    inputCheck(input);

    console.log("CHECK: ", numCheck);

    // check for names
    if (numCheck === false) {
      // get first name match query
      const searchFNameQuery = query(
        collection(db, "users"),
        where("firstName", "==", input)
      );

      // get last name match query
      const searchLNameQuery = query(
        collection(db, "users"),
        where("lastName", "==", input)
      );

      const fNameSnapshot = await getDocs(searchFNameQuery);
      const lNameSnapshot = await getDocs(searchLNameQuery);

      fNameSnapshot.forEach((doc) =>
        fNameMatches.push({ ...doc.data(), docId: doc.id })
      );
      lNameSnapshot.forEach((doc) =>
        lNameMatches.push({ ...doc.data(), docId: doc.id })
      );
      namesMatched = fNameMatches.concat(lNameMatches);

      setRows(namesMatched);
    } else {
      //check for phone numbers
      const searchPhoneNumberQuery = query(
        collection(db, "users"),
        where("phoneNumber", "==", input)
      );

      const phoneNumberSnapshot = await getDocs(searchPhoneNumberQuery);
      phoneNumberSnapshot.forEach((doc) =>
        phoneNumMatches.push({ ...doc.data(), docId: doc.id })
      );

      setRows(phoneNumMatches);
    }
  };

  const filterList = {};

  // handle filter
  const handleFilterSubmit = (applyFilters) => {
    const filters = applyFilters();
    console.log("Filters: ", filters);
    filters.map((item) => {
      const key = columns[`${filters.indexOf(item)}`].name;
      let newFilter;
      if (key === "dateRegistered") {
        newFilter = {
          registerDateFrom: moment(item[0]).format("MM-DD-YYYY"),
          registerDateTo: moment(item[1]).format("MM-DD-YYYY"),
        };
      } else {
        newFilter = { [key]: moment(item[0]).format("MM-DD-YYYY") };
      }
      Object.assign(filterList, newFilter);
    });
    console.log("FILTER LIST: ", filterList);
    setIsFiltered(true);
  };

  const columns = [
    {
      name: "idNumber",
      label: "ID Number",
      options: {
        filter: false,
      },
    },
    {
      name: "",
      label: "Name",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Typography
              sx={{ fontSize: "0.88rem", fontFamily: "Rubik, sans-serif" }}
            >{`${rows[dataIndex].firstName} ${rows[dataIndex].lastName}`}</Typography>
          );
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        filter: false,
      },
    },
    {
      name: "dateRegistered",
      label: "Date Registered",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              console.log(
                `From: ${moment(v[0]).format("MM-DD-YYYY")},  To: ${moment(
                  v[1]
                ).format("MM-DD-YYYY")}`
              );
              return [
                `Registered From: ${moment(v[0]).format("MM-DD-YYYY")}`,
                `Registered To: ${moment(v[1]).format("MM-DD-YYYY")}`,
              ];
            }
            if (v[0]) {
              return `Registered From: ${moment(v[0]).format("MM-DD-YYYY")}`;
            }
            if (v[1]) {
              return `Registered To: ${moment(v[1]).format("MM-DD-YYYY")}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            console.log(
              "customFilterListOnDelete: ",
              filterList,
              filterPos,
              index
            );

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(dateRegistered, filters) {
            const formattedDate =
              typeof dateRegistered === "string"
                ? moment(dateRegistered, "DD-MM-YYYY").format("MM-DD-YYYY")
                : moment(dateRegistered?.toDate().toDateString()).format(
                    "MM-DD-YYYY"
                  );

            // console.log("Formatted Date: ", formattedDate);

            if (filters[0] && filters[1]) {
              let formattedTo = moment(filters[1]).format("MM-DD-YYYY");
              let formattedFrom = moment(filters[0]).format("MM-DD-YYYY");

              return (
                moment(formattedDate, "MM-DD-YYYY").isBetween(
                  formattedFrom,
                  formattedTo
                ) === false
              );
            }
            if (filters[1]) {
              let formattedFrom = moment(filters[1]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrAfter(formattedFrom);
            }
            if (filters[0]) {
              let formattedTo = moment(filters[0]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrBefore(formattedTo);
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Registered From:"
                  value={registerDateFrom || filterList[index][0]}
                  onChange={(newValue) => {
                    setRegisterDateFrom(newValue);
                    filterList[index][0] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Registered To:"
                  value={registerDateTo || filterList[index][1]}
                  onChange={(newValue) => {
                    setRegisterDateTo(newValue);
                    filterList[index][1] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Stack>
          ),
        },
        customBodyRenderLite: (dataIndex) => {
          return typeof rows[dataIndex].dateRegistered === "string"
            ? moment(rows[dataIndex].dateRegistered, "DD-MM-YYYY").format(
                "MMMM Do YYYY, h:mm:ss a"
              )
            : moment(rows[dataIndex].dateRegistered?.toDate()).format(
                "MMMM Do YYYY, h:mm:ss a"
              );
        },
      },
    },
    {
      name: "loanLimit",
      label: "Loan Limit",
      options: {
        filter: false,

        customBodyRenderLite: (dataIndex) =>
          `KES ${
            rows[dataIndex].loanLimit !== undefined
              ? rows[dataIndex].loanLimit
              : ""
          }`,
      },
    },
    {
      name: "isSuspended",
      label: "User Suspended?",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) =>
          rows[dataIndex].isSuspended ? (
            <CheckIcon
              sx={{
                fontSize: "1.2rem",
                fontWeight: "700",
                color: "#009966",
              }}
            />
          ) : (
            <ClearIcon
              sx={{
                fontSize: "1.2rem",
                fontWeight: "700",
                color: "red",
              }}
            />
          ),
      },
    },
    {
      name: "fortuneAccountCreated",
      label: "Fortune Account ?",
      options: {
        filter: true,

        customBodyRenderLite: (dataIndex) =>
          rows[dataIndex].fortuneAccountCreated ? (
            <CheckIcon
              sx={{
                fontSize: "1.2rem",
                fontWeight: "700",
                color: "#009966",
              }}
            />
          ) : (
            <ClearIcon
              sx={{
                fontSize: "1.2rem",
                fontWeight: "700",
                color: "red",
              }}
            />
          ),
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          if (rows[dataIndex]) {
            return (
              <UsersMoreMenu
                userId={rows[dataIndex]["docId"]}
                userData={rows[dataIndex]}
              />
            );
          }
        },
      },
    },
  ];

  const handleChangePage = (tableState) => {
    const { page } = tableState;
    setPage(page);

    if (searchInput.length <= 0) {
      const fetchMoreData = (key) => {
        if (key) {
          Pagination.fetchNextBatch(key, "users", "dateRegistered")
            .then((res) => {
              setLastKey(res.lastKey);
              setRows(rows.concat(res.fetchedData));
              setIsLoading(false);
            })
            .catch((err) => {
              console.log("Err: ", err);
            });
        }
      };

      fetchMoreData(lastKey);
    }
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage, 10));
    setPage(0);
  };

  const options = {
    responsive: "standard",
    rowsPerPage,
    rowsPerPageOptions: [10, 25, 50, 100],
    download: true,
    print: false,
    confirmFilters: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handleChangePage(tableState);
          break;
        case "changeRowsPerPage":
          handleChangeRowsPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
      <Box style={{ marginTop: "40px" }}>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Rubik, sans-serif",
            fontWeight: "500",
            fontSize: ".88rem",
            backgroundColor: "#009966",

            "&:hover": {
              backgroundColor: "rgba(0,153,102,.8)",
            },
          }}
          onClick={() => handleFilterSubmit(applyNewFilters)}
        >
          Apply Filters
        </Button>
      </Box>
    ),
  };

  useEffect(() => {
    Pagination.fetchFirstBatch("users", "dateRegistered")
      .then((res) => {
        setRows(res.fetchedData);
        setLastKey(res.lastKey);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
  }, []);

  console.log("users: ", rows);

  return (
    <div style={{ backgroundColor: "#f2fffb" }}>
      <EnhancedTableToolbar
        setSearchInput={setSearchInput}
        searchRef={searchRef}
        handleSearch={() => handleSearch(searchInput)}
      />
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme()}>
          {isLoading ? (
            <CircularStatic />
          ) : (
            <MUIDataTable data={rows} columns={columns} options={options} />
          )}
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
}
