import React, { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { Link as RouterLink } from "react-router-dom";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";

export default function WaiveLoanAmount({ loanId, loanData }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const LoanWaiveSchema = Yup.object().shape({
    amountRequested: Yup.string(),
    monthlyPayment: Yup.string(),
    amountToRepay: Yup.string(),
    amountToWaive: Yup.string().required("Waive Amount required!"),
    duration: Yup.string(),
    waiveOptions: Yup.string().required("Waive Option required!"),
    note: Yup.string().required("Waive Note required!"),
  });

  const formik = useFormik({
    initialValues: {
      amountRequested: loanData.amountRequested,
      monthlyPayment: loanData.monthlyPayment,
      amountToRepay: loanData.amountToRepay,
      amountToWaive: "",
      duration: loanData.duration,
      waiveOptions: "",
      note: "",
    },
    validationSchema: LoanWaiveSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log("Waive Values:", values);
      handleClose();
      resetForm();
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <MenuItem
        disabled={
          loanData?.pendingAction?.type === "repay" ||
          loanData?.pendingAction?.type === "cancel"
        }
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ContentCutIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Waive Loan Amount"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle
          sx={{ fontFamily: "Rubik, sans-serif", fontWeight: "bold" }}
        >
          Waive Loan Amount
        </DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  sx={{ width: "48%" }}
                  disabled
                  autoComplete="amountRequested"
                  type="text"
                  margin="normal"
                  label="Amount Requested"
                  {...getFieldProps("amountRequested")}
                  error={Boolean(
                    touched.amountRequested && errors.amountRequested
                  )}
                  helperText={touched.amountRequested && errors.amountRequested}
                />
                <TextField
                  sx={{ width: "48%" }}
                  disabled
                  autoComplete="duration"
                  type="text"
                  margin="normal"
                  label="Loan Duration"
                  {...getFieldProps("duration")}
                  error={Boolean(touched.duration && errors.duration)}
                  helperText={touched.duration && errors.duration}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  sx={{ width: "48%" }}
                  disabled
                  autoComplete="amountToRepay"
                  type="text"
                  margin="normal"
                  label="Amount To Repay (KES)"
                  {...getFieldProps("amountToRepay")}
                  error={Boolean(touched.amountToRepay && errors.amountToRepay)}
                  helperText={touched.amountToRepay && errors.amountToRepay}
                />
                <TextField
                  sx={{ width: "48%" }}
                  disabled
                  autoComplete="monthlyPayment"
                  type="text"
                  margin="normal"
                  label="Monthly Payment (KES)"
                  {...getFieldProps("monthlyPayment")}
                  error={Boolean(
                    touched.monthlyPayment && errors.monthlyPayment
                  )}
                  helperText={touched.monthlyPayment && errors.monthlyPayment}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextField
                  sx={{ width: "48%" }}
                  autoComplete="amountToWaive"
                  type="text"
                  margin="normal"
                  label="Amount To Waive (KES) *"
                  {...getFieldProps("amountToWaive")}
                  error={Boolean(touched.amountToWaive && errors.amountToWaive)}
                  helperText={touched.amountToWaive && errors.amountToWaive}
                />

                <FormControl variant="outlined" sx={{ width: "48%" }}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Waive Option *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    {...getFieldProps("waiveOptions")}
                    name="waiveOptions"
                    label="Waive Options"
                    error={Boolean(touched.waiveOptions && errors.waiveOptions)}
                    helperText={touched.waiveOptions && errors.waiveOptions}
                  >
                    <MenuItem value="waive_disqualify">
                      Waive and Disqualify
                    </MenuItem>
                    <MenuItem value="waive_maintain_limit">
                      Waive and Maintain Loan Limit
                    </MenuItem>
                    <MenuItem value="waive_continue_progression_rate">
                      Waive and Continue Progression Rate
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <TextField
                fullWidth
                multiline
                rows={2}
                autoComplete="note"
                type="text"
                margin="normal"
                label="Waive Note *"
                {...getFieldProps("note")}
                error={Boolean(touched.note && errors.note)}
                helperText={touched.note && errors.note}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="error"
                sx={{ mr: "2rem" }}
                onClick={handleClose}
              >
                <Typography sx={{ fontSize: ".9rem" }}>Cancel</Typography>
              </Button>
              <Button variant="contained" type="submit" color="success">
                <Typography sx={{ fontSize: ".9rem" }}>Waive Amount</Typography>
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
