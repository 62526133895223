import {TextField} from "@mui/material";
import {useState} from "react";

const ValidatedTextField = ({ label, validator, onChange, onTouch, type, defaultValue, inputType, children, disabled }) => {
    const [value, setValue] = useState(defaultValue || "");
    const [error, setError] = useState(false);
    const handleChange = e => {
        const newValue = e.target.value;
        const errorMessage = validator(newValue);
        setValue(newValue);
        setError(errorMessage);
        onTouch(!errorMessage);
        onChange(newValue);
    };
    return inputType === 'select' ? (
            <TextField
                label={label}
                value={value}
                onChange={handleChange}
                error={!!error}
                fullWidth
                helperText={error}
                margin="normal"
                select
                disabled={disabled}
            >
                {children}
            </TextField>
        ) : (
            <TextField
                label={label}
                value={value}
                onChange={handleChange}
                error={!!error}
                fullWidth
                helperText={error}
                type={type}
                margin="normal"
                disabled={disabled}
            />
        );

};

ValidatedTextField.defaultProps = {
    type: "text"
}

export default ValidatedTextField;