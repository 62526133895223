import { collection } from "firebase/firestore";
import React from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { Autocomplete, TextField } from "@mui/material";

import { db } from "../../../firebase/firebase";

function MessageTemplate({
  handleMessageSet,
  messageRef,
  touched,
  errors,
  getFieldProps,
}) {
  let templates = [];

  const templatesQuery = collection(db, "ticket_response_templates");

  const [docs] = useCollectionData(templatesQuery);

  docs?.map((template) => {
    templates.push(template.template);
  });

  const inputRef = messageRef;

  handleMessageSet(inputRef.current?.value);

  console.log("Value: ", inputRef.current?.value);

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={templates && templates}
      sx={{ width: "95%" }}
      clearOnBlur={false}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={inputRef}
          fullWidth
          multiline
          rows={3}
          autoComplete="description"
          type="text"
          margin="normal"
          label="Comment"
          {...getFieldProps("description")}
          error={Boolean(touched.description && errors.description)}
          helperText={touched.description && errors.description}
        />
      )}
    />
  );
}

export default MessageTemplate;
