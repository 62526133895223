import React from "react";

import UserActiveLoans from "./userActiveLoans";
import UserLoanHistory from "./userLoanHistory";

function UserLoans({ userId, userData }) {
  return (
    <div>
      <UserActiveLoans userId={userId} userData={userData} />
      <UserLoanHistory userId={userId} userData={userData} />
    </div>
  );
}

export default UserLoans;
