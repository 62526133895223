import { useRef, useState } from "react";

import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

// material
import { Menu, IconButton, Divider } from "@mui/material";
import ApproveLoan from "./approve";
import MakePayment from "./makePayment";
import WaiveLoanAmount from "./waiveLoan";
import ConfirmPayment from "./confirmLoanPayment";
// import CancelLoan from "./cancelLoan";
// import ConfirmCancelLoan from "./confirmCancel";

export default function UsersLoansMoreMenu({ loanId, loanData }) {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: { width: 220, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <ApproveLoan loanId={loanId} loanData={loanData} />
        <MakePayment loanId={loanId} loanData={loanData} />
        <ConfirmPayment loanId={loanId} loanData={loanData} />
        <WaiveLoanAmount loanId={loanId} loanData={loanData} />
        <Divider sx={{ borderBottomWidth: 2 }} />
        {/* <CancelLoan loanId={loanId} loanData={loanData} />
        <ConfirmCancelLoan loanId={loanId} loanData={loanData} /> */}
      </Menu>
    </>
  );
}
