import React, { useState, forwardRef } from "react";
import { useFormik, Form, FormikProvider, Field } from "formik";
import * as Yup from "yup";
import { Link as RouterLink } from "react-router-dom";

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import PaymentIcon from "@mui/icons-material/Payment";

import { LoanOperations } from "../../../firebase/operations";

function MakePayment({ loanId, loanData }) {
  const [open, setOpen] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const makePaymentSchema = Yup.object().shape({
    type: Yup.string().required("Payment Type is required!"),
    source: Yup.string().required("Transaction source is required!"),
    transId: Yup.string().required("Transaction Code is required!"),
    amount: Yup.string().required("Payment amount is required!"),
    lastUpdated: Yup.string().required("Last Updated date is required!"),
  });

  const formik = useFormik({
    initialValues: {
      type: "",
      source: "",
      transId: "",
      amount: "",
      lastUpdated: "",
    },
    validationSchema: makePaymentSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await LoanOperations.makePayment(
        loanId,
        loanData,
        values
      );
      console.log("VALUES: ", values);
      console.log("RESPONSE: ", response);
      if (response.isPaid) {
        setIsPaid(true);
        setOpenSnackbar(true);
        resetForm();
        handleClose();
      } else {
        setOpenSnackbar(true);
      }
    },
  });

  const InputFeedback = ({ error }) =>
    error ? (
      <Typography
        color="error"
        sx={{ fontSize: "0.8rem", fontFamily: "Rubik, sans-serif" }}
      >
        {error}
      </Typography>
    ) : null;

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isPaid ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isPaid
            ? "Payment made successfully!!!"
            : "Cannot Make Loan payment!!!"}
        </Alert>
      </Snackbar>
      <MenuItem
        disabled={
          loanData?.pendingAction?.type === "repay" ||
          loanData?.pendingAction?.type === "cancel"
            ? true
            : false
        }
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <PaymentIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Make Payment"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle
          sx={{
            fontWeight: "bold",
            fontFamily: "Rubik, sans-serif",
          }}
        >
          Make a Loan Payment
        </DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>
                Payment Type:
              </Typography>
              <div
                role="group"
                style={{
                  width: "80%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                aria-labelledby="my-radio-group"
              >
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Rubik, sans-serif",
                  }}
                >
                  <Field type="radio" name="type" value="monthly" />
                  Monthly
                </label>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Rubik, sans-serif",
                  }}
                >
                  <Field type="radio" name="type" value="full" />
                  Full (One Time)
                </label>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Rubik, sans-serif",
                  }}
                >
                  <Field type="radio" name="type" value="partial" />
                  Partial
                </label>
              </div>
              {touched.type && <InputFeedback error={errors.type} />}
              <Typography sx={{ mt: "1rem", fontFamily: "Rubik, sans-serif" }}>
                Transaction Source:
              </Typography>
              <div
                role="group"
                style={{
                  display: "flex",
                  width: "40%",
                  justifyContent: "space-between",
                }}
                aria-labelledby="my-radio-group"
              >
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Rubik, sans-serif",
                  }}
                >
                  <Field type="radio" name="source" value="mpesa" />
                  Mpesa
                </label>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Rubik, sans-serif",
                  }}
                >
                  <Field type="radio" name="source" value="cheque" />
                  Cheque
                </label>
              </div>
              {touched.source && <InputFeedback error={errors.source} />}
              <TextField
                fullWidth
                autoComplete="transId"
                type="text"
                margin="normal"
                label="Transaction Code"
                {...getFieldProps("transId")}
                error={Boolean(touched.transId && errors.transId)}
                helperText={touched.transId && errors.transId}
              />
              <TextField
                fullWidth
                autoComplete="amount"
                type="text"
                sx={{ mb: "1.5rem" }}
                margin="normal"
                label="Transaction Amount"
                {...getFieldProps("amount")}
                error={Boolean(touched.amount && errors.amount)}
                helperText={touched.amount && errors.amount}
              />
              <TextField
                fullWidth
                autoComplete="lastUpdated"
                type="datetime-local"
                margin="normal"
                label="Last Updated"
                {...getFieldProps("lastUpdated")}
                error={Boolean(touched.lastUpdated && errors.lastUpdated)}
                helperText={touched.lastUpdated && errors.lastUpdated}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                variant="outlined"
                color="error"
                sx={{ fontFamily: "Rubik, sans-serif" }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="success"
                variant="contained"
                sx={{ fontFamily: "Rubik, sans-serif" }}
              >
                Make Payment
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}

export default MakePayment;
