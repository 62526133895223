import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { db } from "../../../firebase/firebase";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField, Box, Button, Stack, Typography } from "@mui/material";

import CircularStatic from "../../shared/Loader/loader";

import { tableStyles } from "../../styles/tableStyles";
import NoData from "../../shared/NoData/noData";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: "true",
});

// table stylings
const getMuiTheme = () => createTheme(tableStyles);

function UserLoanHistory({ userId, userData }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const [requestedFrom, setRequestedFrom] = useState(null);
  const [requestedTo, setRequestedTo] = useState(null);
  const [dueTo, setDueTo] = useState(null);
  const [dueFrom, setDueFrom] = useState(null);

  let rows = [];

  const filterList = {};

  // handle filter
  const handleFilterSubmit = (applyFilters) => {
    const filters = applyFilters();
    console.log("Filters: ", filters);
    filters.map((item) => {
      const key = columns[`${filters.indexOf(item)}`].name;
      console.log("Key: ", key);
      let newFilter;
      if (key === "dateRequested") {
        newFilter = {
          requestedFrom: moment(item[0]).format("DD-MM-YYYY"),
          requestedTo: moment(item[1]).format("DD-MM-YYYY"),
        };
      } else if (key === "dueDate") {
        newFilter = {
          dueFrom: moment(item[0]).format("DD-MM-YYYY"),
          dueTo: moment(item[1]).format("DD-MM-YYYY"),
        };
      } else {
        newFilter = { [key]: moment(item[0]).format("DD-MM-YYYY") };
      }
      console.log("FILTER: ", newFilter);
      Object.assign(filterList, newFilter);
    });
    console.log("FILTER LIST: ", filterList);

    setIsFiltered(true);
  };

  const columns = [
    {
      name: "amountRequested",
      label: "Amount Requested",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) =>
          `KES ${rows[dataIndex].amountRequested}`,
      },
    },
    {
      name: "monthlyPayment",
      label: "Amount Paid",
      options: {
        filter: false,
      },
    },
    {
      name: "amountToDisburse",
      label: "Disburse(d) Amount",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) =>
          `KES ${rows[dataIndex].amountToDisburse}`,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => `${rows[dataIndex].status}`,
      },
    },
    {
      name: "dateRequested",
      label: "Date Requested",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              console.log(
                `From: ${moment(v[0]).format("MM-DD-YYYY")},  To: ${moment(
                  v[1]
                ).format("MM-DD-YYYY")}`
              );
              return [
                `Requested From: ${moment(v[0]).format("MM-DD-YYYY")}`,
                `Requested To: ${moment(v[1]).format("MM-DD-YYYY")}`,
              ];
            }
            if (v[0]) {
              return `Requested From: ${moment(v[0]).format("MM-DD-YYYY")}`;
            }
            if (v[1]) {
              return `Requested To: ${moment(v[1]).format("MM-DD-YYYY")}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            console.log(
              "customFilterListOnDelete: ",
              filterList,
              filterPos,
              index
            );

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(dateRequested, filters) {
            const formattedDate =
              typeof dateRequested === "string"
                ? moment(dateRequested, "DD-MM-YYYY").format("MM-DD-YYYY")
                : moment(dateRequested.toDate().toDateString()).format(
                    "MM-DD-YYYY"
                  );

            console.log("Formatted Date: ", formattedDate);

            if (filters[0] && filters[1]) {
              let formattedTo = moment(filters[1]).format("MM-DD-YYYY");
              let formattedFrom = moment(filters[0]).format("MM-DD-YYYY");

              return (
                moment(formattedDate, "MM-DD-YYYY").isBetween(
                  formattedFrom,
                  formattedTo
                ) === false
              );
            }
            if (filters[1]) {
              let formattedFrom = moment(filters[1]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrAfter(formattedFrom);
            }
            if (filters[0]) {
              let formattedTo = moment(filters[0]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrBefore(formattedTo);
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Requested From:"
                  value={requestedFrom || filterList[index][0]}
                  onChange={(newValue) => {
                    setRequestedFrom(newValue);
                    filterList[index][0] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Requested To:"
                  value={requestedTo || filterList[index][1]}
                  onChange={(newValue) => {
                    setRequestedTo(newValue);
                    filterList[index][1] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Stack>
          ),
        },
        customBodyRenderLite: (dataIndex) =>
          moment(rows[dataIndex].dateRequested.toDate()).format(
            "MMMM Do YYYY, h:mm:ss a"
          ),
      },
    },
    {
      name: "dueDate",
      label: "Due Date",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              console.log(
                `From: ${moment(v[0]).format("MM-DD-YYYY")},  To: ${moment(
                  v[1]
                ).format("MM-DD-YYYY")}`
              );
              return [
                `Due From: ${moment(v[0]).format("MM-DD-YYYY")}`,
                `Due To: ${moment(v[1]).format("MM-DD-YYYY")}`,
              ];
            }
            if (v[0]) {
              return `Due From: ${moment(v[0]).format("MM-DD-YYYY")}`;
            }
            if (v[1]) {
              return `Due To: ${moment(v[1]).format("MM-DD-YYYY")}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            console.log(
              "customFilterListOnDelete: ",
              filterList,
              filterPos,
              index
            );

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(dueDate, filters) {
            const formattedDate =
              typeof dueDate === "string"
                ? moment(dueDate, "DD-MM-YYYY").format("MM-DD-YYYY")
                : moment(dueDate.toDate().toDateString()).format("MM-DD-YYYY");

            console.log("Formatted Date: ", formattedDate);

            if (filters[0] && filters[1]) {
              let formattedTo = moment(filters[1]).format("MM-DD-YYYY");
              let formattedFrom = moment(filters[0]).format("MM-DD-YYYY");

              return (
                moment(formattedDate, "MM-DD-YYYY").isBetween(
                  formattedFrom,
                  formattedTo
                ) === false
              );
            }
            if (filters[1]) {
              let formattedFrom = moment(filters[1]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrAfter(formattedFrom);
            }
            if (filters[0]) {
              let formattedTo = moment(filters[0]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrBefore(formattedTo);
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Due From:"
                  value={dueFrom || filterList[index][0]}
                  onChange={(newValue) => {
                    setDueFrom(newValue);
                    filterList[index][0] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Due To:"
                  value={dueTo || filterList[index][1]}
                  onChange={(newValue) => {
                    setDueTo(newValue);
                    filterList[index][1] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Stack>
          ),
        },
        customBodyRenderLite: (dataIndex) =>
          moment(rows[dataIndex].dueDate.toDate()).format(
            "MMMM Do YYYY, h:mm:ss a"
          ),
      },
    },
  ];

  const handleChangePage = (tableState) => {
    const { page } = tableState;
    setPage(page);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage, 10));
    setPage(0);
  };

  const options = {
    responsive: "standard",
    rowsPerPage,
    rowsPerPageOptions: [5, 10],
    download: false,
    print: false,
    confirmFilters: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handleChangePage(tableState);
          break;
        case "changeRowsPerPage":
          handleChangeRowsPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
      <Box style={{ marginTop: "40px" }}>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Rubik, sans-serif",
            fontWeight: "500",
            fontSize: ".88rem",
            backgroundColor: "#009966",

            "&:hover": {
              backgroundColor: "rgba(0,153,102,.8)",
            },
          }}
          onClick={() => handleFilterSubmit(applyNewFilters)}
        >
          Apply Filters
        </Button>
      </Box>
    ),
  };

  useEffect(() => {
    // fetch active user loans
    const fetchUserLoans = async () => {
      const loansRef = collection(db, "loans_v2_history");

      //query all loans with user id
      const loansHistory = query(loansRef, where("clientId", "==", userId));

      const loanSnapshot = await getDocs(loansHistory);

      loanSnapshot.size < 1
        ? setIsLoading(false)
        : loanSnapshot.forEach((doc) => {
            console.log("Data: ", doc.data());
            rows.push(doc.data());
            setIsLoading(false);
          });
    };

    fetchUserLoans();
  }, []);

  console.log("LOANS HISTORY: ", rows);

  return (
    <div style={{ backgroundColor: "#F7F7F7", marginTop: "2rem" }}>
      <Box>
        <Typography
          sx={{
            backgroundColor: "white",
            fontSize: "1.1rem",
            fontWeight: "bold",
            marginBottom: "0.8rem",
            fontFamily: "Rubik, sans-serif",
          }}
        >{`${userData.firstName} ${userData.lastName} Loans History`}</Typography>
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={getMuiTheme()}>
            {isLoading ? (
              <CircularStatic />
            ) : rows.length < 1 ? (
              <NoData text="No Loans History Found!" />
            ) : (
              <MUIDataTable data={rows} columns={columns} options={options} />
            )}
          </ThemeProvider>
        </CacheProvider>
      </Box>
    </div>
  );
}

export default UserLoanHistory;
