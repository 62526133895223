import React, { useState, forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { LoanOperations } from "../../../firebase/operations";

function ApproveLoan({ loanId, loanData }) {
  const [open, setOpen] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  console.log("LOAN ID:", loanId);

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApproveClick = async () => {
    const response = await LoanOperations.approveLoan(loanId);
    if (response === "approved") {
      setIsApproved(true);
      setOpenSnackbar(true);
      handleClose();
    } else {
      setOpenSnackbar(true);
    }
  };

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isApproved ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isApproved
            ? "Loan Approved successfully!!!"
            : "Cannot Approve Loan!!!"}
        </Alert>
      </Snackbar>
      <MenuItem
        disabled={loanData.status === "pending" ? false : true}
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <CheckBoxIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Approve Loan"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle
          sx={{ fontFamily: "Rubik, sans-serif", fontWeight: "bold" }}
        >
          Approve Loan
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{ fontFamily: "Rubik, sans-serif", marginBottom: "1rem" }}
          >
            Are you sure you want to approve the selected loan?
          </Typography>
          <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>
            Client Type:{" "}
            <span
              style={{ fontWeight: "bold" }}
            >{`${loanData.clientType}`}</span>
          </Typography>
          <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>
            Client Phone Number:{" "}
            <span
              style={{ fontWeight: "bold" }}
            >{`${loanData.phoneNumber}`}</span>
          </Typography>
          <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>
            Amount Requested:{" "}
            <span
              style={{ fontWeight: "bold" }}
            >{`${loanData.amountRequested}`}</span>
          </Typography>
          <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>
            Amount To Disburse:{" "}
            <span
              style={{ fontWeight: "bold" }}
            >{`${loanData.amountToDisburse}`}</span>
          </Typography>
          <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>
            Amount To Repay:{" "}
            <span
              style={{ fontWeight: "bold" }}
            >{`${loanData.amountToRepay}`}</span>
          </Typography>
          <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>
            Monthly Payment:{" "}
            <span
              style={{ fontWeight: "bold" }}
            >{`${loanData.monthlyPayment}`}</span>
          </Typography>
          <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>
            Duration:{" "}
            <span
              style={{ fontWeight: "bold" }}
            >{`${loanData.duration} month(s)`}</span>
          </Typography>
          <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>
            Interest:{" "}
            <span
              style={{ fontWeight: "bold" }}
            >{`${loanData.interest}%`}</span>
          </Typography>
          <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>
            Status:{" "}
            <span style={{ fontWeight: "bold" }}>{`${loanData.status}`}</span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{ fontFamily: "Rubik, sans-serif", color: "red" }}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="success"
            sx={{ fontFamily: "Rubik, sans-serif" }}
            onClick={handleApproveClick}
          >
            Approve
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ApproveLoan;
