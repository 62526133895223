import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase/firebase";

// get transaction by mpesa code
const getTransactionByCode = async (code, direction) => {
  direction = direction || "incoming";
  const transactionRef = query(
    collection(db, "mpesa_manual_transactions"),
    where("direction", "==", direction),
    where("receiptNo", "==", code)
  );

  const transactionSnapshot = await getDocs(transactionRef);

  let transaction = null;
  if (!transactionSnapshot.empty) {
    transaction = transactionSnapshot.docs[0].data();
    transaction.docId = transactionSnapshot.docs[0].id;
  }
  return transaction;
};

const updateRemainingAmount = async (docId, remainingAmount) => {
  const docRef = doc(db, "mpesa_manual_transactions", docId);
  await updateDoc(docRef, {
    lastUpdated: serverTimestamp(),
    remainingAmount: remainingAmount,
  });
};

// check if the transaction exists and update the loan
// by subtracting the amount from the remaining loan amount
export const checkTransactionExists = async (code, amount) => {
  let isValidTransaction = false;

  const transaction = await getTransactionByCode(code);
  if (transaction && transaction.receiptNo === code) {
    transaction.remainingAmount =
      transaction.remainingAmount || transaction.amount;
    if (transaction.remainingAmount >= amount) {
      const remainingAmount = transaction.remainingAmount - amount;
      console.log(
        `Found manual mpesa transaction, remainingAmount > before: ${transaction.remainingAmount}, after: ${remainingAmount}`
      );
      await updateRemainingAmount(transaction.docId, remainingAmount);
      isValidTransaction = true;
    } else {
      console.log(
        `Mpesa transaction NOT FOUND remainingAmount: ${transaction.remainingAmount}`
      );
    }
  }
  console.log(
    `verifyManualTransaction > isValidTransaction: `,
    isValidTransaction
  );
  return isValidTransaction;
};

// get sent transaction
export const getSentTransaction = async (loanData) => {
  const transactionCollectionRef = collection(db, "mpesa_manual_transactions");
  const transactionQuery = query(
    transactionCollectionRef,
    where("direction", "==", "outgoing"),
    where("amount", "==", loanData.amountRequested),
    where("phoneNumber", "==", loanData.phoneNumber),
    where("completionTime", ">=", loanData.dateRequested)
  );

  const transactionSnapshot = await getDocs(transactionQuery);

  let transaction = null;
  if (!transactionSnapshot.empty) {
    transaction = transactionSnapshot.docs[0].data();
    transaction.docId = transactionSnapshot.docs[0].id;
  }

  return transaction;
};

// check if sent transaction exists
export const getSentExists = async (loanData) => {
  const transaction = await getSentTransaction(loanData);
  console.log(
    `getSentExists > cond: ${transaction !== null}, transaction:`,
    transaction
  );
  return transaction !== null;
};
