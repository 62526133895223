import React, { useState, forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import { AirtimeOperations } from "../../firebase/operations";

export default function ManualTransfer({ airtimeId, airtimeData }) {
  const [open, setOpen] = useState(false);
  const [isTransferred, setIsTransferred] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // const handleManualTransfer = async () => {
  //   const response = await AirtimeOperations.manualTransfer(airtimeId);
  //   if (response === "manual_transfer") {
  //     handleClose();
  //     setIsTransferred(true);
  //     setOpenSnackbar(true);
  //     window.location.reload();
  //   } else {
  //     setErrorMsg(response);
  //     setOpenSnackbar(true);
  //   }
  // };
  const handleManualTransfer = async () => {
    const response = await AirtimeOperations.manualTransfer(airtimeId);
    if (response === "manual_transfer") {
      handleClose();
      setIsTransferred(true);
      setOpenSnackbar(true);
      window.location.reload();
    } else {
      if (typeof response === "object" && response.message) {
        // If the error is an object with a 'message' property
        setErrorMsg(response.message);
      } else if (typeof response === "string") {
        // If the error is a string
        setErrorMsg(response);
      } else {
        // Default error message
        setErrorMsg("An unexpected error occurred.");
      }
      setOpenSnackbar(true);
    }
  };
  

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isTransferred ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isTransferred
            ? "Airtime transfer made successfully!!!"
            : "Cannot Make Airtime transfer: " + errorMsg}
        </Alert>
      </Snackbar>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <SendToMobileIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Transfer"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        onBackdropClick="false"
        maxWidth="xl"
      >
        <DialogTitle sx={{ fontWeight: "bold", textDecoration: "underline" }}>
          Transfer Airtime
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>
            Are you sure you want to manually{" "}
            <span style={{ fontWeight: "bold" }}>transfer</span> airtime of{" "}
            <span style={{ fontWeight: "bold" }}>
              Ksh. {airtimeData.amount}
            </span>{" "}
            to{" "}
            <span style={{ fontWeight: "bold" }}>
              {airtimeData.displayName}
            </span>{" "}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Rubik, sans-serif",
              fontWeight: "500",
              fontSize: ".88rem",
              backgroundColor: "#009966",

              "&:hover": {
                backgroundColor: "rgba(0,153,102,.8)",
              },
            }}
            onClick={handleManualTransfer}
          >
            Transfer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
