import React, { useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";

import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Send from "./Send_money/send-money";
import Payments from "./Payments/payments";
import PendingTransactions from "./Pending/pendingTransactions";
import SendMoneyToMany from "./SendToMany/sendToMany";
import BankDeposits from "./BankDeposits/bankDeposits";
import ReversalsRequested from "./ReversalRequests/reversal-requests";
import MerchantTransactions from "./MerchantTransactions/merchantTransactions";
// import Received from "./Received/received";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <AppBar elevation={0} position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            "& button": {
              backgroundColor: "#f2fffb",
              color: "#757874",
              fontWeight: "400",
              fontFamily: "Rubik, sans-serif",
              fontSize: ".88rem",
            },
            "& button.Mui-selected": {
              fontFamily: "Rubik, sans-serif",
              fontWeight: "700",
              fontSize: ".88rem",
              color: "#FFFFFF",
              backgroundColor: "#009966",
            },
          }}
          TabIndicatorProps={{
            style: { backgroundColor: "#009966", height: "3px" },
          }}
          textColor="#009966"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Reversals Requested" {...a11yProps(0)} />
          <Tab label="Pending Transactions" {...a11yProps(1)} />
          <Tab label="Send Money" {...a11yProps(3)} />
          <Tab label="Payments" {...a11yProps(4)} />
          <Tab label="Send To Many" {...a11yProps(5)} />
          <Tab label="Bank Deposits" {...a11yProps(6)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ReversalsRequested />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <PendingTransactions />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <Send />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <Payments />
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          <SendMoneyToMany />
        </TabPanel>
        <TabPanel value={value} index={6} dir={theme.direction}>
          <BankDeposits />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
