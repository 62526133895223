import React from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import SaveIcon from "@mui/icons-material/Save";
import TurnSlightRightIcon from "@mui/icons-material/TurnSlightRight";
import { Box } from "@mui/material";

function MoreInfo({ ticketId, ticketData }) {
  const moreInfoSchema = Yup.object().shape({
    description: Yup.string().required("Comment is required!"),
  });

  const formik = useFormik({
    initialValues: {
      description: "",
    },
    validationSchema: moreInfoSchema,
    onSubmit: (values, { resetForm }) => {
      console.log("In here!");
      console.log("VALUES: ", values);
      resetForm();
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Box>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            disabled
            autoComplete="category"
            type="text"
            margin="normal"
            label="Category"
            {...getFieldProps("category")}
            error={Boolean(touched.category && errors.category)}
            helperText={touched.category && errors.category}
          />
          <TextField
            fullWidth
            disabled
            autoComplete="subject"
            type="text"
            margin="normal"
            label="Subject"
            {...getFieldProps("subject")}
            error={Boolean(touched.subject && errors.subject)}
            helperText={touched.subject && errors.subject}
          />
          <TextField
            fullWidth
            disabled
            autoComplete="source"
            type="text"
            margin="normal"
            label="Source"
            {...getFieldProps("source")}
            error={Boolean(touched.source && errors.source)}
            helperText={touched.source && errors.source}
          />
          <TextField
            fullWidth
            autoComplete="appVersion"
            type="text"
            margin="normal"
            label="App Version*"
            {...getFieldProps("appVersion")}
            error={Boolean(touched.appVersion && errors.appVersion)}
            helperText={touched.appVersion && errors.appVersion}
          />
          <TextField
            fullWidth
            disabled
            autoComplete="status"
            type="text"
            margin="normal"
            label="Status"
            {...getFieldProps("status")}
            error={Boolean(touched.status && errors.status)}
            helperText={touched.status && errors.status}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              mt: ".5rem",
            }}
          >
            <Button
              color="secondary"
              variant="outlined"
              sx={{
                fontFamily: "Rubik, sans-serif",
                fontSize: "0.7rem",
              }}
              type="reset"
              startIcon={<TurnSlightRightIcon sx={{ color: "purple" }} />}
            >
              Move
            </Button>
            <Button
              color="success"
              variant="contained"
              sx={{
                fontFamily: "Rubik, sans-serif",
                fontSize: "0.7rem",
              }}
              startIcon={<SaveIcon sx={{ color: "white" }} />}
            >
              Save
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
}

export default MoreInfo;
