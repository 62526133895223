import React, { useState } from "react";
import { Link as RouterLink, Outlet, useNavigate } from "react-router-dom";

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import UserTabs from "./userTabs";

export default function ViewUser({ userId, userData }) {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/home/clients");
  };

  return (
    <div>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <VisibilityIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="View User"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        onBackdropClick="false"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>User Details</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <UserTabs userId={userId} userData={userData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Outlet />
    </div>
  );
}
