import { useRef, useState } from "react";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
// material
import { Menu, IconButton } from "@mui/material";

import ManualTransfer from "./transferAirtime";
import ManualCancel from "./manualCancel";
// import ManualReverse from "./reverseAirtime";
import ViewAirtime from "./viewAirtime";
import CheckAirtimeStatus from "./checkAirtimeStatus";
import ChangeAirtimeStatus from "./changeAirtimeStatus";

export default function AirtimeMoreMenu({ airtimeId, airtimeData }) {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setOpen(true)}>
        <MoreVertOutlinedIcon width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <ViewAirtime airtimeId={airtimeId} airtimeData={airtimeData} />
        <ManualTransfer airtimeId={airtimeId} airtimeData={airtimeData} />
        {airtimeData.status !== "sent" ? (
          <ManualCancel airtimeId={airtimeId} airtimeData={airtimeData} />
        ) : (
          ""
        )}
        <CheckAirtimeStatus airtimeId={airtimeId} airtimeData={airtimeData} />
        <ChangeAirtimeStatus airtimeId={airtimeId} airtimeData={airtimeData} />
        {/* {airtimeData.status !== "sent" &&
        airtimeData.status !== "manual_transfer" &&
        airtimeData.status !== "manual_cancelled" ? (
          <ManualReverse airtimeId={airtimeId} airtimeData={airtimeData} />
        ) : (
          ""
        )} */}
      </Menu>
    </>
  );
}
