import React, { useState, forwardRef } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Link as RouterLink } from "react-router-dom";

import { MessagesOperations } from "../../../firebase/operations";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export default function UpdateMessage({ messageId, messageData }) {
  let description = messageData[1];

  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isUpdated, setIsUpdated] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const UpdateMessageSchema = Yup.object().shape({
    messageDescription: Yup.string().required(
      "Message Description is required"
    ),
  });

  const formik = useFormik({
    initialValues: {
      messageDescription: description,
    },
    validationSchema: UpdateMessageSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await MessagesOperations.updateMessage(
        messageId,
        values
      );

      if (response.updated) {
        setIsUpdated(true);
        setOpenSnackbar(true);
        resetForm();
        handleClose();
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        setErrorMsg(response.err);
        setOpenSnackbar(true);
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isUpdated ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isUpdated
            ? "Message Updated successfully!!!"
            : "Cannot Update Message: " + errorMsg}
        </Alert>
      </Snackbar>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ModeEditOutlineOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Update"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle>Update {messageId} Message</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                fullWidth
                autoComplete="messageDescription"
                type="text"
                multiline
                rows={4}
                margin="normal"
                label="Message Description"
                {...getFieldProps("messageDescription")}
                error={Boolean(
                  touched.messageDescription && errors.messageDescription
                )}
                helperText={
                  touched.messageDescription && errors.messageDescription
                }
              />
            </DialogContent>
            <DialogActions>
              <Button
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  color: "#009966",
                  border: "2px solid #009966",
                  borderRadius: "10px",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "500",
                  fontSize: ".88rem",
                  backgroundColor: "#009966",
                  color: "white",

                  "&:hover": {
                    backgroundColor: "rgba(0,153,102,.8)",
                  },
                }}
                type="submit"
              >
                Update
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
