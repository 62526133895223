export const transactionStatus = {
  PENDING: "pending",
  CANCELLED: "cancelled",
  PROCESSED: "processed",
  PREPROCESSED: "preprocessed",
  APPROVED: "approved",
  FAILED: "failed",
  RETRY: "retry",
  MANUAL_TRANSFER: "manual_transfer",
  MANUAL_SENT: "manual_sent",
  MANUAL_CANCELLED: "manual_cancelled",
  REVERSE: "reverse",
  REVERSED: "reversed",
  SENT: "sent",
  RECEIVED: "received",
  STK_SENT: "stk_sent",
};

export const airtimeStatus = {
  REQUESTED: "requested",
  CANCELLED: "cancelled",
  PROCESSED: "processed",
  SENT: "sent",
  FAILED: "failed",
  APPROVED: "approved",
  REVERSED: "reversed",
  STK_SENT: "stk_sent",
  MANUAL_TRANSFER: "manual_transfer",
  MANUAL_CANCELLED: "manual_cancelled",
  MANUAL_REVERSED: "manual_reversed",
  RECEIVED: "received",
  MANUAL_SENT: "manual_sent",
};
