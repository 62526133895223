import React, { useState, forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { UsersOperations } from "../../firebase/operations";

export default function DeleteUser({ userId, userData }) {
  const [open, setOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    const response = await UsersOperations.deleteUser(userId);
    console.log("response: ", response);
    if (response.deleted) {
      setIsDeleted(true);
      setOpenSnackbar(true);
      handleClose();
    } else {
      setErrorMsg(response.err);
      setOpenSnackbar(true);
    }
  };

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isDeleted ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isDeleted
            ? "User Deleted successfully!!!"
            : "Cannot Delete: " + errorMsg}
        </Alert>
      </Snackbar>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <DeleteOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Delete User"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle
          sx={{ fontFamily: "Rubik, sans-serif", fontWeight: "bold" }}
        >
          Delete User
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>
            Deleting{" "}
            <span
              style={{ fontWeight: "bold" }}
            >{`${userData.firstName} ${userData.lastName}`}</span>{" "}
            from the system!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{ fontFamily: "Rubik, sans-serif" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            variant="outlined"
            color="error"
            sx={{ fontFamily: "Rubik, sans-serif" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
