import React, { useState, forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import DoneIcon from "@mui/icons-material/Done";
import { ReferralCodeOperations } from "../../firebase/operations";

export default function ManualProcessCode({ codeId, rewardData, codeData }) {
  const [open, setOpen] = useState(false);
  const [isProcessed, setIsProcessed] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleCodeProcessing = async (event) => {
    const reward = codeData?.rewards.filter((reward) => reward.event === event);
    reward[0]["manuallyProcessed"] = true;

    const response = await ReferralCodeOperations.manuallyProcessReward(
      codeId,
      codeData
    );

    if (response.isProcessed) {
      handleClose();
      setIsProcessed(true);
      setOpenSnackbar(true);
    } else {
      setErrorMsg(response.err);
      setOpenSnackbar(true);
    }
  };

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isProcessed ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isProcessed
            ? "Manual Process made successfully!!!"
            : "Cannot Manually Process transaction: " + errorMsg}
        </Alert>
      </Snackbar>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <DoneIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Manually Process"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <DialogTitle
          sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
        >
          Process Referral Code
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>
            Are you sure you want to manually process the referral code{" "}
            <span style={{ fontWeight: "bold" }}>{codeData.refCode}</span> with
            reward of{" "}
            <span style={{ fontWeight: "bold" }}>Ksh. {rewardData.amount}</span>{" "}
            for{" "}
            <span style={{ fontWeight: "bold" }}>
              {rewardData.toPhoneNumber}
            </span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Rubik, sans-serif",
              fontWeight: "500",
              fontSize: ".88rem",
              backgroundColor: "#009966",

              "&:hover": {
                backgroundColor: "rgba(0,153,102,.8)",
              },
            }}
            onClick={() => handleCodeProcessing(rewardData.event)}
          >
            Process
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
