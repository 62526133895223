import React, { useState, forwardRef } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { Link as RouterLink } from "react-router-dom";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Snackbar,
  Typography,
  TextField,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { KYCOperations } from "../../../firebase/operations";

export default function DeclineBusiness({ businessData, businessId }) {
  const [open, setOpen] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const DeclineBusinessSchema = Yup.object().shape({
    comment: Yup.string().required("Reason for declining is required"),
  });

  const formik = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema: DeclineBusinessSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await KYCOperations.declineKYC(values, businessId);

      if (response.declined) {
        setIsDeclined(true);
        setOpenSnackbar(true);
        resetForm();
        handleClose();
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        setErrorMsg(response.error);
        setOpenSnackbar(true);
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isDeclined ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isDeclined
            ? "KYC Declined successfully!!!"
            : "Cannot Decline KYC: " + errorMsg}
        </Alert>
      </Snackbar>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleClickOpen}
      >
        <ListItemIcon>
          <ThumbDownAltIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Decline Business"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog open={open} onClose={handleClose} onBackdropClick="false">
        <DialogTitle
          sx={{
            fontFamily: "Rubik, sans-serif",
            paddingBottom: "0",
          }}
        >
          Decline {businessData.businessName}'s Business
        </DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Typography
                sx={{
                  fontStyle: "italic",
                  color: "red",
                  fontFamily: "Rubik, sans-serif",
                  marginBottom: "1.3rem",
                  fontSize: ".92rem",
                  maxWidth: "80%",
                }}
              >
                ***IMPORTANT - Kindly verify that the data required is{" "}
                <strong>NOT</strong> available before you decline the business{" "}
              </Typography>
              <TextField
                fullWidth
                autoComplete="comment"
                multiline
                rows={3}
                type="text"
                margin="normal"
                label="Reason for declining"
                {...getFieldProps("comment")}
                error={Boolean(touched.comment && errors.comment)}
                helperText={touched.comment && errors.comment}
              />
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ fontFamily: "Rubik, sans-serif" }}
                variant="outlined"
                color="success"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                sx={{ fontFamily: "Rubik, sans-serif" }}
                variant="contained"
                type="submit"
                color="error"
              >
                Decline
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
