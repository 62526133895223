import React, { useState, forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { TicketsOperations } from "../../../firebase/operations";

export default function DeleteTemplate({ templateId, templateData }) {
  const [open, setOpen] = useState(false);
  const [openDeleteTemplate, setOpenDeleteTemplate] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleDeleteTemplateOpen = () => {
    setOpenDeleteTemplate(true);
  };

  const handleDeleteTemplateClose = () => {
    setOpenDeleteTemplate(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    const response = await TicketsOperations.deleteTemplate(templateId);
    console.log("response: ", response);

    if (response.deleted) {
      setIsDeleted(true);
      setOpenSnackbar(true);
      handleDeleteTemplateClose();
      setTimeout(function () {
        window.location.reload();
      }, 3000);
    } else {
      setErrorMsg(response.err);
      setOpenSnackbar(true);
    }
  };

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleClose}
          severity={isDeleted ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isDeleted
            ? "Template Deleted successfully!!!"
            : "Cannot Delete template: " + errorMsg}
        </Alert>
      </Snackbar>
      <MenuItem
        component={RouterLink}
        to="#"
        sx={{ color: "text.secondary" }}
        onClick={handleDeleteTemplateOpen}
      >
        <ListItemIcon>
          <DeleteOutlinedIcon width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          primary="Delete Template"
          primaryTypographyProps={{ variant: "body2" }}
        />
      </MenuItem>
      <Dialog open={openDeleteTemplate} onClose={handleDeleteTemplateClose}>
        <DialogTitle
          sx={{ fontFamily: "Rubik, sans-serif", fontWeight: "bold" }}
        >
          Delete Template
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              color: "red",
              fontStyle: "italic",
              fontFamily: "Rubik, sans-serif",
            }}
          >
            **Are you sure you want to delete the following template:
          </Typography>
          <Typography
            sx={{
              mt: "1.5rem",
              fontWeight: "bold",
              fontFamily: "Rubik, sans-serif",
            }}
          >
            {templateData?.template}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteTemplateClose}
            sx={{ fontFamily: "Rubik, sans-serif" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            variant="outlined"
            color="error"
            sx={{ fontFamily: "Rubik, sans-serif" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
