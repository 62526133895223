import React, { useState, forwardRef } from "react";
import { useFormik, Form, FormikProvider, Field } from "formik";
import * as Yup from "yup";
import { Link as RouterLink } from "react-router-dom";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DoneIcon from "@mui/icons-material/Done";
import {
    ListItemIcon,
    ListItemText,
    MenuItem,
    Snackbar,
    Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { KYCOperations } from "../../../firebase/operations";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function VerifyBusiness({ businessData, businessId, refresh }) {
    const [open, setOpen] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [loading, setLoading] = useState(false);

    const Alert = forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const handleSubmit = async () => {
        setLoading(true);

        const response = await KYCOperations.verifyKYC({}, businessId);

        setLoading(false);

        if (response.verified) {
            setIsVerified(true);
            setOpenSnackbar(true);
            refresh();
        } else {
            setErrorMsg(response.error);
            setOpenSnackbar(true);
        }

        setOpen(false);
    }

    return (
        <div>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={isVerified ? "success" : "error"}
                    sx={{ width: "100%" }}
                >
                    {isVerified
                        ? "KYC Verified successfully!!!"
                        : "Cannot Verify KYC: " + errorMsg}
                </Alert>
            </Snackbar>
            <MenuItem
                component={RouterLink}
                to="#"
                sx={{ color: "text.secondary" }}
                onClick={handleClickOpen}
            >
                <ListItemIcon>
                    <DoneIcon width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                    primary="Verify Business"
                    primaryTypographyProps={{ variant: "body2" }}
                />
            </MenuItem>
            <Dialog open={open} onClose={handleClose} onBackdropClick="false">
                <DialogTitle
                    sx={{
                        fontFamily: "Rubik, sans-serif",
                    }}
                >
                   Confirm Verification
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to verify {businessData.businessName}'s Business
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <Typography>
                            <b>Name: </b> {businessData.hakikishaName}
                        </Typography>
                        { businessData.businessType === 'paybill' && (
                            <>
                                <Typography>
                                    <b>Paybill No. </b> {businessData.payBillNo}
                                </Typography>
                                <Typography>
                                    <b>Account No. </b> {businessData.accountNo}
                                </Typography>
                            </>
                        )}
                        { businessData.businessType === 'till' && (
                            <Typography>
                                <b>Till No. </b> {businessData.tillNo}
                            </Typography>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        onClick={handleSubmit}
                        sx={{
                            backgroundColor: "#009966",
                            color: "white",
                            "&:hover": {
                                backgroundColor: "#009966",
                            },
                        }}
                    >
                        {loading ? "Verifying..." : "Verify"}

                        {loading && (
                            <CircularProgress
                                size={14}
                                sx={{ color: "white" ,ml: 1 }}
                            />
                        )}
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}
