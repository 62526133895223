import React, { useState, useEffect, forwardRef } from "react";

import { db, functions } from "../../../firebase/firebase";

import {
  Switch,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Snackbar,
  TextField,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { httpsCallable } from "firebase/functions";
import CircularStatic from "../../shared/Loader/loader";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

function Switches() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(null);
  const [switches, setSwitches] = useState({
    airtime_on: true,
    loans_on: true,
    payments_on: true,
    send_money_on: true,
    bank_deposit_on: true,
    float_purchase_on: true,
  });
  const [reason, setReason] = useState("");

  // current session user
  const sessionUser = JSON.parse(localStorage.getItem("user"));

  // customizable alert
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const getServiceNames = (name) => {
    switch (name) {
      case "airtime_on":
        return "airtime";
      case "loans_on":
        return "loans";
      case "payments_on":
        return "payments";
      case "send_money_on":
        return "send money";
      case "bank_deposit_on":
        return "bank deposits";
      case "float_purchase_on":
        return "float purchase";
      case "pinless_airtime_on":
        return "pinless airtime";
      case "pinless_airtime_commission_on":
        return "pinless airtime commission";
      case "float_shortcode_primary":
        return "float shortcode primary";
    default:
        return "No valid service";
    }
  };

  const handleClickOpen = (e) => {
    let name = e.target.name;
    setOpen(true);
    setName(name);
  };

  const handleAlertClose = () => {
    setOpen(false);
  };

  const handleClose = (switchName) => {
    setOpen(false);

    setSwitches({
      ...switches,
      [switchName]: !switches.switchName,
    });
  };

  const handleReasonInput = (e) => {
    const { value } = e.target;
    setReason(value);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleChange = (e) => {
    setSwitches({
      ...switches,
      [e.target.name]: !e.target.checked,
    });
  };

  const handleSwitch = async () => {
    const serviceSwitches = httpsCallable(
      functions,
      "settingsGroup-onSwitchServices "
    );

    // updating the service switches
    const serviceSwitch = serviceSwitches({
      [name]: !switches[name],
    });

    try {
      const response = await serviceSwitch;

      if (response.data.statusCode === 200) {
        setIsUpdated(true);
        setOpenSnackbar(true);
        setSwitches({ ...switches, [name]: !switches[name] });
        handleClose(name);

        const updatedSwitch = {
          [name]: !switches[name],
          updatedBy: sessionUser?.user.email,
          reason: reason,
          updatedAt: serverTimestamp(),
        };

        await addDoc(collection(db, "service_switches"), updatedSwitch);

        window.location.reload();
      } else {
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const switchesStatus = httpsCallable(
      functions,
      "settingsGroup-servicesStatus"
    );
    const statuses = switchesStatus();

    statuses.then((response) => {
      console.log("STATUS RESPONSE:", response);
      setSwitches({
        airtime_on: response?.data?.message.airtime_on,
        loans_on: response?.data?.message.loans_on,
        payments_on: response?.data?.message.payments_on,
        send_money_on: response?.data?.message.send_money_on,
        bank_deposit_on: response?.data?.message.bank_deposit_on,
        float_purchase_on: response?.data?.message.float_purchase_on,
        pinless_airtime_on: response?.data?.message.pinless_airtime_on,
        pinless_airtime_commission_on: response?.data?.message.pinless_airtime_commission_on,
        float_shortcode_primary: response?.data?.message.float_shortcode_primary,
      });
      setIsLoading(false);
    });
  }, []);

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity={isUpdated ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isUpdated
            ? "Service switched successfully!!!"
            : "Cannot switch service!!!"}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={() => handleClose(name)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{ fontWeight: "bold", fontFamily: "Rubik, sans-serif" }}
        >
          Services Switch
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>
            {`Are you sure you want to turn `}
            <span style={{ fontWeight: "bold" }}>{getServiceNames(name)} </span>
            <span
              style={{
                fontWeight: "bold",
                color: switches[name] ? "red" : "#009966",
              }}
            >
              {switches[name] ? "off" : "on"}
            </span>{" "}
            ?
          </Typography>
          <TextField
            fullWidth
            autoComplete="reason"
            type="text"
            margin="normal"
            label="Reason for switch"
            onChange={handleReasonInput}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              fontFamily: "Rubik, sans-serif",
              fontWeight: "500",
              fontSize: ".88rem",
              color: "red",
              border: "2px solid red",
              borderRadius: "10px",
            }}
            onClick={() => handleClose(name)}
          >
            Cancel
          </Button>
          <Button
            sx={{
              fontFamily: "Rubik, sans-serif",
              fontWeight: "500",
              fontSize: ".88rem",
              backgroundColor: "#009966",
              color: "white",

              "&:hover": {
                backgroundColor: "rgba(0,153,102,.8)",
              },
            }}
            onClick={handleSwitch}
            autoFocus
          >
            Switch
          </Button>
        </DialogActions>
      </Dialog>
      <Box>
        <Typography
          sx={{
            color: "gray",
            mb: "1rem",
            fontSize: "2rem",
            fontFamily: "Rubik, sans-serif",
          }}
        >
          Service Switches
        </Typography>
        {isLoading === false ? (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{ fontFamily: "Rubik, sans-serif" }}
              >{`Airtime is ${switches.airtime_on ? "ON" : "OFF"}, Switch it ${
                switches.airtime_on ? "OFF" : "ON"
              }?`}</Typography>
              <Switch
                checked={switches.airtime_on ? true : false}
                onChange={handleChange}
                onClick={(e) => handleClickOpen(e)}
                color="success"
                sx={{ ml: "1rem" }}
                name="airtime_on"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontFamily: "Rubik, sans-serif" }}>{`Loans is ${
                switches.loans_on ? "ON" : "OFF"
              }, Switch it ${switches.loans_on ? "OFF" : "ON"}?`}</Typography>
              <Switch
                checked={switches.loans_on ? true : false}
                onChange={handleChange}
                onClick={(e) => handleClickOpen(e)}
                color="success"
                sx={{ ml: "1rem" }}
                name="loans_on"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{ fontFamily: "Rubik, sans-serif" }}
              >{`Payments is ${
                switches.payments_on ? "ON" : "OFF"
              }, Switch it ${
                switches.payments_on ? "OFF" : "ON"
              }?`}</Typography>
              <Switch
                checked={switches.payments_on ? true : false}
                onChange={handleChange}
                onClick={(e) => handleClickOpen(e)}
                color="success"
                sx={{ ml: "1rem" }}
                name="payments_on"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{ fontFamily: "Rubik, sans-serif" }}
              >{`Bank Deposit is ${
                switches.bank_deposit_on ? "ON" : "OFF"
              }, Switch it ${
                switches.bank_deposit_on ? "OFF" : "ON"
              }?`}</Typography>
              <Switch
                checked={switches.bank_deposit_on ? true : false}
                onChange={handleChange}
                onClick={(e) => handleClickOpen(e)}
                color="success"
                sx={{ ml: "1rem" }}
                name="bank_deposit_on"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{ fontFamily: "Rubik, sans-serif" }}
              >{`Float Purchase is ${
                switches.float_purchase_on ? "ON" : "OFF"
              }, Switch it ${
                switches.float_purchase_on ? "OFF" : "ON"
              }?`}</Typography>
              <Switch
                checked={switches.float_purchase_on ? true : false}
                onChange={handleChange}
                onClick={(e) => handleClickOpen(e)}
                color="success"
                sx={{ ml: "1rem" }}
                name="float_purchase_on"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{ fontFamily: "Rubik, sans-serif" }}
              >{`Float shortcode primary is ${
                switches.float_shortcode_primary ? "ON" : "OFF"
              }, Switch it ${
                switches.float_shortcode_primary ? "OFF" : "ON"
              }?`}</Typography>
              <Switch
                checked={switches.float_shortcode_primary ? true : false}
                onChange={handleChange}
                onClick={(e) => handleClickOpen(e)}
                color="success"
                sx={{ ml: "1rem" }}
                name="float_shortcode_primary"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{ fontFamily: "Rubik, sans-serif" }}
              >{`Send Money is ${
                switches.send_money_on ? "ON" : "OFF"
              }, Switch it ${
                switches.send_money_on ? "OFF" : "ON"
              }?`}</Typography>
              <Switch
                checked={switches.send_money_on ? true : false}
                onChange={handleChange}
                onClick={(e) => handleClickOpen(e)}
                color="success"
                sx={{ ml: "1rem" }}
                name="send_money_on"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{ fontFamily: "Rubik, sans-serif" }}
              >{`Pinless airtime is ${
                switches.pinless_airtime_on ? "ON" : "OFF"
              }, Switch it ${
                switches.pinless_airtime_on ? "OFF" : "ON"
              }?`}</Typography>
              <Switch
                checked={switches.pinless_airtime_on ? true : false}
                onChange={handleChange}
                onClick={(e) => handleClickOpen(e)}
                color="success"
                sx={{ ml: "1rem" }}
                name="pinless_airtime_on"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{ fontFamily: "Rubik, sans-serif" }}
              >{`Pinless airtime commission is ${
                switches.pinless_airtime_commission_on ? "ON" : "OFF"
              }, Switch it ${
                switches.pinless_airtime_commission_on ? "OFF" : "ON"
              }?`}</Typography>
              <Switch
                checked={switches.pinless_airtime_commission_on ? true : false}
                onChange={handleChange}
                onClick={(e) => handleClickOpen(e)}
                color="success"
                sx={{ ml: "1rem" }}
                name="pinless_airtime_commission_on"
              />
            </Box>
          </Box>
        ) : (
          <CircularStatic />
        )}
      </Box>
    </div>
  );
}

export default Switches;
