export const MaxDisbuseAmounts = {
  MPESA: 70000,
  PAYBILL: 70000,
  BANK: 350000,
};

export const LoanDisburseOption = {
  BANK: "bank",
  MPESA: "mpesa",
  CHEQUE: "cheque",
  PAYBILL: "paybill",
};

export const LoanClient = {
  PERSON: "person", // individual
  BUSINESS: "business", // company
};

export const LoanStatus = {
  PENDING: "pending",
  DECLINED: "declined",
  APPROVED: "approved",
  HAKIKISHA: "hakikisha",
  DISBURSED: "disbursed",

  OVERDUE: "overdue",
  OUTSOURCED: "outsourced",

  REPAID: "repaid",
  REPAID_PARTIAL: "repaid_partial",

  RENEWED: "renewed",
  CANCELLED: "cancelled",
  WRITTEN_OFF: "writtenOff",

  OVERDUE_ROLLOVER: "overdue_rollover",
  OVERDUE_ROLLOVER_STOP: "overdue_rollover_stop",

  CANCELLED_MANUALLY: "cancelled_manually",
  CANCELLED_CONFIRMED: "cancelled_confirmed",
  INITIATE_DISBURSE_MANUALLY: "initiate_disburse_manually",
  DISBURSED_MANUALLY: "disbursed_manually",
  REASSESSED_MANUALLY: "re_assessed_manually",
  RENEWED_MANUALLY: "renewed_manually",
  REPAID_MANUALLY: "repaid_manually",
  REPAID_CONFIRMED: "repaid_confirmed",
  REPAID_PARTIAL_MANUALLY: "repaid_partial_manually",
  REPAID_PARTIAL_CONFIRMED: "repaid_partial_confirmed",
  PAID_IN_TIME_MANUALLY: "paid_in_time_manually",
  RATE_ADJUSTED_MANUALLY: "rate_adjusted_manually",
  MOVED_MANUALLY: "moved_manually",
  LOW_LIMIT: "low_user_loan_limit",
  MARKED_AS_FRAUD: "marked_as_fraud",
  ABORT_CONFIRM_AS_FRAUD: "abort_confirm_action",
  RESTORED_MANUALLY: "restored_manually",
  DISBURSED_FORTUNE: "disbursed_fortune",
  DISBURSE_FAILED_FORTUNE_DISBURSED: "disburse_failed_fortune_disbursed",
  DISBURSE_FAILED_FORTUNE_B2C: "disburse_failed_fortune_b2c",
  CHECK_FAILED_FORTUNE_B2C: "check_failed_fortune_b2c",
  REPAYMENT_FAILED_FORTUNE: "repayment_failed_fortune",
  REPAYMENT_SUCCEDED_FORTUNE: "repayment_succeded_fortune",
};

export const LoanPendingAction = {
  CANCEL: "cancel",
  FRAUD: "fraud",
  REPAY: "repay",
  WAIVE: "waive",
  RESTRUCTURE: "restructure",
  MANUAL_ADD: "manual_add",
};

// loan status codes
export const LoanStatusCodes = {
  LOAN_CODE_PENDING: 0,
  LOAN_CODE_DECLINED: 1,
  LOAN_CODE_APPROVED: 2,
  LOAN_CODE_DISBURSED: 3,
  LOAN_CODE_OVERDUE: 4,
  LOAN_CODE_REPAID: 5,
  LOAN_CODE_CANCELLED: 6,
  LOAN_CODE_RENEWED: 7,
  LOAN_CODE_REPAID_PARTIAL: 8,
  LOAN_CODE_OVERDUE_ROLLOVER: 9,
  LOAN_CODE_OUTSOURCED: 10,
  LOAN_CODE_RATE_CHANGED: 11,
  LOAN_CODE_RATE_ADJUSTED: 12,
  LOAN_CODE_START_ROLLOVER: 13,
  LOAN_CODE_END_ROLLOVER: 14,
  LOAN_CODE_ROLLOVER_WAIVER: 15,
  LOAN_CODE_ROLLOVER_UNDO_WAIVER: 16,
  LOAN_CODE_LOW_LOAN_LIMIT: 17,
  LOAN_CODE_UNDO_PARTIAL_PAYMENT: 18,
  LOAN_CODE_HAKIKISHA: 19,
  LOAN_CODE_PRELISTED: 21,
  LOAN_CODE_LISTED: 22,
  LOAN_CODE_WRITTEN_OFF: 999,
  SEND_MPESA_BELOW_1000: 15,
  SEND_MPESA_ABOVE_1000: 22,
};
