import React from "react";

import Layout from "../shared/Layout/layout";

function Home() {
  return (
    <div>
      <Layout />
    </div>
  );
}

export default Home;
